import React, { JSX } from 'react';
import sanitize from '@/Util/sanitize';
import ImageSlider from '@/components/imageSlider/ImageSlider';
import { useTranslation } from '@/app/i18n/client';
import { ApiSightseeingItem } from '../../../api/model';
import { EditableAreaOrPageChild } from '@/types/cms/magnolia';

const SightseeingContent = ({
  sightseeingItem,
  className,
  hideTitles,
  pageProps
}: {
  sightseeingItem?: ApiSightseeingItem;
  className?: string;
  hideTitles?: boolean;
} & Partial<EditableAreaOrPageChild>): JSX.Element => {
  const { t } = useTranslation('country');

  return (
    <div className={`sightseeing__content ${className || ''}`}>
      {!hideTitles && (
        <div className="sightseeing__content__name">
          <b>{sightseeingItem?.title}</b>
        </div>
      )}

      {sightseeingItem?.images && sightseeingItem.images.length > 0 && (
        <ImageSlider images={sightseeingItem.images} pageProps={pageProps} />
      )}
      <div className="sightseeing__content__short-description">
        <div
          dangerouslySetInnerHTML={{ __html: sanitize(sightseeingItem?.description || '', true) }}
        ></div>
      </div>
    </div>
  );
};

export default SightseeingContent;
