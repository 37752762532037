/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-03-18 11:04:35 */

const Keys = {
  day: 'day',
  day_plural: 'day_plural',
  departure: 'departure',
  departure_plural: 'departure_plural',
  from: 'from',
  new: 'new',
  seeAllTravels: 'seeAllTravels',
  goToTravel: 'goToTravel'
}; export default Keys;