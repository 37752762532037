'use client';

import React, { Fragment, useState, JSX } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import { useMount } from 'react-use';
import styles from './Tabs.module.scss';

type PropType = 'tabHeader' | 'tabArea';
type Property = `${PropType}${number}`;
type CmsPropertyType = object & { '@id': string };
type Props = { [key in Property]: CmsPropertyType } & { tabCount: number };

const Tabs = (props: Props): JSX.Element => {
  const { tabCount } = props;
  const [activeTab, setActiveTab] = useState<string>('');

  useMount(() => {
    const firstTabArea = props[`tabArea${1}` as keyof Props] as CmsPropertyType;
    if (!!firstTabArea) {
      setActiveTab(firstTabArea['@id']);
    }
  });

  const handleNavClick = (index: number): void => {
    const activeTabArea = (props[`tabArea${index}` as keyof Props] as CmsPropertyType)?.['@id'];
    if (!!activeTabArea) {
      setActiveTab(activeTabArea);
    }
  };

  return (
    <div className={styles.tabs}>
      <Nav tabs>
        {[...Array(tabCount).keys()].map(key => {
          const prop = props[`tabHeader${key + 1}` as keyof Props];
          const isActive =
            (props[`tabArea${key + 1}` as keyof Props] as CmsPropertyType)?.['@id'] === activeTab;
          return !!props ? (
            <NavItem key={key}>
              <NavLink
                className={`${styles.navLink} ${isActive ? 'active' : ''}`}
                onClick={(): void => handleNavClick(key + 1)}
              >
                <EditableArea content={prop as Record<string, any>} />
              </NavLink>
            </NavItem>
          ) : (
            <Fragment key={key} />
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab} className={styles.tabContent}>
        {[...Array(tabCount).keys()].map(key => {
          const prop = props[`tabArea${key + 1}` as keyof Props];
          return !!prop ? (
            <TabPane key={key} tabId={(prop as CmsPropertyType)['@id']}>
              <Row className="p-3">
                <Col>
                  <EditableArea content={prop as Record<string, any>} />
                </Col>
              </Row>
            </TabPane>
          ) : (
            <Fragment key={key} />
          );
        })}
      </TabContent>
    </div>
  );
};

export default Tabs;
