/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-03-18 11:04:35 */

const Keys = {
  validationRequired: 'validationRequired',
  validationEmail: 'validationEmail',
  validationConfirmEmail: 'validationConfirmEmail',
  validationCheckboxGroupRequired: 'validationCheckboxGroupRequired',
  validationNumber: 'validationNumber',
  reopenFormButtonText: 'reopenFormButtonText'
}; export default Keys;