import React, { ReactElement, useMemo } from 'react';
import { Link } from '@/Hooks/useReactTransitionProgress';
import { ApiNavigationItem } from '../../../api/model';

const Links = ({ links }: { links: ApiNavigationItem[] }): ReactElement => {
  return (
    <ul>
      {links.map((link: ApiNavigationItem) => (
        <li key={`${link.name}${link.linkType}`}>
          <a className="footer__link" href={link?.link || ''}>
            {link?.name}
          </a>
        </li>
      ))}
    </ul>
  );
};

const FooterLinks = ({
  linkSection,
  headline
}: {
  linkSection: ApiNavigationItem[];
  headline?: string;
}): ReactElement => {
  const list = useMemo(() => {
    return linkSection.length <= 5
      ? { left: [...linkSection] }
      : {
          left: [
            ...linkSection.filter(
              (_, index) => index <= (linkSection.length > 10 ? linkSection.length / 2 : 5)
            )
          ],
          right: [
            ...linkSection.filter(
              (_, index) => index > (linkSection.length > 10 ? linkSection.length / 2 : 5)
            )
          ]
        };
  }, [linkSection]);

  return (
    <div className="footer__links">
      <h4 className="h4-albatros">{headline}</h4>
      <div className="d-xs-flex">
        <Links links={list.left} />
        {!!list.right && <Links links={list.right} />}
      </div>
    </div>
  );
};

export default FooterLinks;
