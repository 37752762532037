import React, { FC, useMemo, JSX, RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faUser } from '@fortawesome/free-solid-svg-icons';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { useConfig } from '@ibe/components';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import Select from '@/components/Select/Select';
import { facCalendarSmall } from '@/Theme/SVG/Icons';
import { getOptionsData, OptionsData } from '@/components/productSelection/optionsData';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useIsComponentMounted } from '@/Hooks/useIsComponentMounted';
import { FormRefs } from '@/components/checkout/ParticipantsForm';
import { PacificProductForHeroDispatch } from '@/components/checkout/checkoutStepConfirmation/CheckoutStepConfirmation';

const ProductSelection: FC<{
  checkoutStore: CheckoutStore;
  participantsFormRefs: RefObject<FormRefs>;
  setPacificProduct: PacificProductForHeroDispatch;
}> = observer(function ProductSelection({
  checkoutStore,
  participantsFormRefs,
  setPacificProduct
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const config = useConfig();
  const language = useCurrentLanguage();
  const isMounted = useIsComponentMounted();

  const optionsData = useMemo(
    (): OptionsData =>
      getOptionsData(
        t,
        config,
        language,
        checkoutStore.pacificProducts,
        checkoutStore.selectedPacificProduct
      ),
    [checkoutStore.pacificProducts, checkoutStore.selectedPacificProduct]
  );
  const hasAirports =
    optionsData.departureAirports &&
    optionsData.departureAirports.length > 0 &&
    !!optionsData.departureAirports[0].label &&
    !!optionsData.departureAirports[0].value;

  const handleChange = (
    type: 'participants' | 'departureDate' | 'departureAirport',
    value: string | number
  ): void => {
    checkoutStore.mountParticipantsFormComponent = false;
    participantsFormRefs.current = [];
    checkoutStore.setSelectedFilterOptions(
      { ...checkoutStore.selectedFilterOptions, [type]: value },
      setPacificProduct
    );
  };

  return (
    <div className="product-selection grid">
      <div
        className={classNames('grid-col-12', {
          'grid-col-lg-4': hasAirports,
          'grid-col-lg-6': !hasAirports
        })}
      >
        <label className="product-selection__label">
          <FontAwesomeIcon icon={faUser} />
          <span>{t(Keys.numberOfTravelers)}</span>
        </label>
        <Select
          value={checkoutStore.selectedFilterOptions?.participants}
          onChange={(value: number) => handleChange('participants', value)}
          options={optionsData.participants}
        />
      </div>
      <div
        className={classNames('grid-col-12', {
          'grid-col-lg-4-5': hasAirports,
          'grid-col-lg-6-7': !hasAirports
        })}
      >
        <label className="product-selection__label">
          <FontAwesomeIcon icon={facCalendarSmall} />
          <span>{t(Keys.departure)}</span>
        </label>
        <Select
          value={isMounted ? checkoutStore.selectedFilterOptions?.departureDate : ''}
          onChange={(value: string) => handleChange('departureDate', value)}
          options={isMounted ? optionsData.departureDates : []}
        />
      </div>
      {hasAirports && (
        <div className="grid-col-12 grid-col-lg-4-9">
          <label className="product-selection__label">
            <FontAwesomeIcon icon={faPlane} />
            <span>{t(Keys.departureAirport)}</span>
          </label>
          <Select
            value={checkoutStore.selectedFilterOptions?.departureAirport}
            onChange={(value: string) => handleChange('departureAirport', value)}
            options={optionsData.departureAirports}
          />
        </div>
      )}
    </div>
  );
});

export default ProductSelection;
