'use client';

import React, { FC, JSX } from 'react';
import Image from '@/components/image/Image';
import { ApiProductsCacheData, ApiProductsCacheResponse } from '@ibe/api';
import Keys from '@/Translations/generated/da/productTeaser.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { getDefaultFormatPrice, mapToApiImage } from '@/Util/globals';
import Button from '@/components/Button';
import { THEMES } from '@/Util/globals';
import GridContainer from '@/Layouts/GridContainer';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { EditableAreaOrPageChild } from '@/types/cms/magnolia';
import { Link } from '@/Hooks/useReactTransitionProgress';
import { ExtendedProduct } from '@/components/productTeasers/ProductTeaser';

export interface TopProductTeaserProps extends EditableAreaOrPageChild {
  product: ExtendedProduct;
  theme?: THEMES;
  cacheProduct?: ApiProductsCacheData;
  productPackages?: ApiProductsCacheResponse[];
  productUrl?: string;
}

const TopProductTeaser: FC<TopProductTeaserProps> = ({
  product,
  theme,
  pageProps,
  cacheProduct,
  productUrl
}: TopProductTeaserProps): JSX.Element => {
  const { t } = useTranslation('topProductTeaser');
  const locale = useCurrentLanguage();
  const { teaserImageMedium, teaserImageLarge, tagline, productTitle } = product;
  const teaserImage = mapToApiImage(teaserImageLarge || teaserImageMedium);
  const { duration, startingPriceAmount } = cacheProduct || {};

  return (
    <GridContainer className="top-product-teaser__container">
      <a className="top-product-teaser" href={productUrl}>
        {!!teaserImage && (
          <Image
            image={teaserImage}
            width={960}
            height={540}
            serverSideContext={pageProps?.albConfig.context}
            pageProps={pageProps}
          />
        )}
        <div className={`top-product-teaser__content ${theme || THEMES.blue}`}>
          <div className="top-product-teaser__pacific">
            <div className="top-product-teaser__pacific__infos">
              {!!duration && <div>{t(Keys.day, { count: duration })}</div>}
              <div className="top-product-teaser__divider">|</div>
              {!!locale && !!startingPriceAmount && (
                <div>{`${t(Keys.from)} ${getDefaultFormatPrice(startingPriceAmount, locale)}`}</div>
              )}
            </div>
          </div>
          <p className="top-product-teaser__title">{productTitle}</p>
          <p className="top-product-teaser__description">{tagline}</p>
          <div className="top-product-teaser__button">
            <Button color="primary" showCaret>
              {t(Keys.goToTravel)}
            </Button>
          </div>
        </div>
      </a>
    </GridContainer>
  );
};

export default TopProductTeaser;
