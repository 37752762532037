import React, { FC, Fragment, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import classNames from 'classnames';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import CheckboxResults from '@/components/Search/CheckboxResults';
import { Action, ApiDatesSearchResponseDates } from '@/components/Search/searchReducer/types';
import useMonthLabels from '@/Hooks/useMonthLabels';

export const SELECTABLE_YEARS = 3;

export const createMonthId = (monthIdx: number, selectedYear?: number): string => {
  return `${selectedYear || 0}-${monthIdx}`;
};

export function getMonthYear(monthId: string, monthLabels: string[]): string {
  const splitMonthId = monthId.split('-');
  if (!!splitMonthId[0] && !!splitMonthId[1]) {
    const year = splitMonthId[0];
    const month = splitMonthId[1];
    return `${monthLabels[parseInt(month, 10)]} ${year}`;
  } else {
    return '';
  }
}

export const getMonthsForSelectedYear = (
  dates: ApiDatesSearchResponseDates[],
  currentSelectedYear?: number,
  localSelectedYear?: number
): number[] => {
  return (
    dates.find(
      date => parseInt(date.year || '0', 10) === (localSelectedYear || currentSelectedYear)
    )?.months || []
  );
};

const MonthSelectorSearchTab: FC<
  PropsWithChildren<
    Pick<SearchProps, 'checkboxState' | 'checkboxDispatch' | 'isLoading'> & {
      mobileStyleInDesktop?: boolean;
    }
  >
> = ({ mobileStyleInDesktop, children, checkboxDispatch, checkboxState, isLoading }) => {
  let isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  isDesktop = mobileStyleInDesktop === undefined ? isDesktop : !mobileStyleInDesktop;
  const { dates } = checkboxState;

  const currentYear = useMemo((): number => new Date().getFullYear(), []);
  const [currentSelectedYear, setCurrentSelectedYear] = useState<number | undefined>(undefined);
  const monthLabels = useMonthLabels();

  useEffect(() => {
    if (isDesktop && !currentSelectedYear) {
      const firstYearWithActiveMonths = [...Array(SELECTABLE_YEARS).keys()]
        .map(idx => currentYear + idx)
        .find(year =>
          getMonthsForSelectedYear(dates, currentSelectedYear, year).some(month => month > 0)
        );
      setCurrentSelectedYear(
        firstYearWithActiveMonths !== undefined ? firstYearWithActiveMonths : currentYear
      );
    }
  }, [isDesktop, currentYear, currentSelectedYear]);

  const handleSelectYear = (year?: number): void => {
    setCurrentSelectedYear(year);
  };

  const checkSingleMonth = async (idx: number): Promise<void> => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: createMonthId(idx, currentSelectedYear),
        name: `${monthLabels[idx]} ${currentSelectedYear}`,
        type: 'date'
      }
    });
  };

  return (
    <div className="month-selector">
      <div className="month-selector__months w-100">
        <div className="month-selector__years--desktop">
          {[...Array(SELECTABLE_YEARS).keys()]
            .filter((_, idx) =>
              getMonthsForSelectedYear(dates, currentSelectedYear, currentYear + idx).some(
                month => month > 0
              )
            )
            .map(idx => (
              <div
                key={idx}
                className={classNames('month-selector__year', {
                  'month-selector__year--selected': currentYear + idx === currentSelectedYear
                })}
                onClick={(): void => handleSelectYear(currentYear + idx)}
              >
                {currentYear + idx}
              </div>
            ))}
        </div>
        <div className="month-selector__months--desktop search__content--checkboxes">
          {monthLabels.map((month: string, idx: number) =>
            (getMonthsForSelectedYear(dates, currentSelectedYear)[idx] || 0) > 0 ? (
              <CheckboxResults
                key={month}
                id={month}
                label={month}
                checked={!!checkboxState.items[createMonthId(idx, currentSelectedYear)]}
                onChange={(): Promise<void> => checkSingleMonth(idx)}
                results={getMonthsForSelectedYear(dates, currentSelectedYear)[idx] || 0}
                isLoading={isLoading}
              />
            ) : (
              <Fragment key={month} />
            )
          )}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default MonthSelectorSearchTab;
