import React, { CSSProperties, Fragment, ReactElement, RefObject, useRef } from 'react';
import { useTransition } from 'react-transition-state';
import useResize from '@/Hooks/useResize';
import useScroll from '@/Hooks/useScroll';
import { NavButton } from '@/components/imageGallery/NavButton';

type NavButtonType = { class: string; style?: CSSProperties; icon: ReactElement };

const useScrollSlide = (
  resizeCallback: (outerContainer: RefObject<HTMLDivElement>) => void,
  onNavButtonClick: (toLeft: boolean, outerContainer: RefObject<HTMLDivElement>) => void,
  navButtons: NavButtonType[]
): { Buttons: ReactElement; outerContainer: RefObject<HTMLDivElement> } => {
  const outerContainer = useRef<HTMLDivElement>(null) as RefObject<HTMLDivElement>;
  const [{ status: statusPrev, isMounted: isMountedPrev }, togglePrev] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });
  const [{ status: statusNext, isMounted: isMountedNext }, toggleNext] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useResize((): void => resizeCallback(outerContainer), 250, [outerContainer]);

  useScroll(
    (evt?: Event, containerElement?: HTMLElement | Window | null): void => {
      const evtTarget =
        (evt?.target as HTMLDivElement | undefined) || (containerElement as HTMLElement);
      if (!evtTarget) return;
      if (evtTarget.scrollLeft <= 5 || evtTarget.scrollWidth <= evtTarget.clientWidth) {
        togglePrev(false);
      } else {
        togglePrev(true);
      }

      if (
        evtTarget.scrollLeft >= evtTarget.scrollWidth - evtTarget.clientWidth - 5 ||
        evtTarget.scrollWidth <= evtTarget.clientWidth
      ) {
        toggleNext(false);
      } else {
        toggleNext(true);
      }
    },
    [outerContainer, navButtons, togglePrev, toggleNext],
    outerContainer?.current as HTMLElement,
    1000
  );

  return {
    Buttons: (
      <>
        {navButtons.map((button: NavButtonType, idx: number) => {
          const status = idx === 0 ? statusPrev : statusNext;
          const isMounted = idx === 0 ? isMountedPrev : isMountedNext;

          return (
            <Fragment key={button.class}>
              {status !== 'unmounted' && isMounted && (
                <NavButton
                  className={`navBtn navBtn--slide ${button.class}${
                    status !== 'preEnter' && status !== 'exiting' ? ' navBtn--visible' : ''
                  }`}
                  style={button.style}
                  onClick={(): void => onNavButtonClick(idx === 0, outerContainer)}
                >
                  {button.icon}
                </NavButton>
              )}
            </Fragment>
          );
        })}
      </>
    ),
    outerContainer
  };
};

export default useScrollSlide;
