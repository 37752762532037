import React, { useMemo } from 'react';

export interface TemperatureElementProps {
  precipitation: string;
}

const PrecipitationElement = (props: TemperatureElementProps) => {
  const { precipitation } = props;

  const { colorCode } = useMemo(() => {
    return {
      colorCode:
        Number(precipitation) / 2 > 79
          ? 'high'
          : Number(precipitation) / 2 > 59
            ? 'mid'
            : Number(precipitation) / 2 > 39
              ? 'low'
              : 'dry'
    };
  }, [precipitation]);

  return <div className={`precipitation-element ${colorCode}`}>{precipitation}</div>;
};
export default PrecipitationElement;
