'use client';

import React, { FC, JSX } from 'react';
import CustomSpinner from '@/components/CustomSpinner/CustomSpinner';
import {
  ApiProductsCacheResponse,
  ApiWebsiteDestinationSearch,
  ApiWebsiteFaqSearch,
  ApiWebsiteOtherPagesSearch
} from '@ibe/api';
import WebsiteSearchFullTeaserList from '@/components/websiteSearch/WebsiteSearchFullTeaserList';
import WebsiteSearchFullDestinationList from '@/components/websiteSearch/WebsiteSearchFullDestinationList';
import WebsiteSearchFullFaqList from '@/components/websiteSearch/WebsiteSearchFullFaqList';
import { Props } from '@/types/cms/magnolia';
import WebsiteSearchFullOtherPagesList from '@/components/websiteSearch/WebsiteSearchFullOtherPagesList';

export type Results = {
  product: ApiProductsCacheResponse[];
  destination: ApiWebsiteDestinationSearch[];
  otherPages: ApiWebsiteOtherPagesSearch[];
  faq: ApiWebsiteFaqSearch[];
};

const WebsiteSearchFull: FC<{
  activeIndex: number;
  isLoading: boolean;
  results: Results;
  query: string;
  pageProps?: Props;
}> = ({ activeIndex, isLoading, results, query, pageProps }): JSX.Element => {
  return (
    <div className="website-search-full__results__container">
      {isLoading && (
        <div className="website-search__results__spinner">
          <CustomSpinner />
        </div>
      )}
      <div className="website-search-full__results">
        {activeIndex === 0 && (
          <WebsiteSearchFullTeaserList
            results={results.product}
            pageProps={pageProps}
            activeIndex={activeIndex}
          />
        )}
        {activeIndex === 1 && (
          <WebsiteSearchFullDestinationList
            results={results.destination}
            activeIndex={activeIndex}
            query={query}
          />
        )}
        {activeIndex === 2 && (
          <WebsiteSearchFullOtherPagesList
            results={results.otherPages}
            activeIndex={activeIndex}
            query={query}
          />
        )}
        {activeIndex === 3 && (
          <WebsiteSearchFullFaqList results={results.faq} activeIndex={activeIndex} />
        )}
      </div>
    </div>
  );
};

export default WebsiteSearchFull;
