'use client';

import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { getNextLinkAttrs, SwitchableLinkProps } from '@/components/linkList/Link';
import Image from '@/components/image/Image';
import { debounce } from 'lodash-es';
import PageUrl from '@/Models/PageUrl';
import { facBurger, facCross } from '@/Theme/SVG/Icons';
import { useClickAway, useMount } from 'react-use';
import { useTransition } from 'react-transition-state';
import MenuDesktop from '@/Layouts/Header/MenuDesktop';
import MenuMobile from '@/Layouts/Header/MenuMobile';
import { cssHelper, HeaderProps, ScrollMode } from '@/Layouts/Header/external';
import { EditableAreaOrPageChild, MagnoliaDamNode, MagnoliaPageNode } from '@/types/cms/magnolia';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapToApiImage, normalizeInheritedProps } from '@/Util/globals';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import WebsiteSearch from '@/components/websiteSearch/WebsiteSearch';
import classNames from 'classnames';
import { useWindow } from '@ibe/components';
import { Link } from '@/Hooks/useReactTransitionProgress';
import useConfig from '@/Hooks/useConfig';

export interface Props extends MagnoliaPageNode, EditableAreaOrPageChild {
  navLinks: { [a: string]: SwitchableLinkProps } & SwitchableLinkProps;
  sidebarNavLinks: { [a: string]: SwitchableLinkProps } & SwitchableLinkProps;
  imageLinkDark: MagnoliaDamNode;
  imageLinkBright: MagnoliaDamNode;
  open: string;
  tel: string;
  mail: string;
}

export const CLOSE_QUICK_SEARCH_CUSTOM_EVENT = 'closeQuickSearchCustomEvent';

const Header = (props: Props) => {
  const { navLinks, sidebarNavLinks, imageLinkDark, imageLinkBright, open, tel, mail, pageProps } =
    normalizeInheritedProps<Props>(props);

  const window = useWindow();
  const { activateWebsiteSearch } = useConfig();
  const containerRef = useRef<HTMLDivElement>(null);
  const globalProps = useGlobalMGLProps();
  const data = useMemo((): HeaderProps => {
    return {
      navLinks: [
        ...(navLinks?.['@nodes'] || []).map(node => {
          return {
            linkProps: getNextLinkAttrs(navLinks[node], globalProps),
            label: navLinks[node].title
          };
        })
      ],
      sidebarNavLinks: [
        ...(sidebarNavLinks?.['@nodes'] || []).map(node => {
          return {
            linkProps: getNextLinkAttrs(sidebarNavLinks[node], globalProps),
            label: sidebarNavLinks[node].title
          };
        })
      ],
      sidebarNavLinksLeftColumn: [
        ...(sidebarNavLinks?.['@nodes'] || [])
          .filter((node, index) => {
            return index % 2 == 0;
          })
          .map(node => {
            return {
              linkProps: getNextLinkAttrs(sidebarNavLinks[node], globalProps),
              label: sidebarNavLinks[node].title
            };
          })
      ],
      sidebarNavLinksRightColumn: [
        ...(sidebarNavLinks?.['@nodes'] || [])
          .filter((node, index) => {
            return index % 2 == 1;
          })
          .map(node => {
            return {
              linkProps: getNextLinkAttrs(sidebarNavLinks[node], globalProps),
              label: sidebarNavLinks[node].title
            };
          })
      ],
      imageDark: imageLinkDark,
      imageBright: imageLinkBright,
      open: open,
      tel: tel,
      mail: mail
    };
  }, [navLinks, sidebarNavLinks, imageLinkDark, imageLinkBright, open, tel, mail]);

  useEffect(() => {
    function closeQuickSearch() {
      toggleSearch(false);
    }

    window?.document.addEventListener(CLOSE_QUICK_SEARCH_CUSTOM_EVENT, closeQuickSearch);

    return () => {
      window?.document.removeEventListener(CLOSE_QUICK_SEARCH_CUSTOM_EVENT, closeQuickSearch);
    };
  }, [window]);

  const [componentMounted, setComponentMounted] = useState<boolean>(false);
  const [prevScrollPos, setPrevScrollPos] = useState<number>(0);
  const [burgerOpen, setBurgerOpen] = useState<boolean>(false);
  const [headerOuterOpenState, setHeaderOuterOpenState] = useState<boolean>(false);
  const [scrollMode, setScrollMode] = useState<ScrollMode>('TOP');
  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 1000,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });
  const [{ status: searchStatus, isMounted: searchIsMounted }, toggleSearch] = useTransition({
    timeout: 1000,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useMount(() => {
    setComponentMounted(true);
  });

  useEffect(() => {
    if (!window) return;
    const handleScroll = debounce(() => {
      const { scrollY } = window;

      if (prevScrollPos > scrollY) {
        // Scroll up
        setScrollMode(scrollY <= 100 ? 'TOP' : 'UP');
      } else if (scrollY >= 100) {
        // Scroll down
        setScrollMode('DOWN');
      }
      setPrevScrollPos(scrollY);
    }, 50);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, window]);

  useEffect(() => {
    toggle(burgerOpen || searchIsMounted);
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (burgerOpen || searchIsMounted) {
      setHeaderOuterOpenState(true);
    } else {
      timer = setTimeout(() => {
        setHeaderOuterOpenState(false);
      }, 1000);
    }

    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, [burgerOpen, searchIsMounted]);

  useEffect(() => {
    if (scrollMode === 'DOWN') {
      toggle(false);
      toggleSearch(false);
    }
  }, [scrollMode]);

  useClickAway(containerRef, (): void => {
    setBurgerOpen(false);
    toggleSearch(false);
  });

  return (
    <Suspense>
      <div
        className={classNames('header__outer', {
          'header__outer--scrolldown': scrollMode === 'DOWN',
          'header__outer--scrollup': scrollMode === 'UP',
          'header__outer--scrolltop': scrollMode === 'TOP',
          'header__outer--open': headerOuterOpenState
        })}
      >
        {isMounted && (
          <div
            className={`header__backdrop${
              status === 'preEnter' || status === 'exiting' ? ' header__backdrop--hidden' : ''
            }`}
          />
        )}
        <div ref={containerRef} className="header">
          <div
            className={
              'topnav ' +
              cssHelper(scrollMode === 'DOWN' && 'topnav-scrolldown ') +
              cssHelper(scrollMode === 'UP' && 'topnav-scrollup ') +
              cssHelper(scrollMode === 'TOP' && 'topnav-scrolltop ')
            }
          >
            {(scrollMode !== 'TOP' || searchIsMounted) &&
              data.imageDark?.imageLink?.['@link'] &&
              componentMounted && (
                <a href={PageUrl.HOME} className="logo">
                  <Image
                    pageProps={pageProps}
                    image={mapToApiImage(data.imageDark?.imageLink) || {}}
                    width={146}
                    height={37}
                    useDefaultLoader
                    serverSideContext={pageProps?.albConfig.context}
                  />
                </a>
              )}
            {scrollMode === 'TOP' &&
              data.imageBright?.imageLink?.['@link'] &&
              !searchIsMounted &&
              componentMounted && (
                <a href={PageUrl.HOME} className="logo">
                  <Image
                    pageProps={pageProps}
                    image={mapToApiImage(data.imageBright?.imageLink) || {}}
                    width={146}
                    height={37}
                    useDefaultLoader
                    serverSideContext={pageProps?.albConfig.context}
                  />
                </a>
              )}
            <div className="spacer" />
            {data.navLinks.map(navLink => {
              return (
                <a
                  className={`topnav-link menu${burgerOpen ? ' topnav-link--hidden' : ''}`}
                  key={navLink.label}
                  href={navLink.linkProps.href}
                >
                  {navLink.label}
                </a>
              );
            })}
          </div>
          <div
            className={
              burgerOpen
                ? 'burgernav ' + 'burgernav-scrolltop '
                : 'burgernav ' +
                  cssHelper(scrollMode === 'DOWN' && 'burgernav-scrolldown ') +
                  cssHelper(scrollMode === 'UP' && 'burgernav-scrollup ') +
                  cssHelper(scrollMode === 'TOP' && 'burgernav-scrolltop ')
            }
          >
            <div
              className={`menu-icon${scrollMode === 'TOP' ? ' menu-icon__top' : ''}`}
              onClick={() => {
                setBurgerOpen(!burgerOpen);
              }}
            >
              {burgerOpen && <FontAwesomeIcon className="burgernav__close" icon={facCross} />}
              {!burgerOpen && <FontAwesomeIcon icon={facBurger} />}
            </div>
            {activateWebsiteSearch && (
              <div
                className={`menu-icon menu-icon__magnify${
                  scrollMode === 'TOP' ? ' menu-icon__top' : ''
                }`}
                onClick={() => {
                  toggleSearch(true);
                }}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </div>
            )}
          </div>
          <MenuDesktop
            burgerOpen={burgerOpen}
            setBurgerOpen={setBurgerOpen}
            toggleSearch={toggleSearch}
            scrollMode={scrollMode}
            data={data}
          />
          <MenuMobile burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} data={data} />
          {activateWebsiteSearch && (
            <WebsiteSearch
              status={searchStatus}
              isMounted={searchIsMounted}
              toggleSearch={toggleSearch}
            />
          )}
        </div>
      </div>
    </Suspense>
  );
};

export default Header;
