'use client';

import React, { FC, JSX } from 'react';
import { ApiProductWithReviews } from '@ibe/api';
import Keys from '@/Translations/generated/da/product.json.keys';
import StarRating from '@/components/starRating/StarRating';
import { useTranslation } from '@/app/i18n/client';

const ShortReviews: FC<{ reviews?: ApiProductWithReviews }> = (props): JSX.Element => {
  const { t } = useTranslation('product');
  const { reviews } = props;

  return !!reviews?.averageRating && reviews.averageRating > -1 ? (
    <>
      <div>{t(Keys.guestReviews)}</div>
      <StarRating
        className="hero__rating"
        rating={reviews.averageRating}
        maxRating={Math.round(reviews.averageRating)}
      />
      <div>
        {`${Math.round(reviews.averageRating * 10) / 10} * ${reviews.totalReviewCount} ${t(
          Keys.reviews
        )}`}
      </div>
    </>
  ) : (
    <></>
  );
};

export default ShortReviews;
