'use client';

import React, { createContext, PropsWithChildren, useContext, JSX } from 'react';
import { fallbackLng } from '@/app/i18n/settingsClient';

export const CurrentLanguageContext = createContext<string>(fallbackLng);

const CurrentLanguageProvider = (props: PropsWithChildren<{ lng: string }>): JSX.Element => {
  const { children, lng } = props;

  return <CurrentLanguageContext.Provider value={lng}>{children}</CurrentLanguageContext.Provider>;
};

export const useCurrentLanguage = (): string => {
  return useContext(CurrentLanguageContext);
};

export default CurrentLanguageProvider;
