import React, { Dispatch, FC, JSX, SetStateAction, useLayoutEffect, useRef } from 'react';
import Button from '@/components/Button';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { getDefaultFormatPrice } from '@/Util/globals';
import classNames from 'classnames';
import { ProgressBarItem } from '@/components/ProgressBar/ProgressBar';
import Script from 'next/script';
import useConfig from '@/Hooks/useConfig';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import CartMobileWrapper from '@/components/checkout/CartMobileWrapper';
import { trustPilotLanguageMapping } from '@/app/i18n/settingsClient';
import desktopContent from '@/components/ProductsDatesPrices/DesktopContent';

const CheckoutFooter: FC<{
  checkoutStore: CheckoutStore;
  showContactBar: boolean;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  activeSubIndex: number[];
  setActiveSubIndex: Dispatch<SetStateAction<number[]>>;
  numberOfSubStepsPerStep: number[];
  progressBarItems: ProgressBarItem[];
  onBackClick?: () => void;
  itemClickReplaceLogicCallback?: (idx: number) => void;
  initialCartError: boolean;
  isDesktop: boolean;
  activePaymentRadio: number;
  setIgnoreBusSeatValidation: Dispatch<SetStateAction<boolean>>;
}> = observer(function CheckoutFooter({
  checkoutStore,
  showContactBar,
  activeIndex,
  setActiveIndex,
  progressBarItems,
  onBackClick,
  itemClickReplaceLogicCallback,
  initialCartError,
  activeSubIndex,
  setActiveSubIndex,
  numberOfSubStepsPerStep,
  isDesktop,
  activePaymentRadio,
  setIgnoreBusSeatValidation
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const language = useCurrentLanguage();
  const { trustpilotTemplateId, trustpilotSrc, trustpilotReviewUrl } = useConfig();
  const { locale, site, businessUnitId, logoUrls } = trustPilotLanguageMapping[language];
  const { price: { finalPrice } = { finalPrice: undefined } } = checkoutStore.booking || {};
  const trustPilotRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!!window?.Trustpilot && !!trustPilotRef.current) {
      window.Trustpilot.loadFromElement(trustPilotRef.current);
    }
  }, [trustPilotRef]);

  const back = (): void => {
    if (activeIndex === 0 && !!onBackClick && isDesktop) {
      onBackClick();
    } else if (activeIndex === 0 && activeSubIndex[activeIndex] > 0 && !isDesktop) {
      let currentActiveSubIndex = [...activeSubIndex];
      currentActiveSubIndex[activeIndex] = currentActiveSubIndex[activeIndex] - 1;
      setActiveSubIndex(currentActiveSubIndex);
    } else if (activeIndex > 0 && activeIndex < progressBarItems.length - 1) {
      if (!isDesktop) {
        let currentActiveSubIndex = [...activeSubIndex];
        if (activeSubIndex[activeIndex] === 0) {
          setActiveIndex(value => value - 1);
          currentActiveSubIndex[activeIndex] = numberOfSubStepsPerStep[activeIndex - 1] - 1;
        } else {
          currentActiveSubIndex[activeIndex] = currentActiveSubIndex[activeIndex] - 1;
        }
        setActiveSubIndex(currentActiveSubIndex);
      } else {
        setActiveIndex(value => value - 1);
      }
    }
  };

  const forward = (): void => {
    if (!initialCartError) {
      if (!!itemClickReplaceLogicCallback) {
        itemClickReplaceLogicCallback(activeIndex + 1);
      } else if (activeIndex < progressBarItems.length - 1) {
        if (!isDesktop) {
          if (activeSubIndex[activeIndex] === numberOfSubStepsPerStep[activeIndex] - 1) {
            setActiveIndex(value => value + 1);
          } else {
            let currentActiveSubIndex = [...activeSubIndex];
            currentActiveSubIndex[activeIndex] = currentActiveSubIndex[activeIndex] + 1;
            setActiveSubIndex(currentActiveSubIndex);
          }
        } else {
          setActiveIndex(value => value + 1);
        }
      }
    }
  };

  return (
    <div className={classNames('checkout__footer', { 'checkout__footer--high': showContactBar })}>
      {activeIndex < progressBarItems.length - 1 && (
        <div className="checkout__footer__buttons">
          {activeIndex < progressBarItems.length - 1 && (
            <Button color="primary" onClick={back}>
              {t(Keys.back)}
            </Button>
          )}
          <div className="checkout__footer__buttons--mobile">
            {!!finalPrice && <div>{getDefaultFormatPrice(finalPrice, language)}</div>}
            <CartMobileWrapper
              checkoutStore={checkoutStore}
              setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
            />
          </div>
          {activeIndex < progressBarItems.length - 1 && (
            <Button color="primary" showCaret onClick={forward} disabled={initialCartError}>
              {(isDesktop && activeIndex + 1 === progressBarItems.length - 1) ||
              (activeIndex + 1 === progressBarItems.length - 1 &&
                !isDesktop &&
                activeSubIndex[activeIndex] === numberOfSubStepsPerStep[activeIndex] - 1)
                ? activePaymentRadio === 0
                  ? t(Keys.payAndBook)
                  : t(Keys.book)
                : t(Keys.forward)}
            </Button>
          )}
        </div>
      )}
      <hr className="checkout__footer__hr" />
      <div className="checkout__footer__lower">
        <div className="checkout__footer__left">
          <div
            ref={trustPilotRef}
            className="trustpilot-widget"
            data-locale={locale}
            data-template-id={trustpilotTemplateId}
            data-businessunit-id={businessUnitId}
            data-style-height="150px"
            data-style-width="100%"
            data-theme="light"
          >
            <a href={`${trustpilotReviewUrl}${site}`} target="_blank" rel="noopener">
              Trustpilot
            </a>
          </div>
        </div>
        <div className="checkout__footer__right">
          {logoUrls.map(url => (
            // eslint-disable-next-line
            <img
              key={url}
              src={url}
              alt={url.split('/')[url.split('/').length - 1].split('.')[0]}
            />
          ))}
        </div>
      </div>
      <Script
        id={`trustpilot-${trustpilotTemplateId}`}
        src={trustpilotSrc}
        strategy="lazyOnload"
        async
        defer
      />
    </div>
  );
});

export default CheckoutFooter;
