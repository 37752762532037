'use client';

import React, { ReactElement, useMemo } from 'react';
import { MagnoliaImageContent, MagnoliaImages, Props as PageProps } from '@/types/cms/magnolia';
import { mapToApiImage } from '@/Util/globals';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { isMagnoliaImages } from '@/types/typeGuards';
import ImageGalleryInner from '@/components/imageGallery/ImageGalleryInner';
import { ApiImage } from '../../../api/model';

type Props = {
  theme?: string;
  headline?: string;
  images: MagnoliaImages | ApiImage[];
  h2Headline?: boolean;
  pageProps?: PageProps;
};

const ImageGallery = (props: Props): ReactElement => {
  const { theme, headline, images, h2Headline, pageProps } = props;

  const transformedImages = useMemo((): ApiImage[] => {
    return isMagnoliaImages(images)
      ? (images['@nodes'].map(node =>
          mapToApiImage(
            images[node as keyof MagnoliaImageContent].image,
            images[node as keyof MagnoliaImageContent].image.metadata.caption
          )
        ) as ApiImage[])
      : images;
  }, []);

  return (
    <>
      {!!transformedImages && (
        <ImageGalleryInner
          images={transformedImages}
          theme={theme}
          headline={headline}
          h2Headline={h2Headline}
          pageProps={pageProps}
        />
      )}{' '}
    </>
  );
};

export default ImageGallery;
