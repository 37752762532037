import DOMPurify from 'dompurify';
import isClient from '@/Util/globals';

export const sanitizeClient = (innerHtml: string, withTargetAttr?: boolean) => {
  return DOMPurify.sanitize(
    innerHtml,
    withTargetAttr
      ? {
          USE_PROFILES: { html: true },
          ADD_TAGS: ['a'],
          ADD_ATTR: ['target']
        }
      : {}
  );
};

const getServerDOMPurifier = () => {
  let instance: DOMPurify.DOMPurify;

  function createInstance(): DOMPurify.DOMPurify {
    const { JSDOM } = require('jsdom');
    const { window } = new JSDOM('<!DOCTYPE html>');
    return DOMPurify(window);
  }

  return (): DOMPurify.DOMPurify => {
    if (!instance) {
      instance = createInstance();
      return instance;
    } else {
      return instance;
    }
  };
};

export const sanitizeServer = (innerHtml: string, withTargetAttr?: boolean): string => {
  const DOMPurifier = getServerDOMPurifier()();
  return DOMPurifier.sanitize(
    innerHtml,
    withTargetAttr
      ? {
          USE_PROFILES: { html: true },
          ADD_TAGS: ['a'],
          ADD_ATTR: ['target']
        }
      : {}
  );
};

export default isClient() ? sanitizeClient : sanitizeServer;
