/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-03-18 11:04:35 */

const Keys = {
  continentCountryListHeadline: 'continentCountryListHeadline',
  continentIntroHeadline: 'continentIntroHeadline',
  moreInformation: 'moreInformation',
  area: 'area',
  largestCities: 'largestCities',
  citizens: 'citizens',
  amount: 'amount'
}; export default Keys;