'use client';

import React, { FC, JSX, PropsWithChildren } from 'react';
import Image from '@/components/image/Image';
import { useCheckoutContext } from '@/templates/checkout/CheckoutContextProvider';
import { Props } from '@/types/cms/magnolia';

const CheckoutPageHeroClient: FC<PropsWithChildren<{ pageProps?: Props }>> = ({
  children,
  pageProps
}): JSX.Element => {
  const { clientHeroImage } = useCheckoutContext();

  return !!clientHeroImage ? (
    <div className="hero">
      <Image
        image={clientHeroImage}
        className="hero__image"
        priority
        serverSideContext={pageProps?.albConfig.context}
        useDefaultLoader
        pageProps={pageProps}
      />
      {children}
    </div>
  ) : (
    <></>
  );
};

export default CheckoutPageHeroClient;
