import { FC } from 'react';
import Footer from '@/Layouts/Footer/Footer';
import Header from '@/Layouts/Header/Header';
import Teaser from '@/components/teaser/Teaser';
import Accordion from '@/components/accordion/Accordion';
import StaticImage from '@/components/image/StaticImage';
import RichText from '@/components/RichText/RichText';
import Tabs from '@/components/tabs/Tabs';
import SimpleHeaderText from '@/components/SimpleHeaderText';
import FixedColumnLayout from '@/components/fixedColumnLayout/FixedColumnLayout';
import LeftNavi from '@/components/leftNavi/LeftNavi';
import FeatureList from '@/components/featureList/FeatureList';
import LinkList from '@/components/linkList/LinkList';
import Link from '@/components/linkList/Link';
import AlertBox from '@/components/alertBox/AlertBox';
import ContentModule from '@/components/contentModule/ContentModule';
import ContentRow from '@/components/contentModule/ContentRow';
import PriceInformation from '@/components/priceInformation/PriceInformation';
import Highlights from '@/components/introduction/Highlights';
import HighlightBanner from '@/components/highlightBanner/HighlightBanner';
import TravelInformation from '@/components/travelInformation/TravelInformation';
import ImageGallery from '@/components/imageGallery/ImageGallery';
import Hero from '@/components/hero/Hero';
import VideoGallery from '@/components/videoGallery/VideoGallery';
import CountryClimate from '@/components/CountryIntros/CountryClimate';
import CountryMain from '@/components/CountryIntros/CountryMain';
import CountryFacts from '@/components/CountryIntros/CountryFacts';
import CountryImages from '@/components/CountryIntros/CountryImages';
import CountrySightseeing from '@/components/CountryIntros/CountrySightseeing';
import CountryTrips from '@/components/CountryIntros/CountryTrips';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb';
import Itinerary from '@/components/itinerary/Itinerary';
import CountryMap from '@/components/CountryIntros/CountryMap';
import CountryEntry from '@/components/CountryIntros/CountryEntry';
import ContinentPage from 'templates/ContinentPage';
import ProductPage from 'templates/ProductPage';
import StaticPage from 'templates/StaticPage';
import HomePage from 'templates/HomePage';
import CountryPage from '@/templates/country/CountryPage';
import GalleryOverviewCountry from '@/components/galleryOverviewCountry/GalleryOverviewCountry';
import ProductTeasers from '@/components/productTeasers/ProductTeasers';
import CountryFactsPage from '@/templates/country/CountryFactsPage';
import CountryTravelsPage from '@/templates/country/CountryTravelsPage';
import CountryImagesPage from '@/templates/country/CountryImagesPage';
import CountrySightsPage from '@/templates/country/CountrySightsPage';
import CountryEntryPage from '@/templates/country/CountryEntryPage';
import CountryVaccinationPage from '@/templates/country/CountryVaccinationPage';
import CountryClimatePage from '@/templates/country/CountryClimatePage';
import CountryMapPage from '@/templates/country/CountryMapPage';
import ResultsPage from '@/templates/ResultsPage';
import TravelTypeTeasers from '@/components/travelTypeTeaser/TravelTypeTeasers';
import CheckoutPage from '@/templates/checkout/CheckoutPage';
import TravelTypePage from '@/templates/TravelTypePage';
import GalleryOverviewTravelType from '@/components/galleryOverviewTravelType/GalleryOverviewTravelType';
import GalleryOverviewTag from '@/components/galleryOverviewTag/GalleryOverviewTag';
import LinkBanner from '@/components/linkBanner/LinkBanner';
import CityMain from '@/components/CityIntros/CityMain';
import CityClimate from '@/components/CityIntros/CityClimate';
import CityFacts from '@/components/CityIntros/CityFacts';
import CityImages from '@/components/CityIntros/CityImages';
import CityTrips from '@/components/CityIntros/CityTrips';
import CityPage from '@/templates/city/CityPage';
import CityTripsPage from '@/templates/city/CityTripsPage';
import CityFactsPage from '@/templates/city/CityFactsPage';
import CityImagesPage from '@/templates/city/CityImagesPage';
import CityClimatePage from '@/templates/city/CityClimatePage';
import IntroWrapper from '@/components/intro/IntroWrapper';
import ContinentMap from '@/components/continentMap/ContinentMap';
import CityMapPage from '@/templates/city/CityMapPage';
import CityVideosPage from '@/templates/city/CityVideosPage';
import CountryVideosPage from '@/templates/country/CountryVideosPage';
import ShipPage from '@/templates/ShipPage';
import GalleryOverviewShip from '@/components/galleryOverviewShip/GalleryOverviewShip';
import WebsiteSearchPage from '@/templates/WebsiteSearchPage';
import MagnoliaForm from '@/components/magnoliaForm/MagnoliaForm';
import FieldSet from '@/components/magnoliaForm/FieldSet';
import Field from '@/components/magnoliaForm/Field';
import Submit from '@/components/magnoliaForm/formElements/Submit';
import Hidden from '@/components/magnoliaForm/formElements/Hidden';
import FormGroup from '@/components/magnoliaForm/FormGroup';
import AccordionElementArea from '@/components/accordion/AccordionElementArea';
import FaqPage from '@/templates/FaqPage';
import InterestListPage from '@/templates/InterestListPage';
import IFrame from '@/components/iFrame/IFrame';
import TopProductTeaserWrapperOuter from '@/components/topProductTeaser/TopProductTeaserWrapperOuter';

export interface MagnoliaMappingConfig {
  componentMappings: { [key: string]: FC<any> };
}

export const config: MagnoliaMappingConfig = {
  componentMappings: {
    'website:pages/product': ProductPage,
    'website:pages/static': StaticPage,
    'website:pages/home': HomePage,
    'website:pages/travelType': TravelTypePage,
    'website:pages/ship': ShipPage,
    'website:pages/faq': FaqPage,
    'website:pages/continent': ContinentPage,
    'website:pages/results': ResultsPage,
    'website:pages/websiteSearch': WebsiteSearchPage,
    'website:pages/checkout': CheckoutPage,
    'website:pages/interestList': InterestListPage,

    'website:pages/country': CountryPage,
    'website:pages/countryFacts': CountryFactsPage,
    'website:pages/countryTravels': CountryTravelsPage,
    'website:pages/countryImages': CountryImagesPage,
    'website:pages/countryVideos': CountryVideosPage,
    'website:pages/countrySights': CountrySightsPage,
    'website:pages/countryEntry': CountryEntryPage,
    'website:pages/countryVaccination': CountryVaccinationPage,
    'website:pages/countryClimate': CountryClimatePage,
    'website:pages/countryMap': CountryMapPage,

    'website:pages/city': CityPage,
    'website:pages/cityTrips': CityTripsPage,
    'website:pages/cityImages': CityImagesPage,
    'website:pages/cityVideos': CityVideosPage,
    'website:pages/cityFacts': CityFactsPage,
    'website:pages/cityClimate': CityClimatePage,
    'website:pages/cityMap': CityMapPage,

    'website:components/galleryOverviewCountry': GalleryOverviewCountry,
    'website:components/galleryOverviewTravelType': GalleryOverviewTravelType,
    'website:components/galleryOverviewTag': GalleryOverviewTag,
    'website:components/galleryOverviewShip': GalleryOverviewShip,
    'website:components/productTeasers': ProductTeasers,
    'website:components/topProductTeaser': TopProductTeaserWrapperOuter,
    'website:components/richText': RichText,
    'website:components/image': StaticImage,
    'website:components/footer': Footer,
    'website:components/header': Header,
    'website:components/highlights': Highlights,
    'website:components/highlightBanner': HighlightBanner,
    'website:components/accordion': Accordion,
    'website:components/accordionElement': AccordionElementArea,
    'website:components/tabs': Tabs,
    'website:components/simpleHeaderText': SimpleHeaderText,
    'website:components/fixedColumnLayout': FixedColumnLayout,
    'website:components/leftNavi': LeftNavi,
    'website:components/featureList': FeatureList,
    'website:components/teaser': Teaser,
    'website:components/link': Link,
    'website:components/linkList': LinkList,
    'website:components/alertBox': AlertBox,
    'website:components/contentModule': ContentModule,
    'website:components/contentRow': ContentRow,
    'website:components/priceInformation': PriceInformation,
    'website:components/imageGallery': ImageGallery,
    'website:components/travelInformation': TravelInformation,
    'website:components/itinerary': Itinerary,
    'website:components/hero': Hero,
    'website:components/videoGallery': VideoGallery,
    'website:components/countryMain': CountryMain,
    'website:components/countryMap': CountryMap,
    'website:components/countryClimate': CountryClimate,
    'website:components/countryEntry': CountryEntry,
    'website:components/countryFacts': CountryFacts,
    'website:components/countrySightseeing': CountrySightseeing,
    'website:components/countryTrips': CountryTrips,
    'website:components/countryMedia': CountryImages,
    'website:components/cityMain': CityMain,
    'website:components/cityClimate': CityClimate,
    'website:components/cityFacts': CityFacts,
    'website:components/cityTrips': CityTrips,
    'website:components/cityMedia': CityImages,
    'website:components/breadcrumb': Breadcrumb,
    'website:components/travelTypeTeasers': TravelTypeTeasers,
    'website:components/linkBanner': LinkBanner,
    'website:components/introduction': IntroWrapper,
    'website:components/continentMap': ContinentMap,
    'website:components/iFrame': IFrame,

    'form:components/form': MagnoliaForm,
    'form:components/formGroupFields': FieldSet,
    'form:components/formEdit': Field,
    'form:components/formSelection': Field,
    'form:components/formNumber': Field,
    'form:components/formSubmit': Submit,
    'form:components/formHidden': Hidden,
    'form:components/formGroupEdit': FormGroup,
    'form:components/formGroupEditItem': Field
  }
};
