'use client';

import React, { JSX, PropsWithChildren } from 'react';
import ContextProvider, { ApplicationProps } from '@/components/contextProviders/ContextProviders';
import { useMount } from 'react-use';
import { lngCookieName } from '@/app/i18n/settingsClient';

const MagnoliaPage = ({
  pageProps,
  isProduction,
  locale,
  children,
  albConfig,
  isLocal,
  withoutSuspense,
  renderOnClientBootstrappingFinished
}: PropsWithChildren<ApplicationProps>): JSX.Element => {
  const { isAuthor, activateCache } = pageProps;
  useMount(async () => {
    // Set language cookie
    await fetch(
      `${process.env.NEXT_PUBLIC_ENDPOINT_COOKIE}?name=${lngCookieName}&value=${locale}`,
      {
        cache: undefined,
        next: { revalidate: isAuthor || !activateCache ? 0 : 600 }
      }
    );
  });

  return (
    <ContextProvider
      isProduction={isProduction}
      pageProps={pageProps}
      locale={locale}
      albConfig={albConfig}
      isLocal={isLocal}
      withoutSuspense={withoutSuspense}
      renderOnClientBootstrappingFinished={renderOnClientBootstrappingFinished}
    >
      {children}
    </ContextProvider>
  );
};

export default MagnoliaPage;
