import React, { FC, JSX, useState } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import ExtrasTeaser from './ExtrasTeaser';
import AccommodationContent from '@/components/accommodations/AccommodationContent';
import IsoModal from '@/components/IsoModal';
import { ApiAccommodation } from '../../../api/model';
import { THEMES } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

const ExtrasSelection: FC<{ checkoutStore: CheckoutStore; pageProps?: Props }> = observer(
  function ExtrasSelection({ checkoutStore, pageProps }): JSX.Element {
    const [selectedExtraAsAccommodation, setSelectedExtraAsAccommodation] = useState<
      ApiAccommodation | undefined
    >(undefined);

    return (
      <>
        <div className="meal-selection">
          {checkoutStore.extras.map(extra =>
            !!extra?.selectableItems?.[0] ? (
              <ExtrasTeaser
                key={extra.id}
                extra={extra.selectableItems[0]}
                checkoutStore={checkoutStore}
                componentId={extra.id}
                isSelected={!!extra?.selectedItems?.[0]}
                setSelectedExtraAsAccommodation={setSelectedExtraAsAccommodation}
              />
            ) : (
              <></>
            )
          )}
        </div>
        <IsoModal
          className="accommodation__modal"
          toggle={(): void => setSelectedExtraAsAccommodation(undefined)}
          isOpen={!!selectedExtraAsAccommodation}
          onClose={(): void => setSelectedExtraAsAccommodation(undefined)}
          theme={THEMES.blue}
        >
          <div className="accommodation__modal__container">
            <AccommodationContent
              accommodation={selectedExtraAsAccommodation}
              simpleMode
              pageProps={pageProps}
            />
          </div>
        </IsoModal>
      </>
    );
  }
);

export default ExtrasSelection;
