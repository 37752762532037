'use client';

import React, { FC, useMemo, JSX } from 'react';
import GridContainer from '@/Layouts/GridContainer';
import { LinkSwitchableMixin, MagnoliaNode } from '@/types/cms/magnolia';
import { THEMES } from '@/Util/globals';
import classNames from 'classnames';
import sanitize from '@/Util/sanitize';
import Link from '@/components/Link';

type LinkType = MagnoliaNode &
  LinkSwitchableMixin & {
    label?: string;
  };

const GRID_SECTION_CLASS = 'grid-section';
const PRODUCT_TEASERS_CLASS = 'product-teasers';
const PULL_UP_CLASS = 'pull-up-sibling';

interface Props extends MagnoliaNode {
  headline?: string;
  button?: LinkType;
  links?: MagnoliaNode & {
    [key: `links${number}`]: LinkType;
  };
  pullUpwards: boolean;
  theme: THEMES;
}

const LinkBanner: FC<Props> = ({ headline, button, links, pullUpwards, theme }): JSX.Element => {
  const refCallback = (ref: HTMLDivElement): void => {
    if (!!ref && pullUpwards) {
      const prevSibling = ref.previousElementSibling;
      if (
        !!prevSibling &&
        (prevSibling.classList.contains(GRID_SECTION_CLASS) ||
          prevSibling.classList.contains(PRODUCT_TEASERS_CLASS))
      ) {
        prevSibling.classList.add(PULL_UP_CLASS);
      }
    }
  };

  const arrayifiedLinks = useMemo(
    (): LinkType[] =>
      !!links
        ? links['@nodes'].map(propertyName => links[propertyName as keyof Props['links']])
        : [],
    [links]
  );

  return (
    <GridContainer containerRef={refCallback} className="link-banner__container">
      <div className={classNames('link-banner', theme || THEMES.darkGreen)}>
        {!!headline && (
          <div
            className="link-banner__headline"
            dangerouslySetInnerHTML={{ __html: sanitize(headline, true) }}
          />
        )}
        <div className="link-banner__links">
          {arrayifiedLinks.map(link => (
            <div key={`${link.linkType}-${link.label}`}>
              <Link
                switchableLink={{
                  ...link,
                  title: link.label || ''
                }}
                showCaret
              />
            </div>
          ))}
          {!!button?.linkType && !!button.label && (
            <div>
              <Link
                className="btn btn-primary"
                switchableLink={{
                  ...button,
                  title: button.label || ''
                }}
                showCaret
              />
            </div>
          )}
        </div>
      </div>
    </GridContainer>
  );
};

export default LinkBanner;
