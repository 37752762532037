/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-03-18 11:04:35 */

const Keys = {
  tripPrice: 'tripPrice',
  singleFee: 'singleFee',
  childPrice: 'childPrice',
  roomUpgrade: 'roomUpgrade',
  priceInfoExcursions: 'priceInfoExcursions',
  priceIncludes: 'priceIncludes',
  priceDoesNotInclude: 'priceDoesNotInclude',
  date: 'date',
  price: 'price'
}; export default Keys;