import React, { Fragment, ReactElement, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Image from '@/components/image/Image';
import { Next, Previous } from '@/Theme/SVG/Svgs';
import { ApiImage } from '../../../api/model';
import { Props as PageProps } from '@/types/cms/magnolia';
import classNames from 'classnames';

type Props = {
  images: ApiImage[];
  pageProps?: PageProps;
};

const customRenderThumb = (transformedImageGallery: Props['images'], pageProps?: PageProps) => () =>
  transformedImageGallery.map(item => {
    return (
      <Fragment key={item.assetId}>
        {item && (
          <span key={item.assetId} className="expanded-gallery__thumb">
            <Image
              image={item}
              width={128}
              height={96}
              serverSideContext={pageProps?.albConfig.context}
              aspectRatio="4-3"
              isThumbnail
              pageProps={pageProps}
            />
          </span>
        )}
      </Fragment>
    );
  });

const ExpandedGallery = (props: Props): ReactElement => {
  const { images, pageProps } = props;

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const currentCaption = images[activeIndex]?.caption?.trim();

  return (
    <div
      className={classNames('expanded-gallery', {
        'expanded-gallery__with-caption': currentCaption
      })}
    >
      <div className="expanded-gallery__label">
        {currentCaption && <div>{currentCaption}</div>}
        <div>
          {activeIndex + 1} / {images.length}
        </div>
      </div>
      <Carousel
        thumbWidth={120}
        renderThumbs={customRenderThumb(images, pageProps)}
        showStatus={false}
        showIndicators={false}
        infiniteLoop
        onChange={index => setActiveIndex(index)}
        renderArrowNext={clickHandler => {
          return (
            <button
              className="nav_btn nav_btn_right expanded-gallery__button expanded-gallery__button--next"
              onClick={clickHandler}
            >
              <Next />
            </button>
          );
        }}
        renderArrowPrev={clickHandler => {
          return (
            <button
              onClick={clickHandler}
              className="nav_btn nav_btn_left expanded-gallery__button expanded-gallery__button--previous"
            >
              <Previous />
            </button>
          );
        }}
      >
        {images.map((imageGallery, index) =>
          imageGallery ? (
            <div key={imageGallery.id ?? imageGallery.assetId}>
              {imageGallery && (
                <Image
                  image={imageGallery}
                  className="expanded-gallery__image"
                  width={960}
                  height={540}
                  aspectRatio="3-2"
                  serverSideContext={pageProps?.albConfig.context}
                  thumbnail={{ image: imageGallery, width: 128, aspectRatio: '4-3' }}
                  pageProps={pageProps}
                />
              )}
            </div>
          ) : (
            <Fragment key={index} />
          )
        )}
      </Carousel>
    </div>
  );
};

export default ExpandedGallery;
