'use client';

import React, { ChangeEvent, FC, JSX, useEffect, useState } from 'react';
import { useMediaQuery, useWindow } from '@ibe/components';
import { useApi } from '@/Hooks/useApi';
import {
  logger,
  MEDIAQUERY_DEFAULTS,
  WEBSITE_SEARCH_TYPE_PARAM,
  WEBSITE_SEARCH_VALUE_PARAM
} from '@/Util/globals';
import { useDebounce } from '@/Hooks/useDebounce';
import WebsiteSearchFullHead, {
  Type,
  types
} from '@/components/websiteSearch/WebsiteSearchFullHead';
import WebsiteSearchFullResults, {
  Results
} from '@/components/websiteSearch/WebsiteSearchFullResults';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { EventType, SearchCategory } from '@/Tracking/types';
import { Props } from '@/types/cms/magnolia';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import {
  ApiWebsiteDestinationSearchResponse,
  ApiWebsiteFaqSearchResponse,
  ApiWebsiteOtherPagesSearchResponse,
  ApiWebsiteProductSearchResponse
} from '@ibe/api';
import { useSearchParams } from 'next/navigation';
import { CLOSE_QUICK_SEARCH_CUSTOM_EVENT } from '@/Layouts/Header/Header';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

const initialResults = {
  product: [],
  destination: [],
  otherPages: [],
  faq: []
};

const WebsiteSearchFull: FC<{ pageProps?: Props }> = ({ pageProps }): JSX.Element => {
  const config = useConfig();
  const locale = useCurrentLanguage();
  const window = useWindow();
  const { isAuthor } = useGlobalMGLProps() || {};
  const api = useApi(isAuthor);
  const searchParams = useSearchParams();
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xm });
  const [selectedType, setSelectedType] = useState<Type>('product');
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Results>({
    ...initialResults
  });

  useEffect(() => {
    (async () => {
      const paramsType = searchParams.get(WEBSITE_SEARCH_TYPE_PARAM) as Type;
      const paramsValue = searchParams.get(WEBSITE_SEARCH_VALUE_PARAM);
      if (!!paramsType && !!paramsValue) {
        setValue(paramsValue);
        setSelectedType(paramsType);
        const closeQuickSearchEvent = new CustomEvent(CLOSE_QUICK_SEARCH_CUSTOM_EVENT);
        window?.document?.dispatchEvent(closeQuickSearchEvent);
        await doRequest(paramsValue);
      }
    })();
  }, [searchParams, window]);

  const doRequest = async (valueParam?: string): Promise<void> => {
    const localValue = valueParam || value;
    if (localValue.length <= 2) return;
    setIsLoading(true);
    broadcastEvent(EventType.SEARCH, config, locale, {
      search: { searchCategory: SearchCategory.WEBSITE, searchTerm: localValue },
      data: { pageProps }
    });
    try {
      const promises: [
        Promise<ApiWebsiteProductSearchResponse>,
        Promise<ApiWebsiteDestinationSearchResponse>,
        Promise<ApiWebsiteOtherPagesSearchResponse>,
        Promise<ApiWebsiteFaqSearchResponse>
      ] = [
        api.websiteSearchProduct(localValue),
        api.websiteSearchDestination(localValue),
        api.websiteSearchOtherPages(localValue),
        api.websiteSearchFaq(localValue)
      ];
      const [productRes, destinationRes, otherPagesRes, faqRes] = await Promise.all(promises);
      setResults({
        product: productRes.results || [],
        destination: destinationRes.results || [],
        otherPages: otherPagesRes.results || [],
        faq: faqRes.results || []
      });
    } catch (err) {
      logger('error')('Unable to fetch website search');
      setResults({ ...initialResults });
    } finally {
      setIsLoading(false);
    }
  };

  const doRequestDebounced = useDebounce(doRequest, 400);

  const onInputChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setValue(e.target.value);
    doRequestDebounced();
  };

  return (
    <div className="website-search-full">
      <WebsiteSearchFullHead
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        value={value}
        onInputChange={onInputChange}
        isDesktop={isDesktop}
        results={results}
      />
      <WebsiteSearchFullResults
        isLoading={isLoading}
        activeIndex={types.findIndex(type => type === selectedType)}
        results={results}
        pageProps={pageProps}
        query={value}
      />
    </div>
  );
};

export default WebsiteSearchFull;
