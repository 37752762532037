import React, { FC, JSX } from 'react';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import RadioResults from '@/components/Search/RadioResults';
import { Action } from '@/components/Search/searchReducer/types';
import { ApiTravelType } from '../../../api/model';

const TravelTypesSearchMobile: FC<
  Pick<SearchProps, 'checkboxDispatch' | 'checkboxState' | 'isLoading'>
> = ({ checkboxDispatch, checkboxState, isLoading }): JSX.Element => {
  const { travelTypes } = checkboxState;

  const checkSingleTravelType = async (travelType: ApiTravelType): Promise<void> => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: travelType.id || '',
        name: travelType.name || '',
        type: 'travelType'
      }
    });
  };

  return (
    <div className="search__content__mobile">
      {travelTypes.map(travelType => (
        <RadioResults
          key={travelType.id}
          id={travelType.id}
          label={travelType.name}
          checked={!!travelType?.id && !!checkboxState.items[travelType.id]}
          onChange={(): Promise<void> => checkSingleTravelType(travelType)}
          results={travelType.products || 0}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
};

export default TravelTypesSearchMobile;
