import React, { FC, JSX } from 'react';
import { ApiProductsCacheResponse } from '@ibe/api';
import { Props } from '@/types/cms/magnolia';
import { Flipper, Flipped } from 'react-flip-toolkit';
import ResultListItemLazy from '@/components/SearchForResults/ResultListItemLazy';

const ResultsList: FC<{
  productPageBasePath: string;
  pageProps?: Props;
  activeView: number;
  sortedPackages: ApiProductsCacheResponse[];
  offset?: number;
  height?: number;
}> = ({
  productPageBasePath,
  pageProps,
  activeView,
  offset,
  height,
  sortedPackages
}): JSX.Element => {
  return (
    <Flipper
      className="results__list__content__flipper"
      flipKey={sortedPackages.map(singlePackage => singlePackage.packageCode).join('')}
    >
      {sortedPackages.length > 0 &&
        (activeView === 0 || activeView === 1) &&
        sortedPackages.map(productPackage => (
          <Flipped key={productPackage.packageCode} flipId={productPackage.packageCode}>
            {flippedProps => (
              <ResultListItemLazy
                key={productPackage.packageCode}
                productPackage={productPackage}
                activeView={activeView}
                pageProps={pageProps}
                productPageBasePath={productPageBasePath}
                flippedProps={flippedProps}
                offset={offset}
                height={height}
              />
            )}
          </Flipped>
        ))}
    </Flipper>
  );
};

export default ResultsList;
