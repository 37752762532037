/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-03-18 11:04:35 */

const Keys = {
  callUs: 'callUs',
  enquire: 'enquire',
  chatWithUs: 'chatWithUs',
  label: 'label',
  phone: 'phone',
  mail: 'mail'
}; export default Keys;