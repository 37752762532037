'use client';

import React, { FC, JSX, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import ContentNavigation from '@/components/contentNavigation/ContentNavigation';
import Keys from '@/Translations/generated/da/product.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { THEMES } from '@/Util/globals';
import ContactBar from '@/components/contactBar/ContactBar';
import getProductPageInnerElements, {
  ProductPageInnerProps
} from '@/templates/ProductPageInnerElements';
import { goToCheckout } from '@/components/ProductsDatesPrices/ProductsDatesPrices';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import SuspenseFallbackComponent from '@/components/SuspenseFallbackComponent/SuspenseFallbackComponent';
import { getCheapestProduct, getProductByOccupancy } from '@/components/Search/helpersProduct';
import { useWindow } from '@ibe/components';
import useConfig from '@/Hooks/useConfig';
import { getVariantParam } from '@/components/ProductsDatesPrices/getVariantParam';
import ProductPageContent from '@/templates/ProductPageContent';
import { useTransitionProgress } from '@/Hooks/useReactTransitionProgress';
import { usePageViewEventOnCookieConsent } from '@/Hooks/usePageViewEventOnCookieConsent';

export const DEPARTURE_DATES_ID = 'departure-dates';

export const ProductPageInner: FC<ProductPageInnerProps> = ({
  productPackages = [],
  header,
  main,
  footer,
  product,
  originalProduct,
  themeContactInformation,
  showContactBar,
  children,
  upperPageContent,
  checkoutPageNode,
  siteConfig,
  isVariant,
  selectedProductPackage,
  reviews,
  pageProps,
  productsAvailabilityState
}): JSX.Element => {
  const { t } = useTranslation('product');
  const startTransition = useTransitionProgress();
  const router = useRouter();
  const config = useConfig();
  const pathName = usePathname();
  const window = useWindow();
  const searchParams = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  usePageViewEventOnCookieConsent(pageProps, config, undefined, {
    productPackage: selectedProductPackage
  });

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [pathName, searchParams]);

  const composedCheckoutUrl = useMemo(() => {
    return getNextLinkAttrs({ linkType: 'page', linkTypepage: checkoutPageNode }, pageProps);
  }, [checkoutPageNode, pageProps]);

  const cheapestCacheProduct = useMemo(() => {
    return selectedProductPackage ? getCheapestProduct(selectedProductPackage) : undefined;
  }, [selectedProductPackage]);

  const componentsList = useMemo(() => {
    return getProductPageInnerElements(t, productsAvailabilityState, product, cheapestCacheProduct);
  }, [t, productsAvailabilityState, product, cheapestCacheProduct]);

  const handleCtaClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>): void => {
      e.preventDefault();
      if (selectedProductPackage?.products?.[0].bookingLink) {
        window?.open(selectedProductPackage?.products?.[0].bookingLink, '_blank');
      } else if (
        !!selectedProductPackage?.products &&
        selectedProductPackage?.products?.length > 0 &&
        !!composedCheckoutUrl.href
      ) {
        const productByOccupancy = getProductByOccupancy(selectedProductPackage);

        if (productByOccupancy) {
          startTransition(() => {
            goToCheckout(
              productByOccupancy,
              composedCheckoutUrl.href,
              router,
              originalProduct,
              window,
              (): void => setIsLoading(true),
              getVariantParam(
                originalProduct,
                productByOccupancy.travelStartDate,
                product?.variants,
                selectedProductPackage.packageCode
              )
            );
          });
        }
      }
    },
    [selectedProductPackage, product, originalProduct]
  );

  return isLoading ? (
    <SuspenseFallbackComponent logoOnly fixedPosition />
  ) : (
    <div className="product-page">
      <div className="page__top">
        {header && <EditableArea className="page__top__header" content={header} />}
        {showContactBar && <ContactBar theme={themeContactInformation || THEMES.darkGreen} />}
      </div>
      <div className="page__center">
        {upperPageContent}
        <ContentNavigation
          items={componentsList}
          productCode={originalProduct?.name || ''}
          ctaItem={{
            label: t(Keys.goToOrder),
            callback: handleCtaClick,
            active:
              !!selectedProductPackage?.products &&
              selectedProductPackage?.products?.length > 0 &&
              !!composedCheckoutUrl.href &&
              !!product
          }}
          productsAvailabilityState={productsAvailabilityState}
        />
        {main && <EditableArea content={main} />}
        <div className="product-page__content">
          <ProductPageContent
            componentsList={componentsList}
            productPackages={productPackages}
            checkoutPageUrl={composedCheckoutUrl.href}
            setIsLoading={setIsLoading}
            productsAvailabilityState={productsAvailabilityState}
            isVariant={isVariant}
            product={product}
            originalProduct={originalProduct}
            siteConfig={siteConfig}
            selectedPackage={selectedProductPackage}
            reviews={reviews}
            pageProps={pageProps}
            cheapestCacheProduct={cheapestCacheProduct}
          />
          {children}
        </div>
      </div>
      <div className="page__bottom">{footer && <EditableArea content={footer} />}</div>
    </div>
  );
};

export default ProductPageInner;
