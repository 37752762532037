import React, { FC, JSX } from 'react';
import GridContainer from '@/Layouts/GridContainer';
import sanitize from '@/Util/sanitize';
import short from 'short-uuid';
import RichTextClient from '@/components/RichText/RichTextClient';

interface Props {
  richText: string;
}

const RichText: FC<Props> = ({ richText }): JSX.Element => {
  const id = short.generate();

  return (
    <>
      <GridContainer className="rich-text">
        <div id={id} dangerouslySetInnerHTML={{ __html: sanitize(richText, true) }} />
      </GridContainer>
      <RichTextClient id={id} />
    </>
  );
};

export default RichText;
