import React, { Fragment, ReactElement, useCallback, useEffect } from 'react';
import Keys from '@/Translations/generated/da/product.json.keys';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import SimpleAccordion from '@/components/accordion/SimpleAccordion';
import { useTransitionMap } from 'react-transition-state';
import { useTranslation } from '@/app/i18n/client';
import GridContainer from '@/Layouts/GridContainer';
import { ApiExcursion } from '../../../api/model';
import ExcursionContent from '@/components/excursions/ExcursionContent';
import Image from '@/components/image/Image';
import { Props } from '@/types/cms/magnolia';

const Excursions = ({
  excursions,
  theme,
  pageProps
}: {
  intro?: string;
  excursions?: ApiExcursion[];
  theme?: string;
  pageProps?: Props;
}): ReactElement => {
  const { t } = useTranslation('product');
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });

  const { stateMap, toggle, setItem } = useTransitionMap<string>({
    timeout: 500,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    if (!!excursions && excursions.length > 0) {
      excursions.forEach(excursion => {
        setItem(excursion.id || '');
      });

      toggle(excursions[0].id || '');
    }
  }, [excursions]);

  const handleExcursionClick = useCallback(
    (id: string) => {
      if (!stateMap.get(id)?.isMounted) {
        toggle(id);
      }
    },
    [stateMap]
  );

  return (
    <GridContainer className={`excursion ${theme || ''}`}>
      {!isDesktop ? (
        <>
          <div className="excursion__headline">
            <h2>{t(Keys.trips)}</h2>
          </div>
          <SimpleAccordion
            groups={
              excursions?.map(excursion => ({
                groupHeader: excursion?.name || '',
                group: [
                  {
                    sectionHeader: '',
                    sectionText: (
                      <ExcursionContent
                        excursion={excursion}
                        className="grid-col-12"
                        pageProps={pageProps}
                      />
                    )
                  }
                ]
              })) || []
            }
          />
        </>
      ) : (
        <>
          <div className="excursion__headline">
            <h2>{t(Keys.trips)}</h2>
          </div>
          <div className="excursion__container">
            <div className="excursion__container__no-print">
              {excursions?.map(excursion => (
                <Fragment key={excursion.id}>
                  {stateMap.get(excursion.id || '')?.status !== 'unmounted' &&
                    stateMap.get(excursion.id || '')?.isMounted && (
                      <ExcursionContent
                        excursion={excursion}
                        className={`excursion__content ${
                          stateMap.get(excursion.id || '')?.status !== 'preEnter' &&
                          stateMap.get(excursion.id || '')?.status !== 'exiting'
                            ? 'excursion__content--visible'
                            : ''
                        }${
                          stateMap.get(excursion.id || '')?.status === 'exiting'
                            ? ' excursion__content--exiting'
                            : ''
                        }`}
                        pageProps={pageProps}
                      />
                    )}
                </Fragment>
              ))}
            </div>
            <div className="excursion__list">
              <div className="excursion__list__sticky">
                {excursions?.map(excursion => (
                  <div
                    key={excursion.id}
                    className={`excursion__name${
                      stateMap.get(excursion.id || '')?.isMounted ? ' active' : ''
                    }`}
                    onClick={() => handleExcursionClick(excursion.id || '')}
                  >
                    <h4>{excursion?.name}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="excursion__list__print">
        {excursions?.map(excursion => (
          <div key={excursion.id}>
            <Image
              image={excursion?.images?.[0] || {}}
              className="excursion__list__image"
              serverSideContext={pageProps?.albConfig.context}
              pageProps={pageProps}
            />
            <ExcursionContent
              excursion={excursion}
              className={`excursion__content ${
                stateMap.get(excursion.id || '')?.status !== 'preEnter' &&
                stateMap.get(excursion.id || '')?.status !== 'exiting'
                  ? 'excursion__content--visible'
                  : ''
              }${
                stateMap.get(excursion.id || '')?.status === 'exiting'
                  ? ' excursion__content--exiting'
                  : ''
              }`}
              pageProps={pageProps}
            />
          </div>
        ))}
      </div>
    </GridContainer>
  );
};

export default Excursions;
