import { apis, logger } from '@/Util/globals';
import { ApiService, AppServiceServer } from '@ibe/services';
import {
  Configuration as ModelApiConfiguration,
  Middleware as ModelApiMiddleware,
  RequestContext as ModelApiRequestContext,
  ModelApi
} from '../../api/model';
import {
  FetchParams,
  Middleware as IbeApiMiddleware,
  RequestContext as IbeApiRequestContext
} from '@ibe/api';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { fallbackCurrency, languageMapping } from '@/app/i18n/settingsClient';

const credentials = {
  username: process.env.MAGNOLIA_AUTH_USER,
  password: process.env.MAGNOLIA_AUTH_PASSWORD
};

const getConfig = (isAuthor?: boolean, activateCache?: boolean) => {
  return {
    basePath: apis.base,
    fetchApi: fetch,
    middleware: [
      new (class implements ModelApiMiddleware {
        async pre(context: ModelApiRequestContext) {
          logger('debug')(`Fetching from model API with url PRE: ${context.url}`);
          return {
            ...context,
            init: {
              ...context.init,
              cache: undefined,
              next: { revalidate: isAuthor || !activateCache ? 0 : 600 }
            }
          };
        }
        async post(context: ModelApiRequestContext) {
          logger('debug')(`Fetching from model API with url POST: ${context.url}`);
        }
      })()
    ]
  };
};

const receiveModelApi = (isAuthor?: boolean, activateCache?: boolean) => {
  let instance: ModelApi;

  function createInstance(): ModelApi {
    return new ModelApi(
      new ModelApiConfiguration({
        ...getConfig(isAuthor, activateCache),
        ...credentials
      })
    );
  }

  return (): ModelApi => {
    if (!instance) {
      instance = createInstance();
      return instance;
    } else {
      return instance;
    }
  };
};

export const getModelApi = (isAuthor?: boolean, activateCache?: boolean): ModelApi => {
  return receiveModelApi(isAuthor, activateCache)();
};

export const useModelApiClient = (isAuthor?: boolean): ModelApi => {
  const { magnoliaHostClient, magnoliaApiBase, isProduction, activateCache } =
    useGlobalMGLProps() || {};
  return new ModelApi(
    new ModelApiConfiguration({
      ...getConfig(isAuthor, activateCache),
      basePath: `${isProduction ? magnoliaHostClient : ''}${magnoliaApiBase}`
    })
  );
};

export const getIbeApiServer = (
  locale: string,
  tenant?: string,
  isAuthor?: boolean,
  activateCache?: boolean
): ApiService => {
  return AppServiceServer.getInstance(
    locale,
    tenant,
    languageMapping[locale]?.currency || fallbackCurrency,
    { basePath: process.env.IBE_API_URL }
  ).api.withMiddleware(
    new (class implements IbeApiMiddleware {
      async pre(context: IbeApiRequestContext): Promise<void | FetchParams> {
        logger('debug')(
          `Fetching from IBE API with url PRE: ${context.url}, full: ${JSON.stringify(
            context.init
          )}`
        );
        return {
          ...context,
          init: {
            ...context.init,
            cache: undefined,
            next: { revalidate: isAuthor || !activateCache ? 0 : 600 }
          }
        };
      }
      async post(context: ModelApiRequestContext) {
        logger('debug')(`Fetching from IBE API with url POST: ${context.url}`);
      }
    })()
  );
};

export function apiFetch(
  url: string,
  init?: RequestInit,
  isAuthor?: boolean,
  activateCache?: boolean
): ReturnType<typeof fetch> {
  if (!process.env.MAGNOLIA_AUTH_USER || !process.env.MAGNOLIA_AUTH_PASSWORD) {
    return fetch(url, {
      ...(init || {}),
      cache: undefined,
      next: { revalidate: isAuthor || !activateCache ? 0 : 600 }
    });
  }
  const credentials = Buffer.from(
    process.env.MAGNOLIA_AUTH_USER + ':' + process.env.MAGNOLIA_AUTH_PASSWORD
  ).toString('base64');
  return fetch(url, {
    ...(init || {}),
    headers: Object.assign(init?.headers ?? {}, {
      Authorization: `Basic ${credentials}`
    }),
    // cache: isAuthor ? 'no-store' : 'force-cache',
    cache: undefined,
    next: { revalidate: isAuthor || !activateCache ? 0 : 600 }
  });
}
