'use client';

import React, { FC, JSX } from 'react';
import { HiddenField } from '@/components/magnoliaForm/types';
import { Input } from 'reactstrap';

const Hidden: FC<HiddenField> = ({ value, controlName, fieldWidth }): JSX.Element => {
  return (
    <div className="magnolia-form__field" style={{ flex: fieldWidth || '1' }}>
      <Input id={controlName} name={controlName} type="hidden" value={value || ''} />
    </div>
  );
};

export default Hidden;
