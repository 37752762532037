'use client';

import React, { Dispatch, JSX, ReactElement, SetStateAction, useState } from 'react';
import AccordionElement from '@/components/accordion/AccordionElement';
import { useProductPageContext } from '@/components/productPageContext/ProductPageContext';

interface Props<T> {
  items: Array<T>;
  bodyCb: (item: T) => ReactElement;
  headerCb: (item: T) => ReactElement;
  indexerProp?: string;
  externalSelectedAccordion?: string | null;
  externalSetSelectedAccordion?: Dispatch<SetStateAction<string | null>>;
  externalPrefix?: number;
  openAllInitially?: boolean;
  setAllOpen?: (state: boolean) => void;
  allOpen?: boolean;
}

const isObject = (
  selectedAccordion: number | null | Record<string, boolean>
): selectedAccordion is Record<string, boolean> => {
  return typeof selectedAccordion === 'object';
};

const AccordionElementList = <T extends Record<string, any>>({
  items,
  bodyCb,
  headerCb,
  indexerProp,
  externalSetSelectedAccordion,
  externalSelectedAccordion,
  externalPrefix,
  openAllInitially,
  setAllOpen,
  allOpen
}: Props<T>): JSX.Element => {
  const [selectedAccordion, setSelectedAccordion] = useState<
    number | null | Record<string, boolean>
  >(openAllInitially ? Object.fromEntries(items.map((_, idx) => [idx, true])) : 0);
  const { printMode } = useProductPageContext();

  const openHandler = (index: number) => {
    if (!!externalSetSelectedAccordion) {
      externalSetSelectedAccordion(
        `${externalPrefix}${index}` === externalSelectedAccordion
          ? null
          : `${externalPrefix}${index}`
      );
    } else {
      if (openAllInitially && isObject(selectedAccordion)) {
        setSelectedAccordion({ ...selectedAccordion, [index]: !selectedAccordion[index] });
      } else {
        setSelectedAccordion(index === selectedAccordion ? null : index);
      }
    }
  };

  return (
    <>
      {items.map((item: T, idx: number) => (
        <AccordionElement
          key={!!indexerProp && !!item[indexerProp] ? item[indexerProp] : idx}
          accordionBody={bodyCb(item)}
          accordionHeader={headerCb(item)}
          isOpen={
            allOpen ||
            printMode ||
            (openAllInitially && isObject(selectedAccordion)
              ? selectedAccordion?.[idx]
              : (externalSelectedAccordion !== undefined &&
                  externalSelectedAccordion === `${externalPrefix}${idx}`) ||
                selectedAccordion === idx)
          }
          openHandler={(): void => {
            openHandler(idx);
            if (setAllOpen) {
              setAllOpen(false);
            }
          }}
        />
      ))}
    </>
  );
};

export default AccordionElementList;
