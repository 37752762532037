'use client';

import React, { JSX } from 'react';
import Keys from '@/Translations/generated/da/country.json.keys';
import Intro from '@/components/intro/Intro';
import { useTranslation } from '@/app/i18n/client';
import { THEMES } from '@/Util/globals';
import Image from '@/components/image/Image';
import { ApiCountry } from '../../../api/model';
import sanitize from '@/Util/sanitize';
import { EditableAreaOrPageChild } from '@/types/cms/magnolia';

const CountryVaccination = ({
  country,
  pageProps,
  pathname
}: {
  country?: ApiCountry | null;
  numberOfProductsForCountry: number;
  pathname: string;
} & Partial<EditableAreaOrPageChild>): JSX.Element => {
  const {
    name,
    introTitleVaccination,
    introTextVaccination,
    introImageVaccination,
    themeIntroVaccination
  } = country || {};

  const { t } = useTranslation('country');

  return (
    <>
      <Intro
        headline={
          introTitleVaccination ? introTitleVaccination : `${t(Keys.headlineVaccination)} ${name}`
        }
        pageProps={pageProps}
        pathname={pathname}
        singleColumn={
          <div className="intro__single-column">
            {!!introImageVaccination && (
              <div className="intro__single-column__image intro__single-column__image--right">
                <Image
                  image={introImageVaccination}
                  className="intro__container__image"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  aspectRatio="4-3"
                  serverSideContext={pageProps?.albConfig.context}
                  pageProps={pageProps}
                />
              </div>
            )}
            {!!introTextVaccination && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(introTextVaccination, true)
                }}
              />
            )}
          </div>
        }
        theme={themeIntroVaccination || THEMES.blue}
      />
    </>
  );
};

export default CountryVaccination;
