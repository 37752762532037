import { ApiProduct } from '../../../../api/model';
import { Product, WithContext } from 'schema-dts';

export const getSchemaOrgJsonProductReview = (
  product: ApiProduct,
  resolvedUrl?: string
): WithContext<Product> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    '@id': resolvedUrl || '',
    name: product.name || '',
    image: product.heroBackgroundImage?.imageLink || '',
    description: product.tripDescription || ''
  };
};
