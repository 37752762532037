import React, { FC, Fragment, JSX } from 'react';
import { ApiProductsCacheResponse } from '@ibe/api';
import {
  getSortedCacheProductsByDate,
  YEAR_MONTH_DELIMITER
} from '@/components/SearchForResults/helperFns';
import ResultsListItemCalendar from '@/components/SearchForResults/ResultsListItemCalendar';
import { useMediaQuery } from '@ibe/components';
import { Props } from '@/types/cms/magnolia';
import LazyLoad from 'react-lazyload';
import FadeIn from '@/components/SearchForResults/FadeIn';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import useMonthLabels from '@/Hooks/useMonthLabels';
import { Flipper, Flipped } from 'react-flip-toolkit';

const ResultsListCalendarView: FC<{
  productPackages: ApiProductsCacheResponse[];
  hideUnavailableTripsChecked: boolean;
  productPageBasePath: string;
  pageProps?: Props;
}> = ({
  productPackages,
  hideUnavailableTripsChecked,
  productPageBasePath,
  pageProps
}): JSX.Element => {
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const monthLabels = useMonthLabels();

  return (
    <Flipper
      flipKey={Object.entries(getSortedCacheProductsByDate(productPackages))
        .map(([year_month]) => year_month)
        .join('-')}
    >
      {Object.entries(getSortedCacheProductsByDate(productPackages)).map(
        ([year_month, products]) => {
          const year = year_month.split(YEAR_MONTH_DELIMITER)[0];
          const month = year_month.split(YEAR_MONTH_DELIMITER)[1];
          const atLeastOneCacheResponseHasAvailabilities = products.some(cacheResponse =>
            cacheResponse.products?.some(cacheData => (cacheData.availability || 0) > 0)
          );
          return !hideUnavailableTripsChecked ||
            (hideUnavailableTripsChecked && atLeastOneCacheResponseHasAvailabilities) ? (
            <Flipped key={year_month} flipId={year_month}>
              <div key={year_month}>
                <h4>{`${monthLabels[parseInt(month, 10)]} ${year}`}</h4>
                <div className="position-relative">
                  {!!products &&
                    products.map(product => {
                      const productKey = `${product.packageCode}-${product.products?.[0]?.travelStartDate}`;
                      const cacheResponsesSplitByCodes = product.products?.reduce(
                        (total: Record<string, ApiProductsCacheResponse>, current) => {
                          const currentCode = current.packageCode || '';
                          return currentCode in total
                            ? {
                                ...total,
                                [currentCode]: {
                                  ...total[currentCode],
                                  products: [...(total[currentCode].products || []), current]
                                }
                              }
                            : {
                                ...total,
                                [currentCode]: {
                                  ...product,
                                  products: [current]
                                }
                              };
                        },
                        {}
                      ) || { [product.packageCode || '']: product };
                      return (
                        <Fragment key={productKey}>
                          {Object.entries(cacheResponsesSplitByCodes).map(([, responseByCode]) => {
                            const atLeastOneCacheDataHasAvailabilities =
                              responseByCode.products?.some(
                                cacheData => (cacheData.availability || 0) > 0
                              );
                            const cacheDataKey = `${responseByCode.packageCode}-${responseByCode.products?.[0]?.travelStartDate}`;
                            return !!responseByCode?.products?.[0] &&
                              (!hideUnavailableTripsChecked ||
                                (hideUnavailableTripsChecked &&
                                  atLeastOneCacheDataHasAvailabilities)) ? (
                              <Flipped key={cacheDataKey} flipId={cacheDataKey}>
                                <div>
                                  <LazyLoad
                                    key={cacheDataKey}
                                    throttle={300}
                                    offset={400}
                                    height={isDesktop ? 170 : 520}
                                    scroll
                                    resize
                                  >
                                    <FadeIn>
                                      <ResultsListItemCalendar
                                        key={cacheDataKey}
                                        product={responseByCode}
                                        productPageBasePath={productPageBasePath}
                                        pageProps={pageProps}
                                      />
                                    </FadeIn>
                                  </LazyLoad>
                                </div>
                              </Flipped>
                            ) : (
                              <Fragment key={cacheDataKey}></Fragment>
                            );
                          })}
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            </Flipped>
          ) : (
            <Fragment key={year_month} />
          );
        }
      )}
    </Flipper>
  );
};

export default ResultsListCalendarView;
