import React, { Dispatch, FC, JSX, RefObject, SetStateAction, useEffect, useState } from 'react';
import CheckoutStore, {
  CHECKOUT_STORAGE_KEY,
  CheckoutStorage
} from '@/templates/checkout/CheckoutStore';
import CheckoutStep from '@/components/checkout/CheckoutStep';
import CheckoutStepHotel from '@/components/checkout/CheckoutStepHotel';
import CheckoutStepExtras from '@/components/checkout/CheckoutStepExtras';
import CheckoutStepParticipants from '@/components/checkout/CheckoutStepParticipants';
import CheckoutStepConfirmation, {
  BookingIdAfterInvoiceBooking,
  PacificProductForHeroDispatch
} from '@/components/checkout/checkoutStepConfirmation/CheckoutStepConfirmation';
import classNames from 'classnames';
import Cart from '@/components/checkout/cart/Cart';
import { ProgressBarItem } from '@/components/ProgressBar/ProgressBar';
import { ApiAirlineLogo } from '../../../api/model';
import { ExternalLinks } from '@/components/checkout/TermsAndConditions';
import { FormRefs } from '@/components/checkout/ParticipantsForm';
import { DisabledPaymentOptions } from '@/components/checkout/payment/PaymentContent';
import { SessionStoragePersistence } from '@ibe/services';
import { Props } from '@/types/cms/magnolia';
import { observer } from 'mobx-react';
import {
  OnlinePaymentCaller,
  OnlinePaymentCallerProps
} from '@/templates/checkout/CheckoutPageInnerMarkup';

const CheckoutStepsContainer: FC<{
  checkoutStore: CheckoutStore;
  activeIndex: number;
  activeSubIndex: number[];
  numberOfItems: number;
  progressBarItems: ProgressBarItem[];
  externalLinks: ExternalLinks;
  participantsFormRefs: RefObject<FormRefs>;
  airlineLogos?: ApiAirlineLogo[];
  isDesktop: boolean;
  numberOfSubStepsPerStep: number[];
  activePaymentTab: number;
  setActivePaymentTab: Dispatch<SetStateAction<number>>;
  setActivePaymentRadio: Dispatch<SetStateAction<number>>;
  activePaymentRadio: number;
  disabledPaymentOptions: DisabledPaymentOptions;
  setIsOnlinePaymentDisabled: (active: boolean, caller: OnlinePaymentCaller) => void;
  onlinePaymentSettingState: OnlinePaymentCallerProps;
  bookingIdAfterInvoiceBooking: BookingIdAfterInvoiceBooking;
  lastStepInDesktop: number;
  confirmationPageMode: boolean;
  useParticipantsMock: boolean;
  stepChangeTriggered: boolean;
  pageProps?: Props;
  setIgnoreBusSeatValidation: Dispatch<SetStateAction<boolean>>;
  ignoreBusSeatValidation: boolean;
  setPacificProduct: PacificProductForHeroDispatch;
  consentGiven: boolean;
}> = observer(function CheckoutStepsContainer({
  checkoutStore,
  activeIndex,
  numberOfItems,
  progressBarItems,
  airlineLogos,
  externalLinks,
  participantsFormRefs,
  isDesktop,
  activeSubIndex,
  numberOfSubStepsPerStep,
  activePaymentTab,
  setActivePaymentTab,
  activePaymentRadio,
  setActivePaymentRadio,
  disabledPaymentOptions,
  setIsOnlinePaymentDisabled,
  onlinePaymentSettingState,
  bookingIdAfterInvoiceBooking,
  lastStepInDesktop,
  confirmationPageMode,
  useParticipantsMock,
  stepChangeTriggered,
  pageProps,
  setIgnoreBusSeatValidation,
  ignoreBusSeatValidation,
  setPacificProduct,
  consentGiven
}): JSX.Element {
  const [useFakeStep, setUseFakeStep] = useState<boolean>(false);

  useEffect(() => {
    const storage = new SessionStoragePersistence<CheckoutStorage>(CHECKOUT_STORAGE_KEY);
    const { numberOfSteps } = storage.get();
    if (numberOfSteps === 4 && confirmationPageMode) {
      setUseFakeStep(true);
    }
  }, [confirmationPageMode]);

  return (
    <>
      <div
        className={classNames('checkout__content__left', {
          'checkout__content__left--no-padding': activeIndex === lastStepInDesktop
        })}
      >
        <div
          className="checkout__content__inner"
          style={{
            width: `${progressBarItems.length * 100}%`,
            transform: `translateX(${activeIndex * (100 / progressBarItems.length) * -1}%)`
          }}
        >
          <CheckoutStep index={0} activeIndex={activeIndex} numberOfItems={numberOfItems}>
            <CheckoutStepHotel
              checkoutStore={checkoutStore}
              airlineLogos={airlineLogos}
              isDesktop={isDesktop}
              activeIndex={activeIndex}
              activeSubIndex={activeSubIndex}
              numberOfSubStepsPerStep={numberOfSubStepsPerStep}
              index={0}
              stepChangeTriggered={stepChangeTriggered}
              pageProps={pageProps}
              setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
              ignoreBusSeatValidation={ignoreBusSeatValidation}
              participantsFormRefs={participantsFormRefs}
              setPacificProduct={setPacificProduct}
              consentGiven={consentGiven}
            />
          </CheckoutStep>
          {(numberOfSubStepsPerStep.length === 4 || useFakeStep) && (
            <CheckoutStep index={1} activeIndex={activeIndex} numberOfItems={numberOfItems}>
              {numberOfSubStepsPerStep.length === 4 && !confirmationPageMode && (
                <CheckoutStepExtras
                  checkoutStore={checkoutStore}
                  isDesktop={isDesktop}
                  activeIndex={activeIndex}
                  activeSubIndex={activeSubIndex}
                  numberOfSubStepsPerStep={numberOfSubStepsPerStep}
                  index={1}
                  pageProps={pageProps}
                />
              )}
              {useFakeStep && <div />}
            </CheckoutStep>
          )}
          <CheckoutStep
            index={numberOfSubStepsPerStep.length === 4 || useFakeStep ? 2 : 1}
            activeIndex={activeIndex}
            numberOfItems={numberOfItems}
          >
            <CheckoutStepParticipants
              externalLinks={externalLinks}
              checkoutStore={checkoutStore}
              participantsFormRefs={participantsFormRefs}
              isDesktop={isDesktop}
              activeIndex={activeIndex}
              activeSubIndex={activeSubIndex}
              numberOfSubStepsPerStep={numberOfSubStepsPerStep}
              index={numberOfSubStepsPerStep.length === 4 || useFakeStep ? 2 : 1}
              activePaymentTab={activePaymentTab}
              setActivePaymentTab={setActivePaymentTab}
              setActivePaymentRadio={setActivePaymentRadio}
              activePaymentRadio={activePaymentRadio}
              disabledPaymentOptions={disabledPaymentOptions}
              setIsOnlinePaymentDisabled={setIsOnlinePaymentDisabled}
              onlinePaymentSettingState={onlinePaymentSettingState}
              useParticipantsMock={useParticipantsMock}
            />
          </CheckoutStep>
          <CheckoutStep
            index={numberOfSubStepsPerStep.length === 4 || useFakeStep ? 3 : 2}
            activeIndex={activeIndex}
            numberOfItems={numberOfItems}
          >
            <CheckoutStepConfirmation
              index={numberOfSubStepsPerStep.length === 4 || useFakeStep ? 3 : 2}
              bookingIdAfterInvoiceBooking={bookingIdAfterInvoiceBooking}
              checkoutStore={checkoutStore}
              externalLinks={externalLinks}
              remarksFieldIsFilled={onlinePaymentSettingState.remark}
              activeIndex={activeIndex}
              pageProps={pageProps}
              setPacificProduct={setPacificProduct}
            />
          </CheckoutStep>
        </div>
      </div>
      <div
        className={classNames('checkout__content__right', {
          'checkout__content__right--hidden': activeIndex === lastStepInDesktop
        })}
      >
        <div className="checkout__price">
          <div className="checkout__price__inner">
            <Cart
              checkoutStore={checkoutStore}
              setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default CheckoutStepsContainer;
