'use client';

import React, { FC, JSX } from 'react';
import { InputField } from '@/components/magnoliaForm/types';
import { Input as ReactInput, Label } from 'reactstrap';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { useMagnoliaFormContext } from '@/components/magnoliaForm/Form';
import { languageMapping } from '@/app/i18n/settingsClient';

const Input: FC<InputField> = ({
  rows,
  controlName,
  inputType,
  title,
  readonly,
  disabled,
  autocomplete,
  autofocus,
  placeholder,
  mandatory,
  maxLength,
  subtitle
}): JSX.Element => {
  const locale = useCurrentLanguage();
  const { isDirty, validationErrors, requiredSymbol } = useMagnoliaFormContext();

  return (
    <div className="magnolia-form__input">
      {((!!title && !!placeholder) ||
        (!!title &&
          (inputType === 'date' ||
            inputType === 'datetime' ||
            inputType === 'datetime-local'))) && (
        <Label>{`${title}${mandatory ? requiredSymbol : ''}`}</Label>
      )}
      {rows > 1 ? (
        <textarea
          id={controlName}
          name={controlName}
          rows={rows}
          placeholder={!placeholder ? title : placeholder || ''}
          readOnly={readonly}
          disabled={disabled}
          autoComplete={autocomplete ? 'one' : 'off'}
          autoFocus={autofocus}
          maxLength={!!maxLength && !isNaN(Number(maxLength)) ? Number(maxLength) : 50}
        />
      ) : (
        <ReactInput
          id={controlName}
          name={controlName}
          type={inputType || 'text'}
          placeholder={
            !!placeholder || !!title
              ? `${placeholder || title}${mandatory ? requiredSymbol : ''}`
              : ''
          }
          readOnly={readonly}
          disabled={disabled}
          autoComplete={autocomplete ? 'one' : 'off'}
          autoFocus={autofocus}
          lang={languageMapping[locale]?.language}
          maxLength={!!maxLength && !isNaN(Number(maxLength)) ? Number(maxLength) : 50}
        />
      )}
      {!!subtitle && (!isDirty || !validationErrors[controlName]) && (
        <div className="magnolia-form__input__subtitle">{subtitle}</div>
      )}
    </div>
  );
};

export default Input;
