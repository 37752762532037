import React, { JSX, forwardRef } from 'react';
import FadeIn from '@/components/SearchForResults/FadeIn';
import ResultsListItem from '@/components/SearchForResults/ResultsListItem';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { ApiProductsCacheResponse } from '@ibe/api';
import { Props } from '@/types/cms/magnolia';
import { useMount } from 'react-use';

const ResultListItemLazy = forwardRef<
  HTMLDivElement,
  {
    productPackage: ApiProductsCacheResponse;
    activeView: number;
    pageProps?: Props;
    productPageBasePath: string;
    flippedProps: object;
    offset?: number;
    height?: number;
  }
>(function ResultListItemLazy(
  { productPackage, activeView, pageProps, productPageBasePath, flippedProps, offset, height },
  ref
): JSX.Element {
  useMount(() => {
    let intervals = 4;
    const interval = setInterval(() => {
      forceCheck();
      --intervals;
      if (intervals === 0) {
        clearInterval(interval);
      }
    }, 600);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div key={productPackage.packageCode} ref={ref} {...flippedProps}>
      <LazyLoad
        key={productPackage.packageCode}
        throttle={300}
        offset={offset ?? 400}
        height={height ?? 420}
        scroll
        resize
      >
        <FadeIn>
          <ResultsListItem
            key={productPackage.packageCode}
            product={productPackage}
            productPageBasePath={productPageBasePath}
            activeView={activeView}
            pageProps={pageProps}
          />
        </FadeIn>
      </LazyLoad>
    </div>
  );
});

export default ResultListItemLazy;
