import React, { ChangeEvent, FC, JSX, useCallback, useEffect, useState } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { Input } from 'reactstrap';
import Button from '@/components/Button';
import GridContainer from '@/Layouts/GridContainer';
import classNames from 'classnames';
import { observer } from 'mobx-react';

const PromoCode: FC<{ checkoutStore: CheckoutStore }> = observer(function PromoCode({
  checkoutStore
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const [promoCodeInput, setPromoCodeInput] = useState<string>('');

  useEffect(() => {
    if (checkoutStore.promoCodeStatus !== 'nothing') {
      setTimeout(() => {
        checkoutStore.promoCodeStatus = 'nothing';
      }, 10000);
    }
  }, [checkoutStore.promoCodeStatus]);

  const handlePromoCodeClick = useCallback(async (): Promise<void> => {
    setPromoCodeInput('');
    await checkoutStore.addPromoCode(promoCodeInput);
  }, [promoCodeInput]);

  return (
    <GridContainer className="promo-code">
      <div className="promo-code__text">
        <div>{t(Keys.promoCodeHeadline)}</div>
        <div>{t(Keys.promoCodeText)}</div>
      </div>
      <div className="payment__voucher__input">
        <Input
          value={promoCodeInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPromoCodeInput(e.target.value)}
        />
        <Button color="primary" disabled={!promoCodeInput} onClick={handlePromoCodeClick}>
          {t(Keys.activate)}
        </Button>
      </div>
      {checkoutStore.promoCodeStatus !== 'nothing' && (
        <div
          className={classNames('payment__voucher__status', {
            'payment__voucher__status--success': checkoutStore.promoCodeStatus === 'success'
          })}
        >
          {checkoutStore.promoCodeStatus === 'success'
            ? t(Keys.promoCodeSuccess)
            : checkoutStore.promoCodeStatus === 'notFound'
              ? t(Keys.promoCodeInvalid)
              : t(Keys.promoCodeError)}
        </div>
      )}
    </GridContainer>
  );
});

export default PromoCode;
