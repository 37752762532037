'use client';

import React, { ReactElement } from 'react';
import { useMediaQuery, MEDIAQUERY_DEFAULTS } from '@ibe/components';
import sanitize from '@/Util/sanitize';
import { getDefaultFormatPrice, THEMES } from '@/Util/globals';
import GridContainer from '@/Layouts/GridContainer';
import { ApiPriceInformation, ApiProduct } from '../../../api/model';
import Image from '@/components/image/Image';
import { ApiProductsCacheData } from '@ibe/api';
import Keys from '@/Translations/generated/da/priceInformation.json.keys';
import { useTranslation } from '@/app/i18n/client';
import PriceInformationStaticPart from '@/components/priceInformation/PriceInformationStaticPart';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { Props as PageProps } from '@/types/cms/magnolia';
import dayjs from 'dayjs';
import useConfig from '@/Hooks/useConfig';

const DEFAULT_GRID_COLUMN_BREAK_AMOUNT = 1000;

type Props = {
  priceInformation?: ApiPriceInformation[];
  theme: string;
  headline?: string;
  product?: ApiProduct;
  selectedProduct?: ApiProductsCacheData;
  pageProps?: PageProps;
};

const PriceInformation = (props: Props): ReactElement => {
  const { priceInformation, pageProps, theme, headline, product, selectedProduct } = props;
  const isDesktop = useMediaQuery(`(min-width: ${MEDIAQUERY_DEFAULTS.lg}px)`, true);
  const { t } = useTranslation('priceInformation');
  const locale = useCurrentLanguage();
  const config = useConfig();

  return (
    <>
      <GridContainer className={`price-information ${theme || THEMES.sand}`}>
        {!!headline && <h2 className="price-information__headline">{headline}</h2>}
        {!!product?.imageGallery?.[2] && (
          <Image
            image={product.imageGallery[2]}
            className="price-information__image"
            serverSideContext={pageProps?.albConfig.context}
            pageProps={pageProps}
          />
        )}
        {!!selectedProduct && (
          <>
            <div className="price-information__banner">
              {!!selectedProduct?.travelStartDate && (
                <div className="grid-col-3">
                  <div className="price-information__banner__headline">{t(Keys.date)}</div>
                  <p>
                    {dayjs(selectedProduct?.travelStartDate).format(
                      config.displayFormatDate[locale]
                    )}
                  </p>
                </div>
              )}
              {!!selectedProduct?.startingPriceAmount && (
                <div className="grid-col-3">
                  <div className="price-information__banner__headline">{t(Keys.price)}</div>
                  <p>{getDefaultFormatPrice(selectedProduct?.startingPriceAmount || 0, locale)}</p>
                </div>
              )}
              {!!selectedProduct?.includedSupplement && (
                <div className="grid-col-3">
                  <div className="price-information__banner__headline">{t(Keys.singleFee)}</div>
                  <p>{getDefaultFormatPrice(selectedProduct?.includedSupplement || 0, locale)}</p>
                </div>
              )}
            </div>
            {!!selectedProduct?.startingPriceAmount && (
              <PriceInformationStaticPart
                priceInformationHeadline={t(Keys.tripPrice)}
                priceInformationText={
                  !!selectedProduct?.startingPriceAmount
                    ? `${getDefaultFormatPrice(selectedProduct?.startingPriceAmount || 0, locale)}`
                    : ''
                }
              />
            )}
            {!!selectedProduct?.includedSupplement && (
              <PriceInformationStaticPart
                priceInformationHeadline={t(Keys.singleFee)}
                priceInformationText={
                  !!selectedProduct?.includedSupplement
                    ? `${getDefaultFormatPrice(selectedProduct?.includedSupplement || 0, locale)}`
                    : ''
                }
              />
            )}
          </>
        )}
        {!!product?.childPrice && (
          <PriceInformationStaticPart
            priceInformationHeadline={t(Keys.childPrice)}
            priceInformationText={product.childPrice}
          />
        )}
        {!!product?.roomUpgrade && (
          <PriceInformationStaticPart
            priceInformationHeadline={t(Keys.roomUpgrade)}
            priceInformationText={product.roomUpgrade}
          />
        )}
        {!!product?.priceInfoExcursions && (
          <PriceInformationStaticPart
            priceInformationHeadline={t(Keys.priceInfoExcursions)}
            priceInformationText={product.priceInfoExcursions}
          />
        )}
        {!!product?.priceIncludes && (
          <PriceInformationStaticPart
            priceInformationHeadline={t(Keys.priceIncludes)}
            priceInformationText={product.priceIncludes}
          />
        )}
        {!!product?.priceDoesNotInclude && (
          <PriceInformationStaticPart
            priceInformationHeadline={t(Keys.priceDoesNotInclude)}
            priceInformationText={product.priceDoesNotInclude}
          />
        )}
        {!!priceInformation &&
          priceInformation.map((singlePriceInformation, index) => (
            <div key={index} className="price-information__container">
              <div className="grid">
                <h5 className="grid-col-12 grid-col-lg-3">{singlePriceInformation.title}</h5>
                {singlePriceInformation.body &&
                  (singlePriceInformation.body?.length >
                    (!!singlePriceInformation.charsAmountForColumnBreak &&
                    !Number.isNaN(parseInt(singlePriceInformation.charsAmountForColumnBreak, 10))
                      ? parseInt(singlePriceInformation.charsAmountForColumnBreak, 10)
                      : DEFAULT_GRID_COLUMN_BREAK_AMOUNT) && isDesktop ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitize(singlePriceInformation.body, true)
                      }}
                      className="price-information__body grid-col-9"
                    />
                  ) : (
                    <div className="grid-col-12 grid-col-lg-9 grid">
                      <div
                        className="price-information__body--unwrapped grid-col-12 grid-col-lg-6"
                        dangerouslySetInnerHTML={{
                          __html: sanitize(singlePriceInformation.body, true)
                        }}
                      />
                    </div>
                  ))}
              </div>
              {index < priceInformation.length - 1 && <hr className="price-information__line" />}
            </div>
          ))}
      </GridContainer>
    </>
  );
};

export default PriceInformation;
