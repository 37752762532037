/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-03-18 11:04:35 */

const Keys = {
  itinerary: 'itinerary',
  home: 'home',
  destinations: 'destinations',
  introMain: 'introMain',
  contentAreas: 'contentAreas',
  goToOrder: 'goToOrder',
  download: 'download',
  trips: 'trips',
  headline: 'headline',
  moreInformation: 'moreInformation',
  citizens: 'citizens',
  capital: 'capital',
  language: 'language',
  cities: 'cities',
  amount: 'amount',
  headlineMap: 'headlineMap',
  headlineTrips: 'headlineTrips',
  headlineImages: 'headlineImages',
  headlineVideos: 'headlineVideos',
  headlineSightseeing: 'headlineSightseeing',
  headlineEntry: 'headlineEntry',
  headlineVaccination: 'headlineVaccination',
  subHeadlineEntry: 'subHeadlineEntry',
  headlineFacts: 'headlineFacts',
  headlineClimate: 'headlineClimate',
  showAllTrips: 'showAllTrips',
  capitalCity: 'capitalCity',
  religion: 'religion',
  currency: 'currency',
  area: 'area',
  foundation: 'foundation'
}; export default Keys;