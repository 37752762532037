import React, { Dispatch, FC, JSX, SetStateAction, useEffect, useState } from 'react';
import short from 'short-uuid';
import { ApiProductsCacheData, ApiProductsCacheResponse, ApiProductWithReviews } from '@ibe/api';
import { PRODUCT_AVAILABILITY_STATE, THEMES } from '@/Util/globals';
import { ApiProduct, ApiVideoContent } from '../../api/model';
import { MagnoliaSite, MagnoliaVideos, Props as PageProps } from '@/types/cms/magnolia';
import { ContentNavigationType } from '@/components/contentNavigation/ContentNavigation';
import { DEPARTURE_DATES_ID } from '@/templates/ProductPageInner';
import { AspectRatio } from '@/Util/imageLoader';
import Introduction from '@/components/introduction/Introduction';
import SingleHotel from '@/components/singleHotel/SingleHotel';
import AccommodationList from '@/components/accommodations/AccommodationList';
import Keys from '@/Translations/generated/da/product.json.keys';
import ImageGallery from '@/components/imageGallery/ImageGallery';
import { useTranslation } from '@/app/i18n/client';
import Itinerary from '@/components/itinerary/Itinerary';
import ProductsDatesPrices from '@/components/ProductsDatesPrices/ProductsDatesPrices';
import PriceInformation from '@/components/priceInformation/PriceInformation';
import ContentModuleDynamic from '@/components/contentModule/ContentModuleDynamic';
import Excursions from '@/components/excursions/Excursions';
import ExtensionTeaser from '@/components/extensions/ExtensionTeaser';
import TravelInformation from '@/components/travelInformation/TravelInformation';
import VideoGallery from '@/components/videoGallery/VideoGallery';
import BVDetailedReviews from '@/components/bazaarVoice/BVDetailedReviews';
import { fallbackLng } from '@/app/i18n/settingsClient';
import { useProductPageContext } from '@/components/productPageContext/ProductPageContext';
import { useSearchParams } from 'next/navigation';

const ProductPageContent: FC<{
  productPackages: ApiProductsCacheResponse[];
  checkoutPageUrl: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  productsAvailabilityState: PRODUCT_AVAILABILITY_STATE;
  isVariant?: boolean;
  product?: ApiProduct;
  originalProduct?: ApiProduct;
  siteConfig?: MagnoliaSite;
  selectedPackage?: ApiProductsCacheResponse;
  reviews?: ApiProductWithReviews;
  pageProps?: PageProps;
  cheapestCacheProduct?: ApiProductsCacheData;
  componentsList: ContentNavigationType;
}> = ({
  componentsList,
  product,
  selectedPackage,
  pageProps,
  productsAvailabilityState,
  reviews,
  productPackages,
  checkoutPageUrl,
  siteConfig,
  isVariant,
  originalProduct,
  setIsLoading,
  cheapestCacheProduct
}): JSX.Element => {
  const { t } = useTranslation('product');
  const { printMode } = useProductPageContext();
  const searchParams = useSearchParams();
  const [imageGalleryId, setImageGalleryId] = useState<string>('');

  useEffect(() => {
    setImageGalleryId(short.generate());
  }, [searchParams]);

  const { locale = fallbackLng, bvSrc, resolvedUrl } = pageProps || {};
  const { reviewsCode } = originalProduct || {};
  const {
    imageGallery,
    productVideos,
    themeImageGallery,
    introductionTitle,
    newProduct,
    tripDescription,
    map,
    aspectRatio,
    highlights,
    itinerary,
    priceInformation,
    productInformation,
    informationGroup,
    visum,
    vaccination,
    currency,
    extensions,
    singleAccommodations,
    multipleAccommodations,
    factSheet,
    travelTypes,
    excursions,
    themeExtensionTeaser,
    themeHotel,
    themeAccommodation,
    themeContentArea,
    themeExcursions,
    themeItinerary,
    themeTravelInformation,
    themePriceInformation,
    themeIntroduction,
    themeHighlights,
    themeDatesAndPrices
  } = product || {};

  return (
    <>
      {componentsList.map(({ id }) => (
        <div key={id} id={id}>
          {id === 'introduction' ? (
            <Introduction
              cacheProduct={selectedPackage?.products?.[0]}
              newProduct={newProduct}
              theme={themeIntroduction}
              themeHighlights={themeHighlights}
              factSheet={factSheet}
              map={map}
              tripDescription={tripDescription}
              introductionTitle={introductionTitle}
              highlights={highlights}
              departureDatesAnchor={DEPARTURE_DATES_ID}
              aspectRatio={aspectRatio as AspectRatio}
              reviews={reviews}
              themeReviews={product?.themeReviews}
              image={imageGallery?.[0]}
              productsAvailabilityState={productsAvailabilityState}
              pageProps={pageProps}
            />
          ) : id === 'accommodation' && !!singleAccommodations ? (
            <div className="singleHotel">
              <div className={`singleHotel__background ${themeHotel || THEMES.sand}`} />
              <SingleHotel
                accommodations={singleAccommodations}
                theme={themeHotel || THEMES.sand}
                pageProps={pageProps}
              />
            </div>
          ) : id === 'accommodations' ? (
            <AccommodationList
              accommodations={multipleAccommodations}
              theme={themeAccommodation}
              pageProps={pageProps}
            />
          ) : id === 'images' && !!imageGallery ? (
            <ImageGallery
              key={imageGalleryId}
              images={imageGallery}
              theme={themeImageGallery || THEMES.darkGreen}
              headline={t(Keys.gallery)}
              h2Headline
              pageProps={pageProps}
            />
          ) : id === 'itinerary' && !!itinerary ? (
            <Itinerary
              itinerary={itinerary}
              theme={themeItinerary || THEMES.mediumGreen}
              product={product}
              pageProps={pageProps}
              setIsLoading={setIsLoading}
            />
          ) : id === DEPARTURE_DATES_ID ? (
            <ProductsDatesPrices
              productPackages={productPackages}
              selectedPackage={selectedPackage}
              checkoutPageUrl={checkoutPageUrl}
              siteConfig={siteConfig}
              isVariant={isVariant}
              product={product}
              originalProduct={originalProduct}
              setIsLoading={setIsLoading}
              theme={themeDatesAndPrices}
            />
          ) : id === 'price-information' ? (
            <PriceInformation
              priceInformation={priceInformation}
              theme={themePriceInformation || THEMES.sand}
              headline={t(Keys.priceInformationHeadline)}
              product={product}
              selectedProduct={cheapestCacheProduct}
            />
          ) : id === 'contentArea' && !!productInformation ? (
            <ContentModuleDynamic
              theme={themeContentArea || THEMES.blue}
              contentRow={productInformation.contentAreas}
              pageProps={pageProps}
            />
          ) : id === 'excursions' ? (
            <Excursions excursions={excursions} theme={themeExcursions} pageProps={pageProps} />
          ) : id === 'extensionTeaser' ? (
            <ExtensionTeaser
              extensionTeaser={extensions}
              theme={themeExtensionTeaser || THEMES.darkGreen}
              pageProps={pageProps}
            />
          ) : id === 'reviews' && !!reviewsCode && !printMode ? (
            <BVDetailedReviews
              bazaarVoiceId={reviewsCode}
              locale={locale}
              product={product}
              bvSrc={bvSrc}
              resolvedUrl={resolvedUrl}
            />
          ) : id === 'travel-information' ? (
            <TravelInformation
              factSheet={factSheet}
              informationGroup={informationGroup}
              visum={visum}
              vaccination={vaccination}
              currency={currency}
              theme={themeTravelInformation}
              travelType={travelTypes}
            />
          ) : id === 'videos' ? (
            <VideoGallery videos={productVideos as MagnoliaVideos | ApiVideoContent[]} />
          ) : (
            <></>
          )}
        </div>
      ))}
    </>
  );
};

export default ProductPageContent;
