import React, { FC, JSX, useMemo } from 'react';
import { ApiWebsiteDestinationSearch } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Header.json.keys';
import LazyLoad from 'react-lazyload';
import FadeIn from '@/components/SearchForResults/FadeIn';
import sanitize from '@/Util/sanitize';
import { trimAndHighlightText } from '@/components/websiteSearch/TrimAndHighlightText';

const WebsiteSearchFullDestinationList: FC<{
  results: ApiWebsiteDestinationSearch[];
  activeIndex: number;
  query: string;
}> = ({ results, activeIndex, query }): JSX.Element => {
  const { t } = useTranslation('Header');
  const tabIndex = 1;
  const sortedByPriority = useMemo((): ApiWebsiteDestinationSearch[] => {
    return results.sort((a, b) => {
      if (a.priority && b.priority) {
        return b.priority - a.priority;
      } else {
        return 0;
      }
    });
  }, [results]);

  return (
    <div
      style={activeIndex !== tabIndex ? { maxHeight: 0 } : {}}
      className="website-search-full__results__list"
    >
      {sortedByPriority.map(r => {
        return (
          <LazyLoad key={r.title} throttle={300} offset={400} height={420} scroll resize>
            <FadeIn>
              <div key={r.title} className="website-search-full__results__list--row">
                <b>{r.title}</b>
                <a href={r.url}>
                  <span>{t(Keys.learnMore)}</span>
                  <FontAwesomeIcon icon={faCircleArrowRight} />
                </a>
                {r.matchedText && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: sanitize(trimAndHighlightText(r.matchedText, query), true)
                    }}
                  />
                )}
              </div>
            </FadeIn>
          </LazyLoad>
        );
      })}
    </div>
  );
};

export default WebsiteSearchFullDestinationList;
