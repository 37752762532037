import React, { FC, JSX } from 'react';
import { ApiProductsCacheData } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCalendar, facDuration, facPerson } from '@/Theme/SVG/Icons';
import dayjs from 'dayjs';
import { getDefaultFormatPrice } from '@/Util/globals';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import useConfig from '@/Hooks/useConfig';
import { faDatabase, faPlane, faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { AllItems } from '@/components/checkout/cart/getAllItems';
import { useIsComponentMounted } from '@/Hooks/useIsComponentMounted';

const PacificBaseInfos: FC<{
  pacificProduct: ApiProductsCacheData;
  productName?: string;
  className?: string;
  extended?: boolean;
  allItems?: AllItems;
}> = observer(function PacificBaseInfos({
  pacificProduct,
  productName,
  className,
  extended,
  allItems
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const locale = useCurrentLanguage();
  const config = useConfig();
  const { duration, maxOccupancy, travelStartDate, startingPriceAmount } = pacificProduct;
  const { flight, main } = allItems || {};
  const isMounted = useIsComponentMounted();

  return (
    <>
      {!!productName && <div className="cart__headline">{productName}</div>}
      <div className={classNames('checkout__data', className || '')}>
        <div>
          <FontAwesomeIcon icon={facPerson} />
          <span>
            {maxOccupancy === 1
              ? t(Keys.person, { count: maxOccupancy })
              : t(Keys.person_plural, { count: maxOccupancy || 0 })}
          </span>
        </div>
        <div>
          <FontAwesomeIcon icon={facCalendar} />
          <span>
            {duration === 1
              ? t(Keys.day, { count: duration })
              : t(Keys.day_plural, { count: duration || 0 })}
          </span>
        </div>
        <div>
          <FontAwesomeIcon icon={facDuration} />
          <span>
            {!!travelStartDate && isMounted
              ? dayjs(travelStartDate).format(config?.displayFormatDateShort?.[locale || ''] || '')
              : ''}
          </span>
        </div>
        {!extended && (
          <div>
            <FontAwesomeIcon className="checkout__infos__product__database" icon={faDatabase} />
            <span>{getDefaultFormatPrice(startingPriceAmount || 0, locale || '')}</span>
          </div>
        )}
        {extended && !!flight && (
          <div>
            <FontAwesomeIcon icon={faPlane} />
            <span>{flight.segment[0].origin.description}</span>
          </div>
        )}
      </div>
      {extended && !!main?.priceByPersonId && (
        <div className="checkout__infos__product checkout__data__price">
          <FontAwesomeIcon icon={faPlus} />
          <div className="checkout__data__price__alt">
            <div>{t(Keys.pricePerPerson)}</div>
            <div>
              {getDefaultFormatPrice(
                Object.values(main.priceByPersonId)?.[0]?.finalPrice || 0,
                locale
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default PacificBaseInfos;
