import React, { PropsWithChildren, useEffect, JSX } from 'react';
import { useTransition } from 'react-transition-state';

const Offcanvas = ({
  children,
  isOpen,
  toggle,
  className
}: PropsWithChildren<{
  isOpen: boolean;
  toggle: () => void;
  className?: string;
}>): JSX.Element => {
  const [{ status, isMounted }, localToggle] = useTransition({
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true
  });

  useEffect(() => {
    localToggle(isOpen);
  }, [isOpen]);

  return (
    <>
      {isMounted && (
        <>
          <div
            className={`${
              status === 'preEnter' || status === 'exiting' ? ' offcanvas--hidden' : ''
            } ${className || ''}`}
          >
            <div className="offcanvas__body">
              <div className="offcanvas__body__inner">{children}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Offcanvas;
