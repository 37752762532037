import React, { FC, PropsWithChildren, JSX } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

const CheckoutStep: FC<
  PropsWithChildren<{
    index: number;
    activeIndex: number;
    numberOfItems: number;
    baseClass?: string;
    ignore?: boolean;
  }>
> = observer(function CheckoutStep({
  index,
  activeIndex,
  numberOfItems,
  children,
  baseClass,
  ignore
}): JSX.Element {
  const className = baseClass || 'checkout__content__step';
  return (
    <div
      className={classNames(className, {
        [`${className}--active`]: activeIndex === index && !ignore
      })}
      style={{ width: ignore ? 'initial' : `${100 / numberOfItems}%` }}
    >
      {children}
    </div>
  );
});

export default CheckoutStep;
