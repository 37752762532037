import React, { FC, JSX } from 'react';
import { ApiOverlaySeat, ApiOverlaySeatMap } from '@ibe/api';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import classNames from 'classnames';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { getDefaultFormatPrice } from '@/Util/globals';

const getSeatForFee = (feeCode: string, seats: ApiOverlaySeat[]): ApiOverlaySeat | undefined => {
  return seats.find(seat => seat.feeCode === feeCode);
};

const SeatSelectionLegend: FC<{
  seatMap: ApiOverlaySeatMap;
  fees: Record<string, number>;
}> = ({ seatMap, fees }): JSX.Element => {
  const { t } = useTranslation('Checkout');
  const locale = useCurrentLanguage();

  return (
    <div className="seat-selection__legend">
      {Object.entries(fees).map(([code, colorIndex]) => {
        const { reservationFeeAmount, reservationFeeCurrency } =
          getSeatForFee(code, seatMap?.seats || []) || {};
        return (
          <div key={code} className="seat-selection__legend__item">
            <div
              className={classNames(
                'seat-selection__legend__color',
                `seat-selection__map__seat--cat${colorIndex}`
              )}
            />
            <div>{`${code}${
              !!reservationFeeAmount && reservationFeeCurrency
                ? `: ${getDefaultFormatPrice(reservationFeeAmount, locale)}`
                : ''
            }`}</div>
          </div>
        );
      })}
      <div className="seat-selection__legend__item">
        <div
          className={classNames(
            'seat-selection__legend__color',
            'seat-selection__map__seat--unavailable'
          )}
        />
        <div>{t(Keys.unavailable)}</div>
      </div>
      <div className="seat-selection__legend__item">
        <div
          className={classNames(
            'seat-selection__legend__color',
            'seat-selection__map__seat--selected'
          )}
        />
        <div>{t(Keys.selected)}</div>
      </div>
    </div>
  );
};

export default SeatSelectionLegend;
