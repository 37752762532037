import { ConfigModel } from '@ibe/services';
import { Language } from '@/app/i18n/settingsClient';

export type MediaCodeBaseType = '' | 'input' | 'dropdown';

class AlbConfigModel extends ConfigModel {
  context?: string;
  sherpaSrc?: string;
  sherpaId?: string;
  trustpilotSrc?: string;
  trustpilotTemplateId?: string;
  trustpilotReviewUrl?: string;
  cookieInformationSrc?: string;
  cookieInformationGcmVersion?: string;
  cookieInformationId?: string;
  mediaCodes?: Record<string, MediaCodeBaseType | Record<string, MediaCodeBaseType>>;
  googleTagManagerId: Record<`${Language}`, string>;
  hideFeaturedReviews?: boolean;
  activateWebsiteSearch?: boolean;
  hideCheckoutFlightModule?: boolean;
  dkHostName?: string;
  fiHostName?: string;
  noHostName?: string;
  seHostName?: string;
  plHostName?: string;
}

export enum BVSrcReplacements {
  LOCALE = '<<bvLocale>>'
}

export default AlbConfigModel;
