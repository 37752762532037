import React, { FC, JSX, useMemo } from 'react';
import useConfig from '@/Hooks/useConfig';
import { observer } from 'mobx-react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import Select from '@/components/Select/Select';
import classNames from 'classnames';
import { MediaCodeBaseType } from '@/Config/AlbConfigModel';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { Input } from 'reactstrap';

const MediaCodes: FC<{
  checkoutStore: CheckoutStore;
}> = observer(function MediaCodes({ checkoutStore }): JSX.Element {
  const { t } = useTranslation('Checkout');
  const locale = useCurrentLanguage();
  const { mediaCodes } = useConfig();

  const options = useMemo(() => {
    return !!mediaCodes
      ? (Object.entries(mediaCodes).filter(
          ([key, value]) =>
            !key.startsWith('@') && !key.startsWith('dropdown_') && typeof value !== 'object'
        ) as [string, MediaCodeBaseType][])
      : [];
  }, [mediaCodes]);

  const dropdowns = useMemo(() => {
    return options.reduce(
      (total, [key, value]) => {
        return value === 'dropdown' && !!mediaCodes?.[`dropdown_${key}`]
          ? {
              ...total,
              [key]: Object.entries(mediaCodes[`dropdown_${key}`])
                .filter(([, value]) => value.includes(locale))
                .map(([key]) => ({
                  label: t((Keys as Record<string, string>)[key]) || key,
                  value: t((Keys as Record<string, string>)[key]) || key
                }))
            }
          : { ...total };
      },
      {} as Record<string, Array<{ label: string; value: string }>>
    );
  }, [options]);

  return !!mediaCodes && Object.keys(mediaCodes).length > 0 ? (
    <div
      id={checkoutStore.mediaCodeErrorId}
      className={classNames('media-codes', { 'media-codes--error': checkoutStore.mediaCodeError })}
    >
      <div className="media-codes__text">
        <div>{t(Keys.mediaCodeTitle)}</div>
        <div>{t(Keys.mediaCodeSubTitle)}</div>
      </div>
      <div className="media-codes__select-container">
        <div className="media-codes__dropdown">
          <Select
            value={checkoutStore.mediaCode.code}
            onChange={(value: string): void => {
              checkoutStore.mediaCode = {
                code: value,
                translated: t((Keys as Record<string, string>)[value]) || value,
                additionalCode: undefined,
                additionalCodeTranslated: undefined
              };
            }}
            options={options.map(([key]) => ({
              label: t((Keys as Record<string, string>)[key]) || key,
              value: key
            }))}
            placeholder={t(Keys.choosePlatform)}
          />
        </div>
        {!!dropdowns[checkoutStore.mediaCode.code] && (
          <div className="media-codes__additional">
            <Select
              value={checkoutStore.mediaCode.additionalCode || ''}
              onChange={(value: string): void => {
                checkoutStore.mediaCode = {
                  ...checkoutStore.mediaCode,
                  additionalCode: value,
                  additionalCodeTranslated: value
                };
              }}
              options={dropdowns[checkoutStore.mediaCode.code]}
              placeholder={t(
                (Keys as Record<string, string>)[
                  `mediaCodeSelectDropdown${
                    checkoutStore.mediaCode.code.charAt(0).toUpperCase() +
                    checkoutStore.mediaCode.code.slice(1)
                  }`
                ] || t(Keys.mediaCodeSelectDropdownDefault)
              )}
            />
          </div>
        )}
        {!!options?.find(
          ([key, value]) => key === checkoutStore.mediaCode.code && value === 'input'
        ) && (
          <div className="media-codes__additional">
            <Input
              placeholder={t(Keys.mediaCodeEnterMediaName)}
              value={checkoutStore.mediaCode.additionalCodeTranslated}
              onChange={(evt): void => {
                checkoutStore.mediaCode = {
                  ...checkoutStore.mediaCode,
                  additionalCodeTranslated: evt.target.value
                };
              }}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
});

export default MediaCodes;
