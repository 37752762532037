'use client';

import React, { FC, ReactElement, useCallback, JSX } from 'react';
import { ApiProductsCacheResponse, ApiProductWithReviews } from '@ibe/api';
import ProductTeaser, { ExtendedProduct } from '@/components/productTeasers/ProductTeaser';
import TeaserCarousel from '@/components/TeaserCarousel/TeaserCarousel';
import { MagnoliaSite, Props } from '@/types/cms/magnolia';
import { getProductPageBasePath, getVariantProductData } from '@/Util/globals';
import { getNumberOfProductsByDate } from '@/components/SearchForResults/ResultsListItem';

const ProductTeasersCarousel: FC<{
  products: ExtendedProduct[];
  productPackages: ApiProductsCacheResponse[];
  siteConfig?: MagnoliaSite;
  rootNodePath: string;
  reviews?: ApiProductWithReviews;
  pageProps?: Props;
}> = ({ products, siteConfig, rootNodePath, pageProps, productPackages }): JSX.Element => {
  const renderItem = useCallback(
    (product: ExtendedProduct): ReactElement => {
      const { foundVariant, cheapestProduct, filteredProductPackages } = getVariantProductData(
        product,
        productPackages
      );

      return (
        <ProductTeaser
          product={
            foundVariant
              ? { ...foundVariant, displayUrl: product.displayUrl, variants: product.variants }
              : product
          }
          originalProduct={product}
          productPackage={cheapestProduct}
          numberOfPacificProducts={getNumberOfProductsByDate(filteredProductPackages)}
          productPagePath={getProductPageBasePath(rootNodePath, siteConfig)}
          pageProps={pageProps}
        />
      );
    },
    [productPackages]
  );

  return (
    <>{products.length > 0 ? <TeaserCarousel items={products} renderItem={renderItem} /> : <></>}</>
  );
};

export default ProductTeasersCarousel;
