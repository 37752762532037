'use client';

import React, { Fragment, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from '@/Hooks/useReactTransitionProgress';
import classNames from 'classnames';
import { ApiPage } from '../../../api/model';
import { usePathname } from 'next/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { facArrowDown } from '@/Theme/SVG/Icons';
import { useMediaQuery, useWindow } from '@ibe/components';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/country.json.keys';
import useScrollSlide from '@/Hooks/useScrollSlide';
import { Next, Previous } from '@/Theme/SVG/Svgs';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';

interface Props {
  items: ApiPage[];
  className?: string;
  disableButton?: boolean;
}

let timer: ReturnType<typeof setTimeout> | null = null;

const Navigation: React.FC<Props> = ({ items, className, disableButton }) => {
  const { t } = useTranslation('country');
  let pathname = usePathname();
  const window = useWindow();
  const { rootNodePath = '' } = useGlobalMGLProps() || {};
  const isTablet = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xm });
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.lg });

  useEffect(() => {
    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const [outerContainerMeasures, setOuterContainerMeasures] = useState<DOMRect | null>(null);
  const buttonWidth = window?.document?.getElementById('navigation__cta')?.clientWidth;

  const scroll = useCallback(
    (toLeft: boolean, outerContainer: RefObject<HTMLDivElement>): void => {
      if (!outerContainer.current) return;

      function getNextElement(
        threeDown: HTMLElement,
        twoDown: HTMLElement,
        oneDown: HTMLElement
      ): HTMLElement | undefined {
        let nextElement: HTMLElement | undefined = undefined;
        if (isDesktop && !!threeDown) {
          nextElement = threeDown;
        } else if (!!twoDown && (isTablet || (isDesktop && !threeDown))) {
          nextElement = twoDown;
        } else if (
          !!oneDown &&
          ((!isTablet && !isDesktop) || ((isTablet || isDesktop) && !twoDown))
        ) {
          nextElement = oneDown;
        }
        return nextElement;
      }

      const visibleElements = outerContainer.current.querySelectorAll(
        '.navigation__item:not(.navigation__item--outside)'
      );
      const furthestElement = (
        toLeft ? visibleElements[0] : visibleElements[visibleElements.length - 1]
      ) as HTMLElement;

      let elementToScrollTo: HTMLElement | undefined;
      if (toLeft) {
        const threeDown = furthestElement?.previousElementSibling?.previousElementSibling
          ?.previousElementSibling as HTMLElement;
        const twoDown = furthestElement?.previousElementSibling
          ?.previousElementSibling as HTMLElement;
        const oneDown = furthestElement?.previousElementSibling as HTMLElement;
        elementToScrollTo = getNextElement(threeDown, twoDown, oneDown);
      } else {
        const threeDown = furthestElement?.nextElementSibling?.nextElementSibling
          ?.nextElementSibling as HTMLElement;
        const twoDown = furthestElement?.nextElementSibling?.nextElementSibling as HTMLElement;
        const oneDown = furthestElement?.nextElementSibling as HTMLElement;
        elementToScrollTo = getNextElement(threeDown, twoDown, oneDown);
      }

      if (!!elementToScrollTo && !!elementToScrollTo.parentElement) {
        const offsetLeft = toLeft
          ? elementToScrollTo.offsetLeft
          : elementToScrollTo.offsetLeft + elementToScrollTo.offsetWidth;
        const parentOffsetLeft = toLeft
          ? elementToScrollTo.parentElement.offsetLeft
          : elementToScrollTo.parentElement.offsetLeft +
            elementToScrollTo.parentElement.offsetWidth;
        elementToScrollTo.parentElement.scrollTo({
          left:
            offsetLeft - parentOffsetLeft + (!isTablet && !isDesktop ? 20 : 24) * (toLeft ? -1 : 1),
          behavior: 'smooth'
        });
      } else {
        outerContainer.current.scrollTo({
          left: toLeft ? 0 : outerContainer.current.scrollWidth,
          behavior: 'smooth'
        });
      }
    },
    [outerContainerMeasures]
  );

  const { outerContainer, Buttons } = useScrollSlide(
    (outerContainer): void => {
      const setMeasures = (): void => {
        if (!!outerContainer?.current) {
          setOuterContainerMeasures(outerContainer.current.getBoundingClientRect());
        }
      };
      setMeasures();
      timer = setTimeout(setMeasures, 500);
    },
    scroll,
    [
      {
        class: 'navBtn--prev',
        icon: <Previous />
      },
      {
        class: 'navBtn--next',
        style: { right: `${buttonWidth}px` },
        icon: <Next />
      }
    ]
  );

  useEffect(() => {
    if (!outerContainer.current) return;
    const allElements = outerContainer.current.querySelectorAll('.navigation__item');
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            entry.target.classList.add('navigation__item--outside');
          } else {
            entry.target.classList.remove('navigation__item--outside');
          }
        });
      },
      { threshold: 1.0 }
    );
    allElements.forEach(element => {
      observer.observe(element);
    });
  }, [outerContainer, outerContainer.current]);

  return (
    <nav className={`navigation country-page__navigation ${className}`}>
      <div id="page-content-navigation" ref={outerContainer} className="navigation__container">
        {items.filter(Boolean).map((subPage, idx) => {
          return !!subPage.url ? (
            <a
              className={classNames('navigation__item', {
                'navigation__item--active': decodeURI(pathname) === subPage.url
              })}
              key={subPage.id}
              href={subPage.url}
              draggable={false}
              data-text={subPage.title}
            >
              {subPage.title}
            </a>
          ) : (
            <Fragment key={idx} />
          );
        })}
      </div>
      {Buttons}
      {!disableButton && (
        <a href="#" className="navigation__cta" id="navigation__cta">
          <span>
            {t(Keys.download)}&nbsp;
            <FontAwesomeIcon icon={facArrowDown} />
          </span>
        </a>
      )}
    </nav>
  );
};

export default Navigation;
