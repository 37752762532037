import { createContext, PropsWithChildren, useContext, useState, JSX } from 'react';

type IsBookingInitializedContextType = {
  isBookingInitialized: boolean;
  setIsBookingInitialized: (isBookingInitialized: boolean) => void;
};

export const IsBookingInitializedContext = createContext<IsBookingInitializedContextType>({
  isBookingInitialized: false,
  setIsBookingInitialized: () => null
});

export const IsBookingInitializedProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [isBookingInitialized, setIsBookingInitialized] = useState<boolean>(false);

  return (
    <IsBookingInitializedContext.Provider value={{ isBookingInitialized, setIsBookingInitialized }}>
      {children}
    </IsBookingInitializedContext.Provider>
  );
};

export const useIsBookingInitialized = (): IsBookingInitializedContextType => {
  return useContext<IsBookingInitializedContextType>(IsBookingInitializedContext);
};
