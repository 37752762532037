import React, { Suspense, useCallback, useEffect, useState, JSX } from 'react';
import { Input, Spinner } from 'reactstrap';
import Button from '@/components/Button';
import Keys from '@/Translations/generated/da/footer.json.keys';
import { useTranslation } from '@/app/i18n/client';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { logger, NEWSLETTER_SOURCE } from '@/Util/globals';
import classNames from 'classnames';
import { useApi } from '@/Hooks/useApi';
import { ApiSignupRequestFromJSON } from '@ibe/api';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

const Newsletter = (): JSX.Element => {
  const { t } = useTranslation('footer');
  const { isAuthor } = useGlobalMGLProps() || {};
  const api = useApi(isAuthor);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');
  const [sendStatus, setSendStatus] = useState<'' | 'generalError' | 'duplicateError' | 'success'>(
    ''
  );

  useEffect(() => {
    if (!!inputValue && !!validationError) {
      setValidationError('');
    }
  }, [inputValue]);

  const handleButtonClick = useCallback(async (): Promise<void> => {
    if (!inputValue) return;
    try {
      setIsLoading(true);
      setValidationError('');
      await yup
        .object({
          email: yup.string().email(t(Keys.validationEmail))
        })
        .validate({ email: inputValue }, { abortEarly: false });
      const res = await api.newsletterSignup(
        ApiSignupRequestFromJSON({
          emails: [inputValue],
          source: NEWSLETTER_SOURCE.footer
        })
      );
      if (res === 'Email is already subscribed to the newsletter') {
        setSendStatus('duplicateError');
      } else {
        setSendStatus('success');
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        const error = e.inner[0];
        if (error.path) {
          setValidationError(error.message);
        }
      } else {
        setSendStatus('generalError');
        logger('error')('Unable to send newsletter subscription', e);
      }
    } finally {
      setIsLoading(false);
    }
  }, [inputValue]);

  return (
    <Suspense>
      <div className="newsletter">
        {!!validationError && <div className="newsletter__error">{validationError}</div>}
        {!!sendStatus && (
          <div
            className={classNames({
              newsletter__error: sendStatus === 'generalError' || sendStatus === 'duplicateError',
              newsletter__success: sendStatus === 'success'
            })}
          >
            {sendStatus === 'generalError'
              ? t(Keys.generalError)
              : sendStatus === 'duplicateError'
                ? t(Keys.duplicateError)
                : t(Keys.success)}
          </div>
        )}
        <div className="label d-xs-flex">
          <Input
            className="footer__input"
            placeholder={t(Keys.yourEmail)}
            value={inputValue}
            onChange={(e): void => setInputValue(e.target.value)}
          />
          <Button
            showCaret={!isLoading}
            className="footer__button"
            disabled={!inputValue}
            onClick={handleButtonClick}
          >
            {isLoading && <Spinner />}
          </Button>
        </div>
      </div>
    </Suspense>
  );
};

export default Newsletter;
