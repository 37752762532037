import { MagnoliaComponentNode, MagnoliaDamNode } from '@/types/cms/magnolia';
import { LinkPropsType } from '@/components/linkList/Link';

export interface ImageType extends MagnoliaComponentNode {
  imageLink: MagnoliaDamNode;
  imageAltText?: string;
}

export interface HeaderProps {
  navLinks: {
    linkProps: LinkPropsType;
    label: string;
  }[];
  sidebarNavLinks: {
    linkProps: LinkPropsType;
    label: string;
  }[];
  sidebarNavLinksLeftColumn: {
    linkProps: LinkPropsType;
    label: string;
  }[];
  sidebarNavLinksRightColumn: {
    linkProps: LinkPropsType;
    label: string;
  }[];
  imageDark: MagnoliaDamNode;
  imageBright: MagnoliaDamNode;
  open: string;
  tel: string;
  mail: string;
}

export type ScrollMode = 'DOWN' | 'UP' | 'TOP';

export const cssHelper = (input: string | boolean) => {
  if (input === false) {
    return '';
  }
  return input;
};
