'use client';

import React, { FC, useEffect, useState, JSX } from 'react';
import Keys from '@/Translations/generated/da/productTeaser.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { getResultsPageBasePath, logger } from '@/Util/globals';
import Link from '@/components/Link';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { useModelApiClient } from '@/Util/api';

const TeasersSearchPageButton: FC = (): JSX.Element => {
  const { rootNodePath, siteConfig, isAuthor } = useGlobalMGLProps() || {};
  const modelApiClient = useModelApiClient(isAuthor);
  const locale = useCurrentLanguage();
  const [productsByMarketCount, setProductsByMarketCount] = useState<number>(0);

  const { t } = useTranslation('productTeaser');

  useEffect(() => {
    (async () => {
      try {
        const newCount = await modelApiClient.getProductsByMarketCount(
          siteConfig?.name || '',
          siteConfig?.name || '',
          locale
        );
        if (!!newCount) {
          setProductsByMarketCount(newCount);
        }
      } catch {
        logger('error')('Unable to fetch products count');
      }
    })();
  }, [siteConfig, locale]);

  return (
    <Link
      color="primary"
      className="btn btn-primary"
      showCaret
      href={`/${getResultsPageBasePath(rootNodePath || '', siteConfig)}`}
    >
      {t(Keys.seeAllTravels, { count: productsByMarketCount })}
    </Link>
  );
};

export default TeasersSearchPageButton;
