'use client';

import { Link } from '@/Hooks/useReactTransitionProgress';
import sanitize from '@/Util/sanitize';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { getNextLinkAttrs } from '../linkList/Link';
import { MagnoliaDamNode, LinkSwitchableMixin, Props } from '@/types/cms/magnolia';
import Image from '@/components/image/Image';
import { mapToApiImage } from '@/Util/globals';

interface TeaserProps {
  showCTA: boolean;
  ctaLabel: string;
  description: string;
  headline: string;
  image: MagnoliaDamNode;
  openNewBrowserWindow?: boolean;
  pageProps?: Props;
}

const Teaser = (props: TeaserProps & LinkSwitchableMixin) => {
  const { magnoliaContext } = useGlobalMGLProps() || {};
  const { isMagnoliaEdit, isMagnolia } = magnoliaContext || {};
  const linkAttrs = getNextLinkAttrs(props);

  if (isMagnolia || isMagnoliaEdit) return <TeaserCard {...props} />;
  return (
    <a className="teaser-link" {...linkAttrs}>
      <TeaserCard {...props} />
    </a>
  );
};

const TeaserCard = (props: TeaserProps) => {
  const { ctaLabel, description, headline, showCTA, image, pageProps } = props;
  return (
    <Card className={`w-100 iso__teaser`}>
      {image && (
        <Image
          image={mapToApiImage(image) || {}}
          className="card-img-top"
          width={100}
          height={100}
          serverSideContext={pageProps?.albConfig.context}
          pageProps={pageProps}
        />
      )}
      <CardBody>
        <CardTitle tag={'h5'}>{headline}</CardTitle>
        <div
          className="card-text teaser-description"
          dangerouslySetInnerHTML={{ __html: sanitize(description, true) }}
        />
        {showCTA && (
          <div className="text-center mt-auto">
            <Button className="teaserButton w-100">{ctaLabel}</Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Teaser;
