import React, { FC, useMemo, useState, JSX } from 'react';
import { ApiOverlaySeat } from '@ibe/api';
import classNames from 'classnames';
import { Tooltip } from 'reactstrap';
import isClient, { getDefaultFormatPrice } from '@/Util/globals';
import useDelayRender from '@/Hooks/useDelayRender';

const getSeatPosition = (
  seat: ApiOverlaySeat,
  height: number = 0,
  width: number = 0
): { left: string; top: string; height: string; width: string } => {
  const { xMin = 0, xMax = 0, yMin = 0, yMax = 0 } = seat;

  return {
    left: `${(xMin / width) * 100}%`,
    top: `${(yMin / height) * 100}%`,
    height: `${((yMax - yMin) / height) * 100}%`,
    width: `${((xMax - xMin) / width) * 100}%`
  };
};

const SeatSelectionSeat: FC<{
  seat: ApiOverlaySeat;
  height?: number;
  width?: number;
  fees: Record<string, number>;
  selected: boolean;
  locale: string;
  onClick: (seat: ApiOverlaySeat) => void;
  disableOnClick: boolean;
}> = ({ seat, height, width, fees, selected, locale, onClick, disableOnClick }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const renderNow = useDelayRender();
  const { available, seatNumber, feeCode, reservationFeeAmount, reservationFeeCurrency } = seat;

  const {
    left,
    top,
    height: seatHeight,
    width: seatWidth
  } = useMemo(() => {
    return getSeatPosition(seat, height, width);
  }, [seat, height, width]);

  const toggle = (): void => {
    setIsOpen(value => !value);
  };

  return (
    <>
      <div
        id={`seat-selection__map__seat-${seatNumber}`}
        className={classNames('seat-selection__map__seat', {
          'seat-selection__map__seat--unavailable': !available || !feeCode,
          [`seat-selection__map__seat--cat${fees[feeCode || 'default']}`]: available && !!feeCode,
          'seat-selection__map__seat--selected': selected,
          'seat-selection__map__seat--disabled': disableOnClick
        })}
        style={{ left, top, height: seatHeight, width: seatWidth }}
        onClick={
          !selected && available && !!feeCode && !disableOnClick
            ? (): void => onClick(seat)
            : undefined
        }
      >
        <div>{seatNumber}</div>
      </div>
      {!!seatNumber && !!feeCode && available && isClient() && renderNow && (
        <Tooltip
          target={`seat-selection__map__seat-${seat.seatNumber}`}
          isOpen={isOpen}
          toggle={toggle}
          autohide={true}
          flip={true}
        >{`${seatNumber} - ${feeCode}${
          !!reservationFeeAmount && !!reservationFeeCurrency
            ? `: ${getDefaultFormatPrice(reservationFeeAmount, locale)}`
            : ''
        }`}</Tooltip>
      )}
    </>
  );
};

export default SeatSelectionSeat;
