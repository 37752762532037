'use client';

import React, { FC, JSX, useEffect } from 'react';
import { SHERPA_CONTAINER_ID, SHERPA_CUSTOM_EVENT } from '@/Util/globals';
import Script from 'next/script';
import GridContainer from '@/Layouts/GridContainer';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import useConfig from '@/Hooks/useConfig';
import { languageMapping } from '@/app/i18n/settingsClient';

const renderSherpa = (language: string, sherpaCode: string): void => {
  window.$sherpa.V2.createElement('trip', {
    placement: 'discovery',
    cookieConsentPreferences: ['functionality_storage', 'security_storage'],
    language: languageMapping[language].language,
    travellers: [
      {
        nationality: languageMapping[language].nationality,
        vaccinations: [
          {
            type: 'COVID_19',
            status: 'FULLY_VACCINATED'
          }
        ]
      }
    ],
    segments: [
      {
        segmentType: 'OUTBOUND',
        origin: {
          countryCode: languageMapping[language].nationality
        },
        destination: {
          countryCode: sherpaCode
        }
      }
    ]
  }).mount(`#${SHERPA_CONTAINER_ID}`);
};

const SherpaInfos: FC<{ sherpaCode: string }> = ({ sherpaCode }): JSX.Element => {
  const language = useCurrentLanguage();
  const { sherpaSrc, sherpaId } = useConfig();

  useEffect(() => {
    if (!!window?.$sherpa?.V2) {
      renderSherpa(language, sherpaCode);
    }

    document.addEventListener(SHERPA_CUSTOM_EVENT, (() => {
      renderSherpa(language, sherpaCode);
    }) as EventListener);
  }, []);

  return (
    <GridContainer>
      <div className="sherpa-infos">
        <div id={SHERPA_CONTAINER_ID} />
        <Script id="sherpa-inline-script" async defer strategy="lazyOnload">
          {`
          function onSherpaEvent(event) {
            if (event.type === 'sdkLoaded') {
              const sherpaCustomEvent = new CustomEvent('${SHERPA_CUSTOM_EVENT}', {
                detail: {
                  sdkLoaded: true
                }
              });
              setTimeout(() => {
                document.dispatchEvent(sherpaCustomEvent);
              }, 0);
            }
          }
        `}
        </Script>
        <Script src={`${sherpaSrc}${sherpaId}`} strategy="lazyOnload" defer async />
      </div>
    </GridContainer>
  );
};

export default SherpaInfos;
