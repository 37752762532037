import React, { Dispatch, FC, JSX, SetStateAction, useMemo } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import PacificBaseInfos from '@/components/checkout/cart/PacificBaseInfos';
import Accommodation from '@/components/checkout/cart/Accommodation';
import { observer } from 'mobx-react';
import Upgrades from '@/components/checkout/cart/Upgrades';
import AdditionalPurchases from '@/components/checkout/cart/AdditionalPurchases';
import { AllItems, getAllItems } from '@/components/checkout/cart/getAllItems';
import OverviewAndTotalPrice from '@/components/checkout/cart/OverviewAndTotalPrice';
import Insurances from '@/components/checkout/cart/Insurances';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { Language } from '@/app/i18n/settingsClient';

const Cart: FC<{
  checkoutStore: CheckoutStore;
  setIgnoreBusSeatValidation: Dispatch<SetStateAction<boolean>>;
}> = observer(function Cart({ checkoutStore, setIgnoreBusSeatValidation }): JSX.Element {
  const { t } = useTranslation('Checkout');
  const locale = useCurrentLanguage();

  const allItems = useMemo((): AllItems => {
    return getAllItems(checkoutStore);
  }, [checkoutStore, checkoutStore.booking]);

  return (
    <div className="cart">
      <div className="cart__title">{t(Keys.yourTravel)}</div>
      {!!checkoutStore.selectedPacificProduct && (
        <PacificBaseInfos
          pacificProduct={checkoutStore.selectedPacificProduct}
          productName={checkoutStore.product?.productTitle || ''}
          allItems={allItems}
          extended
        />
      )}
      <Accommodation allItems={allItems} checkoutStore={checkoutStore} />
      <Upgrades allItems={allItems} />
      <AdditionalPurchases allItems={allItems} />
      {/* TODO: no insurances for FI until further notice */}
      {locale !== Language.FI && <Insurances allItems={allItems} />}
      <OverviewAndTotalPrice
        checkoutStore={checkoutStore}
        allItems={allItems}
        setIgnoreBusSeatValidation={setIgnoreBusSeatValidation}
      />
    </div>
  );
});

export default Cart;
