import React, { Dispatch, JSX, RefObject, SetStateAction, useRef, useState } from 'react';
import { ApiProduct } from '../../../api/model';
import { useTranslation } from '@/app/i18n/client';
import { Link } from '@/Hooks/useReactTransitionProgress';
import { ExclamationMarkCircle } from '@/Theme/SVG/Svgs';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import Keys from '@/Translations/generated/da/product.json.keys';
import { ApiProductsCacheResponse } from '@ibe/api';
import isClient from '@/Util/globals';
import useDelayRender from '@/Hooks/useDelayRender';

const Variant = ({
  product,
  productPackage,
  isDesktop,
  containerRef,
  variantUrl,
  setLoading
}: {
  isVariant?: boolean;
  product?: ApiProduct;
  productPackage: ApiProductsCacheResponse;
  isDesktop: boolean;
  containerRef: RefObject<HTMLDivElement>;
  variantUrl: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const { t } = useTranslation('product');
  const targetRef = useRef<HTMLAnchorElement>(null) as RefObject<HTMLAnchorElement>;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const renderNow = useDelayRender();

  const isVariantActive = (): boolean => {
    return product?.name !== productPackage.packageCode;
  };

  return isVariantActive() ? (
    <>
      <a
        ref={targetRef}
        className="dates-prices__link"
        href={variantUrl}
        onMouseEnter={(): void => setIsOpen(true)}
        onMouseLeave={(): void => setIsOpen(false)}
        onClick={(): void => setLoading(true)}
      >
        <ExclamationMarkCircle />
      </a>
      {isClient() && renderNow && (
        <Popover
          isOpen={isOpen}
          target={targetRef}
          container={containerRef}
          toggle={(): void => setIsOpen(value => !value)}
          placement={isDesktop ? 'bottom' : 'right'}
          trigger="legacy"
        >
          <div className="dates-prices__variant-popover">
            <PopoverHeader>
              <div className="dates-prices__variant-popover__head">{t(Keys.note2)}</div>
            </PopoverHeader>
            <PopoverBody>
              <div>{isDesktop ? t(Keys.clickTableRow) : t(Keys.clickInfoIcon)}</div>
            </PopoverBody>
          </div>
        </Popover>
      )}
    </>
  ) : (
    <></>
  );
};

export default Variant;
