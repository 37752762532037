import React, { FC, MouseEvent, ReactElement, useCallback, useState } from 'react';
import {
  facBus,
  facCaretDownThin,
  facCaretRightThin,
  facClockDuration,
  facCoinStack,
  facInfo,
  facLocationMarker,
  facPerson,
  facPlane,
  facTrain
} from '@/Theme/SVG/Icons';
import Keys from '@/Translations/generated/da/product.json.keys';
import { Tooltip } from 'reactstrap';
import sanitize from '@/Util/sanitize';
import isClient, {
  MEDIAQUERY_DEFAULTS,
  PRODUCT_AVAILABILITY_STATE,
  smoothScroll
} from '@/Util/globals';
import { useMediaQuery } from '@ibe/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@/app/i18n/client';
import useDelayRender from '@/Hooks/useDelayRender';

interface FactSheetProps {
  duration: string;
  isNewTrip?: boolean;
  price?: string;
  startingLocation: string;
  transportation: string;
  language: string;
  tooltipText: string;
  date: { label: string; anchorLink: string };
  theme: string;
  productsAvailabilityState: PRODUCT_AVAILABILITY_STATE;
}

const FactSheet: FC<FactSheetProps> = ({
  duration,
  isNewTrip,
  price,
  startingLocation,
  transportation,
  language,
  tooltipText,
  date,
  theme,
  productsAvailabilityState
}): ReactElement => {
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.md });
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const renderNow = useDelayRender(400);
  const { t } = useTranslation('product');

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const getTransportation = useCallback(
    (transportation: string): ReactElement => {
      switch (transportation) {
        case 'plane':
          return (
            <div className="bullet-point">
              <span className="icon">{renderNow && <FontAwesomeIcon icon={facPlane} />}</span>
              <span>{t(Keys.plane)}</span>
            </div>
          );
        case 'bus':
          return (
            <div className="bullet-point">
              <span className="icon">{renderNow && <FontAwesomeIcon icon={facBus} />}</span>
              <span>{t(Keys.bus)}</span>
            </div>
          );
        case 'train':
          return (
            <div className="bullet-point">
              <span className="icon">{renderNow && <FontAwesomeIcon icon={facTrain} />}</span>
              <span>{t(Keys.train)}</span>
            </div>
          );
        default:
          return <></>;
      }
    },
    [renderNow]
  );

  return (
    <div className={isDesktop && renderNow ? 'fact-sheet contrast' : 'fact-sheet'}>
      <div className="date-items non-contrast">
        <div className="date-item">
          <span>
            {productsAvailabilityState === PRODUCT_AVAILABILITY_STATE.soldOutNoFutureAvail ||
            productsAvailabilityState === PRODUCT_AVAILABILITY_STATE.soldOutWithFutureAvail
              ? t(Keys.soldOut)
              : productsAvailabilityState === PRODUCT_AVAILABILITY_STATE.pastTripNoFutureAvail ||
                  productsAvailabilityState === PRODUCT_AVAILABILITY_STATE.pastTripWithFutureAvail
                ? t(Keys.departed)
                : date.label}
          </span>
        </div>
        <div className="date-item">
          {productsAvailabilityState === PRODUCT_AVAILABILITY_STATE.soldOutNoFutureAvail ||
          productsAvailabilityState === PRODUCT_AVAILABILITY_STATE.pastTripNoFutureAvail ? (
            t(Keys.noAvailableDates)
          ) : (
            <>
              <a
                href="#"
                className="link"
                onClick={(e: MouseEvent<HTMLAnchorElement>): void =>
                  smoothScroll(date.anchorLink, e)
                }
              >
                {t(Keys.seeAvailableDates)}
              </a>
              {isDesktop ? (
                <span className="icon">
                  {renderNow && <FontAwesomeIcon icon={facCaretRightThin} />}
                </span>
              ) : (
                <span className="icon">
                  {renderNow && <FontAwesomeIcon icon={facCaretDownThin} />}
                </span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="bullet-points">
        {isNewTrip &&
          productsAvailabilityState !== PRODUCT_AVAILABILITY_STATE.pastTripNoFutureAvail && (
            <div className="bullet-point">
              <span className="badge">{t(Keys.newJourney)}</span>
            </div>
          )}
        {productsAvailabilityState !== PRODUCT_AVAILABILITY_STATE.pastTripNoFutureAvail &&
          productsAvailabilityState !== PRODUCT_AVAILABILITY_STATE.pastTripWithFutureAvail && (
            <div className="bullet-point">
              <span className="icon">
                {renderNow && <FontAwesomeIcon icon={facClockDuration} />}
              </span>
              <span>{duration}</span>
            </div>
          )}
        {!!price &&
          productsAvailabilityState !== PRODUCT_AVAILABILITY_STATE.soldOutNoFutureAvail &&
          productsAvailabilityState !== PRODUCT_AVAILABILITY_STATE.pastTripNoFutureAvail && (
            <div className="bullet-point">
              <span className="icon">{renderNow && <FontAwesomeIcon icon={facCoinStack} />}</span>
              <span>{price}</span>
              {!!tooltipText && isClient() && renderNow && (
                <>
                  <span id="price-tooltip">
                    <FontAwesomeIcon icon={facInfo} />
                  </span>
                  <Tooltip
                    className="custom-tooltip"
                    innerClassName={'tooltip-inner ' + theme}
                    arrowClassName="tooltip-arrow"
                    target="price-tooltip"
                    placement={isDesktop ? 'right' : 'bottom'}
                    isOpen={tooltipOpen}
                    toggle={toggle}
                    autohide={false}
                    trigger="click hover focus"
                  >
                    <div dangerouslySetInnerHTML={{ __html: sanitize(tooltipText, true) }}></div>
                  </Tooltip>
                </>
              )}
            </div>
          )}
        {startingLocation &&
          productsAvailabilityState !== PRODUCT_AVAILABILITY_STATE.pastTripNoFutureAvail && (
            <div className="bullet-point">
              <span className="icon">
                {renderNow && <FontAwesomeIcon icon={facLocationMarker} />}
              </span>
              <span>{startingLocation}</span>
            </div>
          )}
        {getTransportation(transportation)}
        {language && (
          <div className="bullet-point">
            <span className="icon">{renderNow && <FontAwesomeIcon icon={facPerson} />}</span>
            <span>{language}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FactSheet;
