import React, { FC, JSX, useEffect, useState } from 'react';
import Keys from '@/Translations/generated/da/search.json.keys';
import classNames from 'classnames';
import { useTranslation } from '@/app/i18n/client';
import { SearchProps } from '@/components/Search/SearchDesktopOverlay';
import CheckboxResults from '@/components/Search/CheckboxResults';
import {
  Action,
  ApiContinentComponent,
  ApiCountryComponent
} from '@/components/Search/searchReducer/types';
import { Spinner, useHorizontalDrag } from '@ibe/components';
import Button from '@/components/Button';

const CountriesSearchTab: FC<
  Pick<SearchProps, 'checkboxState' | 'checkboxDispatch' | 'isLoading'> & {
    fullSearchView?: boolean;
    closeCollapse?: () => void;
    calculatedProductsTotalResults?: number;
  }
> = ({
  checkboxState,
  checkboxDispatch,
  fullSearchView,
  closeCollapse,
  isLoading,
  calculatedProductsTotalResults
}): JSX.Element => {
  const { t } = useTranslation('search');
  const [selectedContinent, setSelectedContinent] = useState<ApiContinentComponent | undefined>(
    undefined
  );
  const { continents, totalProductsOverall } = checkboxState;

  const { isDown } = useHorizontalDrag('search-content-continent-navigation', 250);

  useEffect(() => {
    if (continents.length > 0) {
      setSelectedContinent(continents[0]);
    }
  }, []);

  useEffect(() => {
    if (
      !!selectedContinent &&
      !continents.find(continent => continent.id === selectedContinent.id)
    ) {
      setSelectedContinent(continents[0]);
    }
  }, [continents, selectedContinent]);

  const checkSingleCountry = async (country: ApiCountryComponent): Promise<void> => {
    checkboxDispatch({
      type: Action.ADD_OR_REMOVE_SINGLE,
      payload: {
        id: country?.continent || '',
        type: 'continent',
        name: selectedContinent?.name || '',
        items: [{ id: country?.id || '', name: country.name }]
      }
    });
  };

  return (
    <>
      <div
        id="search-content-continent-navigation"
        className={classNames(
          'search__content__continents',
          {
            'search__content__continents--grab': isDown
          },
          { 'search__content__continents--full-search': fullSearchView }
        )}
      >
        {continents.map(continent => (
          <div
            key={continent.id}
            className={classNames('search__content__continent', {
              'search__content__continent--active': continent.id === selectedContinent?.id
            })}
            onClick={(): void => setSelectedContinent(continent)}
            draggable={false}
          >
            {continent.name}
          </div>
        ))}
      </div>
      {fullSearchView && <hr className="search__content__hr" />}
      <div
        className={classNames('search__content search__content--checkboxes', {
          'search__content--checkboxes-expanded': fullSearchView
        })}
      >
        {selectedContinent?.countries?.map(country => (
          <CheckboxResults
            key={country.id}
            id={country.id}
            label={country.name}
            checked={
              !!country?.id &&
              !!country?.continent &&
              !!checkboxState.items[country.continent]?.countries?.[country.id]
            }
            onChange={(): Promise<void> => checkSingleCountry(country)}
            results={country.products || 0}
            isLoading={isLoading}
          />
        ))}
      </div>
      {fullSearchView && (
        <div className="search__content__submit">
          <Button color="primary" onClick={closeCollapse} disabled={isLoading}>
            <span>{`${t(Keys.apply)} (${
              calculatedProductsTotalResults !== undefined
                ? calculatedProductsTotalResults
                : totalProductsOverall
            })`}</span>
            {isLoading && <Spinner />}
          </Button>
        </div>
      )}
    </>
  );
};

export default CountriesSearchTab;
