import React, { Dispatch, FC, RefObject, SetStateAction, JSX } from 'react';
import SearchDesktopOverlay, {
  CollapseIndex,
  SearchProps
} from '@/components/Search/SearchDesktopOverlay';
import Tabs from '@/components/Search/Tabs';
import MobileOverlay from '@/components/MobileOverlay/MobileOverlay';
import SearchMobileOverlay from '@/components/Search/SearchMobileOverlay';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/search.json.keys';
import { TFunction } from 'i18next';

const getMobileHeader = (activeIndex: number, t: TFunction): string => {
  switch (activeIndex) {
    case 1:
      return t(Keys.destinations);
    case 2:
      return t(Keys.travelTypes);
    case 3:
      return t(Keys.departureTime);
    case 4:
      return t(Keys.search);
    default:
      return '';
  }
};

const SearchMarkup: FC<
  SearchProps & {
    textSearchInputRef: RefObject<HTMLInputElement>;
    setCollapseOpen: Dispatch<SetStateAction<CollapseIndex>>;
    hasSelectedItems: boolean;
    isDesktop: boolean;
    closeCollapse: () => void;
  }
> = ({
  activeIndex,
  collapseOpen,
  setCollapseOpen,
  inputValue,
  handleInputChange,
  totalNumberOfProducts,
  checkboxDispatch,
  checkboxState,
  textSearch,
  textSearchInputRef,
  goToResultsPage,
  isLoading,
  productPageBasePath,
  hasSelectedItems,
  isDesktop,
  closeCollapse,
  isInitialSearch,
  resultsPageBasePath
}): JSX.Element => {
  const { t } = useTranslation('search');

  return (
    <>
      <div className="search__container">
        <div className="search">
          {isDesktop && (
            <SearchDesktopOverlay
              collapseOpen={collapseOpen}
              activeIndex={activeIndex}
              inputValue={inputValue}
              handleInputChange={handleInputChange}
              totalNumberOfProducts={totalNumberOfProducts}
              textSearch={textSearch}
              checkboxDispatch={checkboxDispatch}
              checkboxState={checkboxState}
              textSearchInputRef={textSearchInputRef}
              goToResultsPage={goToResultsPage}
              isLoading={isLoading}
              productPageBasePath={productPageBasePath}
              resultsPageBasePath={resultsPageBasePath}
            />
          )}
          <Tabs
            textSearchInputRef={textSearchInputRef}
            collapseOpen={activeIndex}
            setCollapseOpen={setCollapseOpen}
            checkboxState={checkboxState}
            goToResultsPage={goToResultsPage}
            hasSelectedItems={hasSelectedItems}
            isLoading={isLoading}
          />
        </div>
      </div>
      {!isDesktop && (
        <MobileOverlay
          overlayOpen={collapseOpen}
          setOverlayOpen={closeCollapse}
          className="mobile-overlay--search"
          headerContent={<h2>{getMobileHeader(activeIndex, t)}</h2>}
          content={
            <SearchMobileOverlay
              activeIndex={activeIndex}
              inputValue={inputValue}
              handleInputChange={handleInputChange}
              totalNumberOfProducts={totalNumberOfProducts}
              textSearch={textSearch}
              checkboxDispatch={checkboxDispatch}
              checkboxState={checkboxState}
              textSearchInputRef={textSearchInputRef}
              goToResultsPage={goToResultsPage}
              isLoading={isLoading}
              productPageBasePath={productPageBasePath}
              hasSelectedItems={hasSelectedItems}
              isInitialSearch={isInitialSearch}
              collapseOpen={collapseOpen}
              resultsPageBasePath={resultsPageBasePath}
            />
          }
        />
      )}
    </>
  );
};

export default SearchMarkup;
