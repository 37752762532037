/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-03-18 11:04:35 */

const Keys = {
  person: 'person',
  person_plural: 'person_plural',
  day: 'day',
  day_plural: 'day_plural',
  singleDay: 'singleDay',
  selectedProduct: 'selectedProduct',
  extras: 'extras',
  participantsAndPayment: 'participantsAndPayment',
  confirmation: 'confirmation',
  step: 'step',
  back: 'back',
  forward: 'forward',
  viewProduct: 'viewProduct',
  book: 'book',
  flight: 'flight',
  flightInfo: 'flightInfo',
  flightInfoNoFlights: 'flightInfoNoFlights',
  flightInfoConnectingFlights: 'flightInfoConnectingFlights',
  outboundFlight: 'outboundFlight',
  inboundFlight: 'inboundFlight',
  stop: 'stop',
  stop_plural: 'stop_plural',
  participantsAndDepartures: 'participantsAndDepartures',
  participantsAndDeparturesInfo: 'participantsAndDeparturesInfo',
  numberOfTravelers: 'numberOfTravelers',
  departure: 'departure',
  departureAirport: 'departureAirport',
  traveler: 'traveler',
  traveler_plural: 'traveler_plural',
  traveler_single: 'traveler_single',
  accommodation: 'accommodation',
  accommodationSubText: 'accommodationSubText',
  readMore: 'readMore',
  included: 'included',
  includedAlt: 'includedAlt',
  includedShort: 'includedShort',
  surcharge: 'surcharge',
  pricePerPerson: 'pricePerPerson',
  price: 'price',
  totalPrice: 'totalPrice',
  select: 'select',
  from: 'from',
  upgrade: 'upgrade',
  transfer: 'transfer',
  transferSubText: 'transferSubText',
  bookingAttemptFailed: 'bookingAttemptFailed',
  bookingOrCartError: 'bookingOrCartError',
  noCartError: 'noCartError',
  tAndC1: 'tAndC1',
  tAndC2: 'tAndC2',
  tAndC3: 'tAndC3',
  tandC4: 'tandC4',
  tAndC5: 'tAndC5',
  tAndC6: 'tAndC6',
  tAndC7: 'tAndC7',
  SingleExcursions: 'SingleExcursions',
  excursionPackages: 'excursionPackages',
  excursionPackagesInfo: 'excursionPackagesInfo',
  SingleExcursionsInfo: 'SingleExcursionsInfo',
  add: 'add',
  remove: 'remove',
  opera: 'opera',
  operaInfo: 'operaInfo',
  meal: 'meal',
  mealShort: 'mealShort',
  mealInfo: 'mealInfo',
  visa: 'visa',
  visaInfo: 'visaInfo',
  seatsHeadline: 'seatsHeadline',
  travelParticipant: 'travelParticipant',
  travelApplicant: 'travelApplicant',
  email: 'email',
  emailError: 'emailError',
  confirmEmail: 'confirmEmail',
  confirmEmailError: 'confirmEmailError',
  title: 'title',
  titleError: 'titleError',
  titlePlaceholder: 'titlePlaceholder',
  firstName: 'firstName',
  firstNameError: 'firstNameError',
  lastName: 'lastName',
  lastNameError: 'lastNameError',
  birthDate: 'birthDate',
  birthDateError: 'birthDateError',
  birthDatePlaceholder: 'birthDatePlaceholder',
  street: 'street',
  streetError: 'streetError',
  zipCode: 'zipCode',
  zipCodeError: 'zipCodeError',
  zipCodeFormatError: 'zipCodeFormatError',
  city: 'city',
  cityError: 'cityError',
  cityPlaceholder: 'cityPlaceholder',
  country: 'country',
  countryError: 'countryError',
  countryPlaceholder: 'countryPlaceholder',
  phone: 'phone',
  phoneError: 'phoneError',
  phoneNumberError: 'phoneNumberError',
  countryCode: 'countryCode',
  countryCodeError: 'countryCodeError',
  mustBeEqualOrOlderError: 'mustBeEqualOrOlderError',
  mustBeEqualOrYoungerError: 'mustBeEqualOrYoungerError',
  mustBeYoungerError: 'mustBeYoungerError',
  notes: 'notes',
  notesSubLabel: 'notesSubLabel',
  copyData: 'copyData',
  yourTravel: 'yourTravel',
  operaHeadline: 'operaHeadline',
  seatIncluded: 'seatIncluded',
  upgrades: 'upgrades',
  bus: 'bus',
  busSubText: 'busSubText',
  busSeatHeadline: 'busSeatHeadline',
  purchases: 'purchases',
  travelExtensions: 'travelExtensions',
  excursion: 'excursion',
  excursionPackage: 'excursionPackage',
  insurances: 'insurances',
  travelerAlt: 'travelerAlt',
  travel: 'travel',
  paymentOptions: 'paymentOptions',
  paymentText: 'paymentText',
  selectPayment: 'selectPayment',
  deposit: 'deposit',
  fullPayment: 'fullPayment',
  onlinePayment: 'onlinePayment',
  onlinePaymentText: 'onlinePaymentText',
  invoice: 'invoice',
  invoiceText: 'invoiceText',
  payAndBook: 'payAndBook',
  bookingConfirmed: 'bookingConfirmed',
  thankYouNote: 'thankYouNote',
  bookingNumber: 'bookingNumber',
  onRequestBooking: 'onRequestBooking',
  onRequestBookingNote: 'onRequestBookingNote',
  bookingReceived: 'bookingReceived',
  bookingReceivedNote1: 'bookingReceivedNote1',
  bookingReceivedNote2: 'bookingReceivedNote2',
  readMoreLink: 'readMoreLink',
  bookingReceivedPaymentFailed: 'bookingReceivedPaymentFailed',
  bookingReceivedPaymentFailedNote1: 'bookingReceivedPaymentFailedNote1',
  bookingReceivedPaymentFailedNote2: 'bookingReceivedPaymentFailedNote2',
  onRequestBookingPaymentFailed: 'onRequestBookingPaymentFailed',
  onRequestBookingPaymentFailedNote1: 'onRequestBookingPaymentFailedNote1',
  onRequestBookingPaymentFailedNote2: 'onRequestBookingPaymentFailedNote2',
  travelApplication: 'travelApplication',
  travelParticipantSimple: 'travelParticipantSimple',
  flightData: 'flightData',
  flightDataDeparture: 'flightDataDeparture',
  flightDataReturn: 'flightDataReturn',
  emailLabel: 'emailLabel',
  phoneLabel: 'phoneLabel',
  childLabel: 'childLabel',
  travelExtension: 'travelExtension',
  travelExtensionText: 'travelExtensionText',
  travelDetails: 'travelDetails',
  request: 'request',
  requested: 'requested',
  requestExtension: 'requestExtension',
  cancel: 'cancel',
  cancelHere: 'cancelHere',
  extensionAlreadyBooked: 'extensionAlreadyBooked',
  hotelSelection: 'hotelSelection',
  additionalExcursions: 'additionalExcursions',
  mediaCodeTitle: 'mediaCodeTitle',
  mediaCodeSubTitle: 'mediaCodeSubTitle',
  choosePlatform: 'choosePlatform',
  none: 'none',
  newsletter: 'newsletter',
  newspaper_print: 'newspaper_print',
  newspaper_online: 'newspaper_online',
  recommended: 'recommended',
  television: 'television',
  events_webinar: 'events_webinar',
  website: 'website',
  google: 'google',
  facebook: 'facebook',
  other_websites: 'other_websites',
  stationSelectHeadline: 'stationSelectHeadline',
  stationSelectPickup: 'stationSelectPickup',
  stationSelectDropOff: 'stationSelectDropOff',
  seatSelectionHeadline: 'seatSelectionHeadline',
  noReservation: 'noReservation',
  unavailable: 'unavailable',
  selected: 'selected',
  default: 'default',
  selectSeat: 'selectSeat',
  seatNumber: 'seatNumber',
  apply: 'apply',
  voucherHeadline: 'voucherHeadline',
  voucherText: 'voucherText',
  voucherPlaceholder: 'voucherPlaceholder',
  voucherSuccess: 'voucherSuccess',
  voucherError: 'voucherError',
  voucherInvalid: 'voucherInvalid',
  voucher: 'voucher',
  extra: 'extra',
  extraSubText: 'extraSubText',
  royalAlbLabel: 'royalAlbLabel',
  royalAlbPlaceholder: 'royalAlbPlaceholder',
  promoCodeHeadline: 'promoCodeHeadline',
  promoCodeText: 'promoCodeText',
  activate: 'activate',
  promoCodeSuccess: 'promoCodeSuccess',
  promoCodeError: 'promoCodeError',
  promoCodeInvalid: 'promoCodeInvalid',
  discount: 'discount',
  discountRoyalAlbatros: 'discountRoyalAlbatros',
  noSeatSelection: 'noSeatSelection',
  subscribeToNewsletter: 'subscribeToNewsletter',
  mediaCodeEnterMediaName: 'mediaCodeEnterMediaName',
  mediaCodeSelectDropdownNewsletter: 'mediaCodeSelectDropdownNewsletter',
  mediaCodeSelectDropdownDefault: 'mediaCodeSelectDropdownDefault',
  newsletter_value_1: 'newsletter_value_1',
  newsletter_value_2: 'newsletter_value_2',
  newsletter_value_3: 'newsletter_value_3',
  newsletter_value_4: 'newsletter_value_4',
  newsletter_value_5: 'newsletter_value_5',
  upgradeYourRoom: 'upgradeYourRoom',
  voucherAlreadyExists: 'voucherAlreadyExists',
  insuranceParticipant: 'insuranceParticipant',
  insuranceParticipantApplicant: 'insuranceParticipantApplicant',
  added: 'added',
  insurance: 'insurance',
  insuranceCancellation: 'insuranceCancellation',
  insuranceAdditional: 'insuranceAdditional',
  insuranceText: 'insuranceText',
  suppliedBy: 'suppliedBy',
  insuranceTAndCLinkLabel: 'insuranceTAndCLinkLabel',
  noInsuranceSelected: 'noInsuranceSelected',
  totalDiscount: 'totalDiscount',
  onRequest: 'onRequest'
}; export default Keys;