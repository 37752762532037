'use client';

import { useContext, createContext, ReactElement, FC, PropsWithChildren } from 'react';
import { Props } from '@/types/cms/magnolia';

export const GlobalMGLPropsContext = createContext<Props | undefined>(undefined);

export const GlobalMGLPropsContextProvider: FC<PropsWithChildren<Props>> = ({
  children,
  ...props
}): ReactElement => {
  return <GlobalMGLPropsContext.Provider value={props}>{children}</GlobalMGLPropsContext.Provider>;
};

export const useGlobalMGLProps = (): Props | undefined => {
  return useContext(GlobalMGLPropsContext);
};
