import AlbConfigModel from '@/Config/AlbConfigModel';
import { useEffect, useState } from 'react';
import { BroadCastData, broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { EventType } from '@/Tracking/types';
import { Props } from '@/types/cms/magnolia';

export const usePageViewEventOnCookieConsent = (
  pageProps?: Props,
  config?: AlbConfigModel,
  additionalCondition?: boolean,
  additionalEventData?: Partial<BroadCastData['data']>,
  eventTypes: EventType[] = [EventType.PAGE_VIEW]
): boolean => {
  const [pageViewWasTriggered, setPageViewWasTriggered] = useState<boolean>(false);

  useEffect(() => {
    if (!config || !pageProps) return;

    const { locale } = pageProps;

    function checkForConsent() {
      if (
        window?.CookieInformation?.getConsentGivenFor('cookie_cat_necessary') &&
        (additionalCondition !== undefined ? additionalCondition : true) &&
        !!config &&
        !pageViewWasTriggered
      ) {
        eventTypes.forEach(eventType => {
          broadcastEvent(eventType, config, locale, {
            data: !!additionalEventData ? { pageProps, ...additionalEventData } : { pageProps }
          });
        });
        setPageViewWasTriggered(true);
      }
    }

    window.removeEventListener('CookieInformationConsentGiven', checkForConsent, false);
    window.addEventListener('CookieInformationConsentGiven', checkForConsent, false);
    checkForConsent();

    return () => {
      window.removeEventListener('CookieInformationConsentGiven', checkForConsent, false);
    };
  }, [config, pageProps, additionalEventData, additionalCondition, pageViewWasTriggered]);

  return pageViewWasTriggered;
};
