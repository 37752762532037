import { ImageLoader, ImageLoaderProps } from 'next/image';

export type AspectRatio = 'default' | '4-3' | '3-2' | '1-1' | '9-16';
const fakeHost = 'https://faking-a-fake-host.net';

const getUrl = (localSrc: string, magnoliaContext: string, imagingPath: string): string => {
  if (!localSrc.startsWith('http')) {
    localSrc = `${fakeHost}${localSrc}`;
  }

  const url = new URL(localSrc);
  url.pathname = `${magnoliaContext}${`${imagingPath}${url.pathname}`
    .replace(magnoliaContext, '')
    .replace('/ibepublic', '')
    .replace('/ibeauthor', '')}`;
  let finalUrl = url.toString();
  return finalUrl.startsWith(fakeHost) ? finalUrl.replace(fakeHost, '') : finalUrl;
};

export function getNoLoader(): ImageLoader {
  return ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 100}`;
  };
}

export function getMagnoliaFocalUrl(
  variation: AspectRatio = 'default',
  magnoliaContext: string | undefined = '',
  src: string,
  width: number
): string {
  const imagingPath = `/.imaging/focalpoint/${variation || 'default'}/${width}x`;
  return getUrl(src, magnoliaContext, imagingPath);
}

export function getMagnoliaFocalImageLoader(
  variation: AspectRatio = 'default',
  magnoliaContext: string | undefined = ''
): ImageLoader {
  return ({ src, width }: ImageLoaderProps): string => {
    return getMagnoliaFocalUrl(variation, magnoliaContext, src, width);
  };
}

export function getDefaultImageLoader(magnoliaContext: string | undefined = '') {
  return ({ src, width }: ImageLoaderProps): string => {
    const imagingPath = `/.imaging/responsive/${width}x`;
    return getUrl(src, magnoliaContext, imagingPath);
  };
}
