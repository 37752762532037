'use client';

import React, { FC, JSX, useState } from 'react';
import SearchInner from '@/components/Search/SearchInner';
import { ApiProductsCacheResponse, ApiProductsSearchResponse } from '@ibe/api';
import { logger } from '@/Util/globals';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { useMount } from 'react-use';
import { useApi } from '@/Hooks/useApi';
import { useModelApiClient } from '@/Util/api';
import CustomSpinner from '@/components/CustomSpinner/CustomSpinner';
import classNames from 'classnames';
import { Props } from '@/types/cms/magnolia';

const Search: FC<{ isInitialSearch?: boolean; pageProps?: Props }> = ({
  isInitialSearch,
  pageProps
}): JSX.Element => {
  const api = useApi(pageProps?.isAuthor);
  const modelApiClient = useModelApiClient(pageProps?.isAuthor);
  const { siteConfig, rootNodePath = '' } = useGlobalMGLProps() || {};
  const [productPackages, setProductPackages] = useState<ApiProductsCacheResponse[]>([]);
  const [totalNumberOfProducts, setTotalNumberOfProducts] = useState<number>(0);

  useMount(async (): Promise<void> => {
    try {
      const promises: [
        Promise<ApiProductsSearchResponse | undefined>,
        Promise<number | undefined>
      ] = [new Promise(() => {}), new Promise(() => {})];

      promises[0] = api.searchProducts();

      promises[1] = modelApiClient.getProductsByMarketCount(
        siteConfig?.name || '',
        siteConfig?.name || '',
        pageProps?.locale
      );

      const [productsResponse, totalNumberOfProductResponse] = await Promise.all(promises);
      if (!!totalNumberOfProductResponse) {
        setTotalNumberOfProducts(totalNumberOfProductResponse);
      }

      setProductPackages(productsResponse?.products || []);
    } catch (err) {
      logger('error')('Unable to fetch search data: ', err);
    }
  });

  return productPackages.length > 0 ? (
    <SearchInner
      productPackagesTotal={productPackages}
      siteConfig={siteConfig}
      totalNumberOfProducts={totalNumberOfProducts}
      rootNodePath={rootNodePath}
      isInitialSearch={isInitialSearch}
      pageProps={pageProps}
    />
  ) : (
    <div
      className={classNames('search__custom-spinner', {
        search__container: isInitialSearch,
        'search-for-results': !isInitialSearch
      })}
    >
      <CustomSpinner />
    </div>
  );
};

export default Search;
