'use client';

import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import ItineraryAccordion from '@/components/itinerary/ItineraryAccordion';
import { facArrowDown } from '@/Theme/SVG/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GridContainer from '@/Layouts/GridContainer';
import { ApiItinerary, ApiProduct } from '../../../api/model';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/product.json.keys';
import { Props as PageProps } from '@/types/cms/magnolia';
import { useProductPageContext } from '@/components/productPageContext/ProductPageContext';
import { Spinner } from 'reactstrap';

type Props = {
  theme: string;
  itinerary: ApiItinerary[];
  product?: ApiProduct;
  pageProps?: PageProps;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

let timer: ReturnType<typeof setTimeout> | null = null;

const Itinerary: FC<Props> = ({ theme, itinerary, product }) => {
  const { t } = useTranslation('product');

  const { printMode, setPrintMode } = useProductPageContext();
  const [allOpen, setAllOpen] = useState<boolean>(false);

  useEffect(() => {
    function afterPrint() {
      setPrintMode(false);
    }
    window.addEventListener('afterprint', afterPrint);

    return (): void => {
      window.removeEventListener('afterprint', afterPrint);

      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useEffect(() => {
    if (printMode) {
      timer = setTimeout(function () {
        window.print();
      }, 6000);
    }
  }, [printMode]);

  const onClick = () => {
    setPrintMode(true);
  };

  return (
    <GridContainer className={`itinerary ${theme}`}>
      <div className="itinerary__wrapper">
        <h2 className="itinerary__header">{t(Keys.dailyProgram)}</h2>
        <div>
          <button
            className="btn btn-secondary itinerary__btn__showAll"
            onClick={() => setAllOpen(!allOpen)}
          >
            <span>{allOpen ? t(Keys.closeAll) : t(Keys.expandAll)}</span>
          </button>
          <button className="btn btn-primary itinerary__btn" onClick={onClick}>
            <div>
              <span className="itinerary__btn__text">{t(Keys.downloadFullProgram)}</span>
              {printMode ? (
                <Spinner className="itinerary__btn__spinner"></Spinner>
              ) : (
                <FontAwesomeIcon icon={facArrowDown} />
              )}
            </div>
          </button>
        </div>
      </div>
      {!!itinerary && itinerary.length > 0 && (
        <ItineraryAccordion
          groups={itinerary}
          product={product}
          setAllOpen={setAllOpen}
          allOpen={allOpen}
        />
      )}
    </GridContainer>
  );
};

export default Itinerary;
