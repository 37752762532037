import { PropsWithChildren } from 'react';
import {
  DefaultPageProps as Props,
  MagnoliaNode,
  MagnoliaSite,
  Props as PageProps
} from '@/types/cms/magnolia';
import { ApiProductsCacheData, ApiProductsCacheResponse, ApiProductWithReviews } from '@ibe/api';
import { ApiProduct } from '../../api/model';
import Keys from '@/Translations/generated/da/product.json.keys';
import { PRODUCT_AVAILABILITY_STATE, PRODUCT_SORT_ORDER } from '@/Util/globals';
import { ContentNavigationType } from '@/components/contentNavigation/ContentNavigation';
import { DEPARTURE_DATES_ID } from '@/templates/ProductPageInner';
import { TFunction } from 'i18next';

export type ProductPageInnerProps = PropsWithChildren<
  Props & {
    text: string;
    tel: string;
    mail: string;
    themeContactInformation: string;
    showContactBar: boolean;
    checkoutPageNode: MagnoliaNode;
    productPackages: ApiProductsCacheResponse[];
    product?: ApiProduct;
    originalProduct?: ApiProduct;
    siteConfig?: MagnoliaSite;
    isVariant?: boolean;
    selectedProductPackage?: ApiProductsCacheResponse;
    reviews?: ApiProductWithReviews;
    pageProps?: PageProps;
    cheapestCacheProduct?: ApiProductsCacheData;
    productsAvailabilityState: PRODUCT_AVAILABILITY_STATE;
  }
>;

const getProductPageInnerElements = (
  t: TFunction,
  productsAvailabilityState: PRODUCT_AVAILABILITY_STATE,
  product?: ApiProduct,
  cheapestCacheProduct?: ApiProductsCacheData
): ContentNavigationType => {
  const {
    imageGallery,
    productVideos,
    introductionTitle,
    tripDescription,
    map,
    highlights,
    itinerary,
    priceInformation,
    productInformation,
    extensions,
    singleAccommodations,
    multipleAccommodations,
    factSheet,
    excursions,
    sortOrder,
    reviewsCode,
    childPrice,
    roomUpgrade,
    priceInfoExcursions,
    priceIncludes,
    priceDoesNotInclude
  } = product || {};

  return [
    !!introductionTitle ||
    !!tripDescription ||
    !!map ||
    (highlights && highlights?.length > 0) ||
    !!factSheet?.language ||
    !!factSheet?.transportation ||
    !!factSheet?.startingLocation
      ? {
          title: t(Keys.introduction),
          id: 'introduction'
        }
      : null,
    !!singleAccommodations &&
    singleAccommodations.length > 0 &&
    sortOrder === PRODUCT_SORT_ORDER.accommodation
      ? {
          id: 'accommodation',
          hideInNavbar: true
        }
      : null,
    !!multipleAccommodations &&
    multipleAccommodations.length > 0 &&
    sortOrder === PRODUCT_SORT_ORDER.accommodation
      ? {
          id: 'accommodations',
          hideInNavbar: true
        }
      : null,
    !!imageGallery && imageGallery?.length > 0
      ? {
          title: t(Keys.images),
          id: 'images'
        }
      : null,
    itinerary && itinerary?.length > 0
      ? {
          title: t(Keys.itinerary),
          id: 'itinerary'
        }
      : null,
    productsAvailabilityState !== PRODUCT_AVAILABILITY_STATE.pastTripNoFutureAvail
      ? {
          title: t(Keys.departureDates),
          id: DEPARTURE_DATES_ID
        }
      : null,
    (!!priceInformation && priceInformation?.length > 0) ||
    !!cheapestCacheProduct ||
    !!childPrice ||
    !!roomUpgrade ||
    !!priceInfoExcursions ||
    !!priceIncludes ||
    !!priceDoesNotInclude
      ? {
          title: t(Keys.priceInformation),
          id: 'price-information'
        }
      : null,
    !!singleAccommodations &&
    singleAccommodations.length > 0 &&
    sortOrder === PRODUCT_SORT_ORDER.default
      ? {
          id: 'accommodation',
          hideInNavbar: true
        }
      : null,
    !!multipleAccommodations &&
    multipleAccommodations.length > 0 &&
    sortOrder === PRODUCT_SORT_ORDER.default
      ? {
          id: 'accommodations',
          hideInNavbar: true
        }
      : null,
    productInformation &&
    productInformation.contentAreas &&
    productInformation.contentAreas?.length > 0
      ? {
          id: 'contentArea',
          hideInNavbar: true
        }
      : null,
    !!excursions && excursions.length > 0
      ? {
          id: 'excursions',
          hideInNavbar: true
        }
      : null,
    !!extensions && extensions.length > 0
      ? {
          title: t(Keys.extensionTeaser),
          id: 'extensionTeaser'
        }
      : null,
    sortOrder === PRODUCT_SORT_ORDER.accommodation && !!reviewsCode
      ? { title: '', id: 'reviews', hideInNavbar: true }
      : null,
    {
      title: t(Keys.travelInformation),
      id: 'travel-information'
    },
    sortOrder === PRODUCT_SORT_ORDER.default && !!reviewsCode
      ? { title: '', id: 'reviews', hideInNavbar: true }
      : null,
    !!productVideos && productVideos?.length > 0
      ? {
          id: 'videos',
          hideInNavbar: true
        }
      : null
  ].filter(Boolean) as ContentNavigationType;
};

export default getProductPageInnerElements;
