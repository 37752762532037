'use client';

import React, { FC } from 'react';
import sanitize from '@/Util/sanitize';
import ImageSlider from '@/components/imageSlider/ImageSlider';
import AccordionElementList from '@/components/accordion/AccordionElementList';
import { ApiItinerary, ApiProduct } from '../../../api/model';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/product.json.keys';
import { Props } from '@/types/cms/magnolia';
import Image from '@/components/image/Image';

const ItineraryAccordion: FC<{
  groups: ApiItinerary[];
  pageProps?: Props;
  product?: ApiProduct;
  setAllOpen?: (state: boolean) => void;
  allOpen?: boolean;
}> = ({ groups, pageProps, product, setAllOpen, allOpen }) => {
  const { t } = useTranslation('product');

  return (
    <div className="itinerary__accordion">
      <AccordionElementList
        setAllOpen={setAllOpen}
        allOpen={allOpen}
        items={groups}
        bodyCb={group => (
          <div className="itinerary__accordion__group">
            {group.images && group.images.length > 0 && (
              <div className="itinerary__accordion__group__imageslider">
                <ImageSlider images={group.images} pageProps={pageProps} />
              </div>
            )}
            <div
              className="itinerary__accordion__group__text"
              dangerouslySetInnerHTML={{ __html: sanitize(group.description || '', true) }}
            />
          </div>
        )}
        headerCb={group => (
          <div className="grid">
            <div className="itinerary__accordion__header__days__wrapper grid-col-2">
              <Image
                image={group?.images?.[0] || product?.imageGallery?.[0] || {}}
                className="itinerary__accordion__image"
                serverSideContext={pageProps?.albConfig.context}
                pageProps={pageProps}
              />
              <p className="itinerary__accordion__header__days">
                {`${t(Keys.singleDay)} ${group.dayFrom}${
                  group.dayFrom !== group.dayTo ? ` - ${group.dayTo}` : ''
                }`}
              </p>
            </div>
            <div className="grid-col-8">
              <p className="itinerary__accordion__header__text"> {group.title} </p>
            </div>
          </div>
        )}
        indexerProp="title"
      />
    </div>
  );
};
export default ItineraryAccordion;
