'use client';

import {
  createContext,
  PropsWithChildren,
  JSX,
  useState,
  useContext,
  Dispatch,
  SetStateAction
} from 'react';

export type ProductPageContextType = {
  printMode: boolean;
  setPrintMode: Dispatch<SetStateAction<boolean>>;
};

export const ProductPageContext = createContext<ProductPageContextType>({
  printMode: false,
  setPrintMode: () => {}
});

export const ProductPageContextProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [printMode, setPrintMode] = useState<boolean>(false);

  return (
    <ProductPageContext.Provider value={{ printMode, setPrintMode }}>
      {children}
    </ProductPageContext.Provider>
  );
};

export const useProductPageContext = (): ProductPageContextType => {
  return useContext<ProductPageContextType>(ProductPageContext);
};
