import React, { FC, JSX, RefObject, MouseEvent, useMemo, Dispatch, SetStateAction } from 'react';
import Variant from '@/components/ProductsDatesPrices/Variant';
import dayjs from 'dayjs';
import Keys from '@/Translations/generated/da/product.json.keys';
import { getDefaultFormatPrice } from '@/Util/globals';
import Button from '@/components/Button';
import { ApiProductsCacheData, ApiProductsCacheResponse } from '@ibe/api';
import useConfig from '@/Hooks/useConfig';
import { useTranslation } from '@/app/i18n/client';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { ApiProduct } from '../../../api/model';
import { useRouter } from 'next/navigation';
import classNames from 'classnames';
import { getProductByOccupancy } from '@/components/Search/helpersProduct';
import { getVariantParam } from '@/components/ProductsDatesPrices/getVariantParam';
import { MagnoliaSite } from '@/types/cms/magnolia';
import { useTransitionProgress } from '@/Hooks/useReactTransitionProgress';

export type DesktopContentProps = {
  productPackage: ApiProductsCacheResponse;
  siteConfig?: MagnoliaSite;
  isVariant?: boolean;
  product?: ApiProduct;
  originalProduct?: ApiProduct;
  isDesktop: boolean;
  containerRef: RefObject<HTMLDivElement>;
  goToCheckout: (item: ApiProductsCacheData, variantParam?: string) => void;
  selectedPackage?: ApiProductsCacheResponse;
  variantUrl: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const TableRow: FC<DesktopContentProps> = ({
  productPackage,
  goToCheckout,
  isVariant,
  product,
  isDesktop,
  containerRef,
  selectedPackage,
  originalProduct,
  variantUrl,
  setLoading
}): JSX.Element => {
  const { t } = useTranslation('product');
  const router = useRouter();
  const language = useCurrentLanguage();
  const config = useConfig();
  const startTransition = useTransitionProgress();

  const productByOccupancy = useMemo(
    (): ApiProductsCacheData | undefined => getProductByOccupancy(productPackage),
    [productPackage, productPackage.products]
  );

  const hasAvailabilities = useMemo((): boolean | undefined => {
    return productPackage.products?.some(cacheProduct => (cacheProduct?.availability ?? 0) > 0);
  }, [productPackage]);

  const isSelected = useMemo((): boolean => {
    const selectedDate = dayjs(selectedPackage?.products?.[0]?.travelStartDate);
    const currentDate = dayjs(productPackage.products?.[0]?.travelStartDate);
    return (
      selectedDate.isValid() &&
      currentDate.isValid() &&
      selectedDate.isSame(currentDate, 'day') &&
      selectedPackage?.packageCode === productPackage.packageCode
    );
  }, [productPackage, selectedPackage]);

  const handleRowClick = (): void => {
    if (variantUrl) {
      startTransition(() => {
        router.push(variantUrl);
      });
    }
  };

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    if (productPackage?.products?.[0].bookingLink) {
      window.open(productPackage.products[0].bookingLink, '_blank');
    } else if (productByOccupancy) {
      startTransition(() => {
        goToCheckout(
          productByOccupancy,
          getVariantParam(
            originalProduct,
            productByOccupancy?.travelStartDate ?? '',
            product?.variants,
            productPackage.packageCode
          )
        );
      });
    }
  };

  return (
    <tr
      className={classNames({
        'dates-prices__variant-row--active': isSelected
      })}
      onClick={!isSelected ? handleRowClick : undefined}
    >
      <td>
        {!isSelected && (
          <Variant
            isVariant={isVariant}
            product={product}
            productPackage={productPackage}
            isDesktop={isDesktop}
            containerRef={containerRef}
            variantUrl={variantUrl}
            setLoading={setLoading}
          />
        )}
        {productByOccupancy?.travelStartDate && (
          <span>
            {dayjs(productByOccupancy.travelStartDate).format(config.displayFormatDate[language])}
          </span>
        )}
      </td>
      <td>
        <span>{t(Keys.day, { count: productByOccupancy?.duration ?? 0 })}</span>
      </td>
      <td>
        <span>{getDefaultFormatPrice(productByOccupancy?.startingPriceAmount ?? 0, language)}</span>
      </td>
      <td>
        <span>{productByOccupancy?.departureAirport ?? ''}</span>
      </td>
      <td>
        <span>
          {(productByOccupancy?.availability ?? 0) > 0 &&
          (productByOccupancy?.availability ?? 1) / (productByOccupancy?.maxAvailability ?? 1) < 0.2
            ? t(Keys.almostSoldOut)
            : (productByOccupancy?.tourGuide ?? '')}
        </span>
      </td>
      <td className="dates-prices__button theme-dark-green">
        <span>
          <Button
            color="primary"
            showCaret={hasAvailabilities}
            disabled={!hasAvailabilities}
            onClick={hasAvailabilities ? handleButtonClick : undefined}
          >
            {hasAvailabilities ? t(Keys.book) : t(Keys.soldOut)}
          </Button>
        </span>
      </td>
    </tr>
  );
};

export default TableRow;
