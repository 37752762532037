import React, { FC, JSX, ReactElement, useState, ChangeEvent } from 'react';
import { CheckboxCheck } from '@/Theme/SVG/Svgs';
import classNames from 'classnames';
import { Input, Label } from 'reactstrap';

export type CheckboxProps = {
  checked?: boolean;
  onChange?: () => void;
  label?: string | ReactElement;
  disabled?: boolean;
  className?: string;
  name?: string;
  manual?: boolean;
  id?: string;
};

const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  disabled,
  className,
  name,
  manual,
  id
}): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!disabled && !!onChange && !manual) {
      onChange();
    }
    if (manual) {
      setIsChecked(e.target.checked);
    }
  };

  return (
    <Label
      className={classNames(
        'checkbox',
        className || '',
        { 'checkbox--disabled': disabled },
        { 'checkbox--checked': checked }
      )}
      for={id}
    >
      <Input
        id={id}
        type="checkbox"
        name={name}
        checked={manual ? isChecked : checked}
        onChange={handleChange}
      />
      <div className="checkbox__field">{(manual ? isChecked : checked) && <CheckboxCheck />}</div>
      {!!label && (
        <Label check={manual} className="checkbox__label" for={id}>
          {label}
        </Label>
      )}
    </Label>
  );
};

export default Checkbox;
