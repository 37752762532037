import { ImageTypeProps } from '@/components/image/Image';
import { ApiImage } from '../../../../api/model';
import { ImageObject, WithContext } from 'schema-dts';

export const getSchemaOrgJsonImage = (
  imageProps?: Partial<ImageTypeProps>,
  image?: ApiImage,
  isMapImage?: boolean,
  thumbnailUrl?: string,
  host?: string
): WithContext<ImageObject> => {
  const name = (imageProps?.title || image?.title || image?.caption || '').trim();
  const caption = (image?.caption || '').trim();

  return {
    '@context': 'https://schema.org',
    '@type': 'ImageObject',
    image: `${host || ''}${imageProps?.src || image?.imageLink || ''}`,
    ...(thumbnailUrl ? { thumbnailUrl } : {}),
    ...(name ? { name } : {}),
    ...(caption ? { caption } : {}),
    ...(isMapImage
      ? {
          associatedMedia: {
            '@type': ['CreativeWork', 'MediaObject', 'Map']
          }
        }
      : {})
  } as WithContext<ImageObject>;
};
