'use client';

import React, { FC, PropsWithChildren, JSX, useRef, useState, ReactElement, useMemo } from 'react';
import { MagnoliaNode, Props as PageProps } from '@/types/cms/magnolia';
import Image from '@/components/image/Image';
import Keys from '@/Translations/generated/da/hero.json.keys';
import dynamic from 'next/dynamic';
import { useTranslation } from '@/app/i18n/client';
import { ApiImage, ApiVideo } from '../../../api/model';
import { VideoProps } from '@/components/videoGallery/Video';
import { mapToApiImage } from '@/Util/globals';
import classNames from 'classnames';
import { AlbatrosLogoDesktopWhite2 } from '@/Theme/SVG/AlbatrosLogos';
import {
  facCalendar,
  facClockDuration,
  facCoinStack,
  facLanguage,
  facPlane
} from '@/Theme/SVG/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiProductsCacheResponse } from '@ibe/api';
import { getCheapestProduct } from '@/components/Search/helpersProduct';
import { useMount } from 'react-use';
import { Language } from '@/app/i18n/settingsClient';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { useProductPageContext } from '@/components/productPageContext/ProductPageContext';
import { CircleCaretDownProps } from '@/Theme/SVG/Svgs';
import { getMagnoliaFocalUrl } from '@/Util/imageLoader';

export type HeroContent = 'video' | 'image';

const CircleCaretDown = dynamic<CircleCaretDownProps>(
  () => import('@/Theme/SVG/Svgs').then(mod => mod.CircleCaretDown),
  {
    ssr: true
  }
);

const Video = dynamic<VideoProps>(
  () => import('components/videoGallery/Video').then(mod => mod.Video),
  {
    ssr: true
  }
);

export type HeroInnerProps = {
  contentvideo?: ApiVideo;
  contentimage?: ApiImage;
  content?: HeroContent;
  headline?: string;
  headlineType?: 'h1' | 'h2' | 'h3';
  tagline?: string;
  useVideoScaleEffect?: boolean;
  showSearchPanel?: boolean;
  smallVersion?: boolean;
  liftContent?: boolean;
  hideScrollBtn?: boolean;
  additionalContent?: ReactElement;
  pageProps?: PageProps;
  productData?: ApiProductsCacheResponse;
  language?: string;
  pdfCoverImage?: ApiImage;
  useDefaultLoader?: boolean;
};

type Props = Partial<MagnoliaNode> & HeroInnerProps;

const getTextStartOffset = (locale: string): number => {
  switch (locale) {
    case Language.DA:
    case Language.NO:
      return 9;
    case Language.FI:
      return 16;
    case Language.SV:
      return 13;
    default:
      return 2;
  }
};

const getTextLength = (locale: string): number | undefined => {
  switch (locale) {
    case Language.PL:
      return 130;
    default:
      return undefined;
  }
};

const HeroInner: FC<PropsWithChildren<Props>> = (props): JSX.Element => {
  const {
    content,
    contentimage,
    contentvideo,
    headline,
    headlineType,
    hideScrollBtn,
    tagline,
    useVideoScaleEffect,
    children,
    smallVersion,
    liftContent,
    additionalContent,
    productData,
    pageProps,
    language,
    pdfCoverImage,
    useDefaultLoader
  } = props;
  const { t } = useTranslation('hero');
  const locale = useCurrentLanguage();
  const { printMode } = useProductPageContext();
  const scrollRef = useRef<HTMLDivElement>(null);

  const [hoverState, setHoverState] = useState<boolean>(false);
  const [componentMounted, setComponentMounted] = useState<boolean>(false);
  const HeadlineType = headlineType || 'h1';

  useMount(() => {
    setComponentMounted(true);
  });

  const scrollDown = (): void => {
    if (!!scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const cheapestCacheProduct = useMemo(() => {
    return !!productData ? getCheapestProduct(productData) : undefined;
  }, [productData]);

  return (
    <>
      <div
        className={classNames('hero', {
          'hero--small': smallVersion,
          'hero--lift-content': liftContent
        })}
      >
        <div className="hero__logo">
          <AlbatrosLogoDesktopWhite2 />
        </div>
        {(!!headline || !!tagline) && (
          <div className="hero__headline">
            {!!headline && <HeadlineType className="hero__head">{headline}</HeadlineType>}
            {!!tagline && <h3 className="hero__tagline">{tagline}</h3>}
            {!!additionalContent && (
              <div className="hero__additionalContent">{additionalContent}</div>
            )}
          </div>
        )}
        {content === 'video' && !!contentvideo ? (
          <Video
            videoSrc={contentvideo.videoSrc || ''}
            backupVideoSrc={contentvideo.backupVideoSrc || ''}
            className="hero__video"
            placeholderImageLink={getMagnoliaFocalUrl(
              'default',
              pageProps?.albConfig?.context,
              mapToApiImage(contentvideo.placeholderImage)?.imageLink || '',
              1280
            )}
            useScaleEffect={useVideoScaleEffect}
          />
        ) : content === 'image' && !!contentimage && componentMounted ? (
          <Image
            image={contentimage}
            className="hero__image"
            priority
            fetchPriority="high"
            serverSideContext={pageProps?.albConfig.context}
            useDefaultLoader={useDefaultLoader}
            pageProps={pageProps}
          />
        ) : (
          <></>
        )}
        {!!pdfCoverImage && componentMounted && printMode && (
          <Image
            image={pdfCoverImage}
            className="hero__image__pdf"
            useDefaultLoader
            ignoreFadeIn
            priority
            fetchPriority="high"
            pageProps={pageProps}
          />
        )}
        {!hideScrollBtn && !smallVersion ? (
          <div
            className={`hero__down${hoverState ? ' hero__down--hover' : ''}`}
            onClick={scrollDown}
            onMouseEnter={(): void => setHoverState(true)}
            onMouseLeave={(): void => setHoverState(false)}
          >
            <CircleCaretDown
              text={t(Keys.scrollForMore)}
              textLength={getTextLength(locale)}
              textStartOffset={getTextStartOffset(locale)}
              className={locale === Language.PL ? 'circle-caret-down--pl' : ''}
            />
          </div>
        ) : (
          <></>
        )}
        <hr className="hero__line" />
        <div className="hero__infobox">
          {cheapestCacheProduct?.travelStartDate && (
            <div className="hero__infobox__item">
              <FontAwesomeIcon className="hero__infobox__item__icon" icon={facCalendar} />
              <div>
                <div className="hero__infobox__item__text">{t(Keys.date)}</div>
                <div className="hero__infobox__item__text">
                  {cheapestCacheProduct?.travelStartDate}
                </div>
              </div>
            </div>
          )}
          {cheapestCacheProduct?.duration && (
            <div className="hero__infobox__item">
              <FontAwesomeIcon className="hero__infobox__item__icon" icon={facClockDuration} />
              <div>
                <div className="hero__infobox__item__text">{t(Keys.duration)}</div>
                <div className="hero__infobox__item__text">{`${cheapestCacheProduct?.duration?.toString()} ${
                  t(Keys.days) ?? 'N/A'
                }`}</div>
              </div>
            </div>
          )}
          {cheapestCacheProduct?.startingPriceAmount && (
            <div className="hero__infobox__item">
              <FontAwesomeIcon className="hero__infobox__item__icon" icon={facCoinStack} />
              <div>
                <div className="hero__infobox__item__text">{t(Keys.price)}</div>
                <div className="hero__infobox__item__text">
                  {new Intl.NumberFormat().format(cheapestCacheProduct?.startingPriceAmount)}
                </div>
              </div>
            </div>
          )}
          {cheapestCacheProduct?.departureAirport && (
            <div className="hero__infobox__item">
              <FontAwesomeIcon className="hero__infobox__item__icon" icon={facPlane} />
              <div>
                <div className="hero__infobox__item__text">{t(Keys.departure)}</div>
                <div className="hero__infobox__item__text">
                  {cheapestCacheProduct?.departureAirport}
                </div>
              </div>
            </div>
          )}
          {language && (
            <div className="hero__infobox__item">
              <FontAwesomeIcon className="hero__infobox__item__icon" icon={facLanguage} />
              <div>
                <div className="hero__infobox__item__text">{t(Keys.language)}</div>
                <div className="hero__infobox__item__text">{language}</div>
              </div>
            </div>
          )}
        </div>
        {children}
      </div>
      <div ref={scrollRef} className="hero__scroll-ref" />
    </>
  );
};

export default HeroInner;
