import { useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash-es/debounce';

export const useDebounce = (callback: (...args: any[]) => Promise<void>, delay: number = 300) => {
  const ref = useRef<(...args: any[]) => Promise<void>>(undefined);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = (...args: any[]) => {
      ref?.current?.(...args);
    };

    return debounce(func, delay);
  }, []);
};
