import CheckoutStore from '@/templates/checkout/CheckoutStore';
import {
  ApiBookedItem,
  ApiComponentType,
  ApiHotelRoom,
  ApiItemType,
  ApiPrice,
  ApiPriceFromJSON
} from '@ibe/api';
import { isHotelRoom } from '@/types/typeGuards';
import { getServiceTypeCode } from '@/components/checkout/cart/getUpgrades';
import { API_ITEM_SERVICE_CODE } from '@/Util/globals';

export type AccommodationsData = {
  hotelsAndRooms: { hotel: ApiBookedItem; rooms: ApiHotelRoom[] }[];
  totalPricePerPerson: ApiPrice;
  totalPrice: ApiPrice;
};

export const isHotel = (item: ApiBookedItem): boolean => {
  return (
    item.itemType === ApiItemType.HOTEL &&
    item.componentType !== ApiComponentType.MAIN &&
    getServiceTypeCode(item) !== API_ITEM_SERVICE_CODE.OPERA
  );
};

export const getAccommodations = (checkoutStore: CheckoutStore): AccommodationsData => {
  const { booking } = checkoutStore;
  const hotels = booking?.bookedItems.filter(item => isHotel(item)) || [];
  const combined = hotels.reduce(
    (total: Array<{ hotel: ApiBookedItem; rooms: ApiHotelRoom[] }>, current: ApiBookedItem) => {
      return [
        ...total,
        {
          hotel: current,
          rooms: booking?.items.filter(
            item =>
              item.type === 'HotelRoom' &&
              isHotelRoom(item) &&
              item.roomrates.some(rate => rate.id === current.idParent)
          ) as unknown as ApiHotelRoom[]
        }
      ];
    },
    []
  );
  return {
    hotelsAndRooms: combined,
    totalPricePerPerson: ApiPriceFromJSON({
      ...(!!combined[0] ? combined[0].hotel.price : {}),
      finalPrice: combined.reduce((total, current) => {
        return (
          total +
          (!!current?.hotel ? Object.values(current.hotel.priceByPersonId)[0]?.finalPrice || 0 : 0)
        );
      }, 0),
      modifiers: []
    }),
    totalPrice: ApiPriceFromJSON({
      ...(!!combined[0] ? combined[0].hotel.price : {}),
      finalPrice: combined.reduce((total, current) => {
        return total + (!!current?.hotel ? current.hotel?.price?.finalPrice || 0 : 0);
      }, 0),
      modifiers: []
    })
  };
};
