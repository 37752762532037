import React, { FC, JSX, useEffect, useMemo } from 'react';
import { ApiProductsCacheResponse } from '@ibe/api';
import { Props } from '@/types/cms/magnolia';
import { getCheapestProduct } from '@/components/Search/helpersProduct';
import { getProductPageBasePath } from '@/Util/globals';
import { forceCheck } from 'react-lazyload';
import ResultsListTeaserView from '@/components/SearchForResults/ResultsListTeaserView';

const WebsiteSearchFull: FC<{
  results: ApiProductsCacheResponse[];
  pageProps?: Props;
  activeIndex: number;
}> = ({ results, pageProps, activeIndex }): JSX.Element => {
  const { rootNodePath, siteConfig } = pageProps || {};
  const tabIndex = 0;
  const sortedPackages = useMemo((): ApiProductsCacheResponse[] => {
    return results.sort((a: ApiProductsCacheResponse, b: ApiProductsCacheResponse) => {
      return (
        (getCheapestProduct(a)?.startingPriceAmount || 0) -
        (getCheapestProduct(b)?.startingPriceAmount || 0)
      );
    });
  }, [results]);

  useEffect(() => {
    setTimeout((): void => {
      forceCheck();
    }, 300);
  }, [sortedPackages]);

  return (
    <div
      style={activeIndex !== tabIndex ? { maxHeight: 0 } : {}}
      className="website-search-full__results__list website-search-full__results__list--teasers"
    >
      <ResultsListTeaserView
        activeView={0}
        pageProps={pageProps}
        productPageBasePath={getProductPageBasePath(rootNodePath || '', siteConfig)}
        sortedPackages={sortedPackages}
        offset={0}
        height={520}
      />
    </div>
  );
};

export default WebsiteSearchFull;
