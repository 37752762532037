import React, { Dispatch, FC, RefObject, SetStateAction, useRef, JSX } from 'react';
import { CollapseIndex, SearchProps } from '@/components/Search/SearchDesktopOverlay';
import { ApiProductsCacheResponse } from '@ibe/api';
import SearchMarkup from '@/components/Search/SearchMarkup';
import SearchForResultsMarkup from '@/components/SearchForResults/SearchForResultsMarkup';
import useClickAwayMultiple from '@/Hooks/useClickAwayMultiple';

const SearchUI: FC<
  SearchProps & {
    textSearchInputRef: RefObject<HTMLInputElement>;
    setCollapseOpen: Dispatch<SetStateAction<CollapseIndex>>;
    hasSelectedItems: boolean;
    isDesktop: boolean;
    closeCollapse: () => void;
    singleRoomOnlyRadioChecked: boolean;
    setSingleRoomOnlyRadioChecked: Dispatch<SetStateAction<boolean>>;
    productPackages: ApiProductsCacheResponse[];
  }
> = ({
  activeIndex,
  setCollapseOpen,
  collapseOpen,
  checkboxState,
  checkboxDispatch,
  setSingleRoomOnlyRadioChecked,
  singleRoomOnlyRadioChecked,
  closeCollapse,
  isInitialSearch,
  productPackages,
  hasSelectedItems,
  inputValue,
  handleInputChange,
  totalNumberOfProducts,
  textSearch,
  textSearchInputRef,
  goToResultsPage,
  isLoading,
  isDesktop,
  productPageBasePath,
  resultsPageBasePath
}): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);

  useClickAwayMultiple([containerRef], (): void => {
    setCollapseOpen(0);
  });

  return (
    <div ref={containerRef}>
      {isInitialSearch ? (
        <SearchMarkup
          collapseOpen={collapseOpen}
          activeIndex={activeIndex}
          inputValue={inputValue}
          handleInputChange={handleInputChange}
          totalNumberOfProducts={totalNumberOfProducts}
          textSearch={textSearch}
          checkboxDispatch={checkboxDispatch}
          checkboxState={checkboxState}
          textSearchInputRef={textSearchInputRef}
          goToResultsPage={goToResultsPage}
          isLoading={isLoading}
          isDesktop={isDesktop}
          productPageBasePath={productPageBasePath}
          setCollapseOpen={setCollapseOpen}
          hasSelectedItems={hasSelectedItems}
          closeCollapse={closeCollapse}
          isInitialSearch={isInitialSearch}
          resultsPageBasePath={resultsPageBasePath}
        />
      ) : (
        <SearchForResultsMarkup
          collapseOpen={collapseOpen}
          activeIndex={activeIndex}
          inputValue={inputValue}
          handleInputChange={handleInputChange}
          totalNumberOfProducts={totalNumberOfProducts}
          textSearch={textSearch}
          checkboxDispatch={checkboxDispatch}
          checkboxState={checkboxState}
          textSearchInputRef={textSearchInputRef}
          goToResultsPage={goToResultsPage}
          isLoading={isLoading}
          isDesktop={isDesktop}
          productPageBasePath={productPageBasePath}
          setCollapseOpen={setCollapseOpen}
          hasSelectedItems={hasSelectedItems}
          closeCollapse={closeCollapse}
          isInitialSearch={isInitialSearch}
          singleRoomOnlyRadioChecked={singleRoomOnlyRadioChecked}
          setSingleRoomOnlyRadioChecked={setSingleRoomOnlyRadioChecked}
          productPackages={productPackages}
          resultsPageBasePath={resultsPageBasePath}
        />
      )}
    </div>
  );
};

export default SearchUI;
