import React, { FC, Fragment, JSX, useMemo } from 'react';
import { ApiWebsiteOtherPagesSearch } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link as NextLink } from '@/Hooks/useReactTransitionProgress';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Header.json.keys';
import LazyLoad from 'react-lazyload';
import FadeIn from '@/components/SearchForResults/FadeIn';
import sanitize from '@/Util/sanitize';
import { trimAndHighlightText } from '@/components/websiteSearch/TrimAndHighlightText';

const WebsiteSearchFullOtherPagesList: FC<{
  results: ApiWebsiteOtherPagesSearch[];
  activeIndex: number;
  query: string;
}> = ({ results, activeIndex, query }): JSX.Element => {
  const { t } = useTranslation('Header');

  const categoryPriorityByCategoryName = (categoryName: string): number => {
    switch (categoryName) {
      case 'rejsetyper':
      case 'matkatyypit':
      case 'reisetyper':
      case 'resetyper':
      case 'rodzaj-wycieczki':
        return 4;
      case 'opdag':
      case 'inspiroidu':
      case 'oppdag':
      case 'upptack':
      case 'inspiracje':
        return 3;
      case 'praktisk':
      case 'kaytannon-tietoa':
      case 'praktyczne':
        return 2;
      case 'om-albatros':
      case 'lue-albatrosista':
      case 'o-biurze-albatros':
        return 1;
      case 'ansvarlighed':
      case 'vastuullisuus':
      case 'samfunnsansvar':
      case 'hallbarhet':
      case 'odpowiedzialnosc-w-biznesie-csr':
        return 0;
      default:
        return 0;
    }
  };

  const groupByPageType = useMemo(() => {
    let map: Record<string, ApiWebsiteOtherPagesSearch[]> = {};
    const sortedByPriority = results.sort((a, b) => {
      if (a.priority && b.priority) {
        return b.priority - a.priority;
      } else {
        return 0;
      }
    });
    sortedByPriority.forEach((item: ApiWebsiteOtherPagesSearch) => {
      map[item.pageType] = [...(map[item.pageType] || []), item];
    });
    return Object.entries(map).sort((a, b) => {
      return categoryPriorityByCategoryName(b[0]) - categoryPriorityByCategoryName(a[0]);
    });
  }, [results]);

  return (
    <div
      style={activeIndex !== 2 ? { maxHeight: 0 } : {}}
      className="website-search-full__results__list"
    >
      {groupByPageType.map(([key, value]) => (
        <Fragment key={key}>
          <h4 key={key}>{key}</h4>
          {value.map(item => (
            <LazyLoad key={item.title} throttle={300} offset={400} height={420} scroll resize>
              <FadeIn>
                <div key={item.title} className="website-search-full__results__list--row">
                  <b>{item.title}</b>
                  <a href={item.url}>
                    <span>{t(Keys.learnMore)}</span>
                    <FontAwesomeIcon icon={faCircleArrowRight} />
                  </a>
                  {item.matchedText && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(trimAndHighlightText(item.matchedText, query), true)
                      }}
                    />
                  )}
                </div>
              </FadeIn>
            </LazyLoad>
          ))}
        </Fragment>
      ))}
    </div>
  );
};

export default WebsiteSearchFullOtherPagesList;
