import React, { JSX, PropsWithChildren, useEffect, useState } from 'react';
import { PageEditorBridge, TemplateAnnotations } from '@magnolia/template-annotations';
import {
  EditorContextHelper,
  PersonalizationService,
  EditorContext,
  constants
} from '@magnolia/react-editor';
import EditableComment from '../EditableComponent/EditableComment';
import { EditablePageProps } from '../EditablePage/EditablePageServer';
import { EditorContextType } from '@/components/magnoliaPage/componentHelper';
import { logger } from '@/Util/globals';

const EditablePage = (props: PropsWithChildren<EditablePageProps>): JSX.Element => {
  const {
    content: contentProp,
    templateAnnotations: templateAnnotationsProp = null,
    templateDefinitions: templateDefinitionsProp = null,
    config: {
      componentMappings: componentMappingsProp = {},
      fallbackComponent: fallbackComponentProp = false
    },
    children
  } = props;

  const [selectedComponentVariants, setSelectedComponentVariants] = useState<string | null>(null);
  const [listeners] = useState<object>({});

  useEffect(() => {
    if (EditorContextHelper.inIframe()) {
      PageEditorBridge.onMessage(
        listeners,
        'updateState',
        (message: { selectedComponentVariants: string }) => {
          setSelectedComponentVariants(message.selectedComponentVariants);
        }
      );

      PageEditorBridge.init(listeners, undefined as unknown as Function);
    }
  }, [listeners, selectedComponentVariants]);

  const onReady = (): void => {
    EditorContextHelper.onFrameReady();
    EditorContextHelper.refresh();
  };

  const getAnnotationString = (): string => {
    if (!contentProp) {
      return '';
    }
    if (
      templateDefinitionsProp &&
      templateDefinitionsProp?.[contentProp?.[constants.TEMPLATE_ID_PROP]]
    ) {
      logger('warn')(
        'Template-definitions endpoint is deprecated. Please migrate your code to use template-annotations endpoint instead.'
      );
      return TemplateAnnotations.getPageCommentString(
        contentProp,
        templateDefinitionsProp?.[contentProp?.[constants.TEMPLATE_ID_PROP]]
      );
    }
    if (templateAnnotationsProp && templateAnnotationsProp?.[contentProp?.['@path']]) {
      return templateAnnotationsProp?.[contentProp?.['@path']];
    }
    return '';
  };

  const getTemplateAnnotations = (): Record<string, string> => {
    if (!templateAnnotationsProp || !selectedComponentVariants) {
      return templateAnnotationsProp as Record<string, string>;
    }
    return PersonalizationService.wrap(templateAnnotationsProp, selectedComponentVariants);
  };

  const getContextValue = (): EditorContextType => {
    const wrappedTemplateAnnotations = getTemplateAnnotations();
    const isDevMode = process.env.NODE_ENV === 'development';
    return {
      templateDefinitions: templateDefinitionsProp,
      templateAnnotations: wrappedTemplateAnnotations,
      componentMappings: componentMappingsProp,
      fallbackComponent: fallbackComponentProp,
      content: contentProp,
      isDevMode
    };
  };

  const context = getContextValue();

  return (
    <EditorContext.Provider value={context}>
      <EditableComment annotation={getAnnotationString()} callback={onReady} alwaysRender />
      {children}
    </EditorContext.Provider>
  );
};

export default EditablePage;
