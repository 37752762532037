import { createElement, ReactElement } from 'react';
import DefaultFallbackComponent from '@/components/magnoliaPage/DefaultFallbackComponent';
import { Props } from '@/types/cms/magnolia';
import { IMagnoliaContext } from '@magnolia/template-annotations/src/service/GetMagnoliaContext';
import { logger } from '@/Util/globals';

export const constants = {
  CLOSED_AREA_COMMENT: '/cms:area',
  CLOSED_COMPONENT_COMMENT: '/cms:component',
  TEMPLATE_ID_PROP: 'mgnl:template'
};

export type EditorContextType = {
  templateAnnotations: Record<string, string> | null;
  isDevMode: boolean;
  templateDefinitions: Record<string, object> | null;
  componentMappings: Record<string, unknown>;
  fallbackComponent?: (() => ReactElement) | false;
  content: Record<string, any>;
};

export type FallbackComponent =
  | ((props: { metadata: Record<string, string>; data: object }) => ReactElement)
  | null
  | undefined
  | boolean;

function getFallbackComponent(fallbackComponentConfig: FallbackComponent): FallbackComponent {
  return typeof fallbackComponentConfig === 'function'
    ? fallbackComponentConfig
    : !!fallbackComponentConfig
      ? DefaultFallbackComponent
      : null;
}

export const buildKey = (componentContent: Record<string, string>) => {
  return componentContent['@name'];
};

export const classnames = (...arg: Array<string | number | object>) => {
  const classes: Array<string | number | object> = [];
  arg.forEach(item => {
    if (item == null) {
      return;
    }
    const itemType = typeof item;
    if (itemType === 'string' || itemType === 'number') {
      classes.push(item);
    } else if (Array.isArray(item) && item.length) {
      classes.push(classnames(...item));
    } else if (itemType === 'object') {
      Object.keys(item).forEach(key => {
        if ((item as Record<string, string>)[key]) {
          classes.push(key);
        }
      });
    }
  });
  return classes.join(' ');
};

export type PageComponentProps = {
  data: object;
  metadata: Record<string, string | number>;
  key: string;
  pageProps?: Props;
  searchParams: Record<string, string>;
  editorContext?: Omit<EditorContextType, 'componentMappings'>;
};

const getComponentProperties = (
  componentContent: object,
  index: number,
  isFallbackComponent: boolean,
  editorContext?: Omit<EditorContextType, 'componentMappings'>,
  searchParams?: Record<string, string>,
  pageProps?: Props
): PageComponentProps | {} => {
  if (!componentContent) {
    return {};
  }

  let props: PageComponentProps = {
    data: {},
    metadata: {},
    key: '',
    pageProps: pageProps,
    searchParams: searchParams || {},
    editorContext
  };
  let propsContainer: Record<string, unknown> | object;
  if (isFallbackComponent) {
    propsContainer = props.data;
  } else {
    propsContainer = props;
  }
  props.metadata = { '@index': index };
  props.key = buildKey(componentContent as Record<string, string>);

  Object.keys(componentContent).forEach(key => {
    if (key.match(/^(@|mgnl:|jcr:)/)) {
      props.metadata[key] = (componentContent as Record<string, string>)[key];
    } else {
      (propsContainer as Record<string, unknown>)[key.replace('-', '_')] = (
        componentContent as Record<string, string>
      )[key];
    }
  });
  return props;
};

export const getRenderedComponent = (
  componentContent: object,
  componentMappings: Record<string, unknown>,
  fallbackComponentConfig: FallbackComponent,
  editorContext?: Omit<EditorContextType, 'componentMappings'>,
  searchParams?: Record<string, string>,
  pageProps?: Props,
  index = 0
) => {
  const emptyComponent = createElement('div');
  if (!componentContent) {
    const msg = 'Component content is missing';
    logger('error')(msg);
    return emptyComponent;
  }
  if (!componentMappings) {
    const msg = 'Component mappings is missing';
    logger('error')(msg);
    return emptyComponent;
  }

  let componentClass =
    componentMappings[(componentContent as Record<string, string>)[constants.TEMPLATE_ID_PROP]];
  let isFallbackComponent = false;
  if (!componentClass) {
    const msg = `Component with ID ${
      (componentContent as Record<string, string>)[constants.TEMPLATE_ID_PROP]
    } is not mapped.`;
    logger('error')(msg);
    const fallbackComponent = getFallbackComponent(fallbackComponentConfig);
    if (!fallbackComponent) {
      return emptyComponent;
    }
    componentClass = fallbackComponent;
    isFallbackComponent = true;
  }

  return createElement(
    componentClass as string,
    getComponentProperties(
      componentContent,
      index,
      isFallbackComponent,
      editorContext,
      searchParams,
      pageProps
    )
  );
};

export const inIframe = () => {
  if (typeof window !== 'undefined') {
    return window !== window.parent;
  }
  return false;
};

export const isSameOrigin = () => {
  try {
    const { href } = window.parent.location;
    return href != null;
  } catch (error) {
    return false;
  }
};

function getWindowInstance() {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return isSameOrigin() ? window.parent : window;
}

const sleep = (ms: number) =>
  new Promise(r => {
    setTimeout(r, ms);
  });

const waitFor = async (f: () => boolean): Promise<boolean> => {
  // eslint-disable-next-line no-await-in-loop
  while (!f()) await sleep(200);
  return f();
};

export const inEditorAsync = async () => {
  if (await waitFor(() => inIframe())) {
    return inIframe();
  }
  return false;
};

export const getSelectedVariant = (
  content: Record<string, any>,
  templateAnnotations: Record<string, any>
) => {
  if (!content || !templateAnnotations) {
    return content;
  }
  const annotations = templateAnnotations[content?.['@path']];
  const match = annotations ? annotations.match(/selectedVariant="(.+)"/) : null;
  if (!match) return content;
  const variant = match[1];
  return variant === content['@name'] ? content : content[variant];
};

export const getVariant = (
  content: object,
  templateAnnotations: object,
  magnoliaContext?: IMagnoliaContext
) => {
  if (!magnoliaContext?.isMagnolia) {
    return content;
  }

  return getSelectedVariant(content, templateAnnotations);
};

const callMgnlRefresh = () => {
  const windowInstance = getWindowInstance();
  if (!!windowInstance?.mgnlRefresh) {
    if (document.documentElement.innerHTML.indexOf('cms:page content=') > -1) {
      windowInstance.mgnlRefresh();
    } else {
      refresh(200);
    }
  }
};

let refreshTimeoutID: ReturnType<typeof setTimeout> | null = null;
export const refresh = (delayInMilliseconds?: number): void => {
  if (refreshTimeoutID) {
    clearTimeout(refreshTimeoutID);
  }
  refreshTimeoutID = setTimeout(callMgnlRefresh, delayInMilliseconds);
};
